<div *ngIf="vm$ | async as vm" class="layout-tab">
  @if (vm.showFallback) {
    <div class="layout-tab__fallback">
      <jui-alert-block color="high" size="sm">
        {{ vm.tabLayout.fallbackMessage }}
      </jui-alert-block>
    </div>
  } @else {
    <div class="layout-tab__columns">
      <div class="layout-tab__column" *ngFor="let c of [].constructor(vm?.layoutColCount$ | async); index as colIndex">
        <ng-container *ngFor="let layoutCollection of vm?.tabLayout.layouts; index as i">
          <ng-container *ngIf="floor(((vm?.layoutColCount$ | async) / vm?.tabLayout.layouts.length) * i) === colIndex">
            <ng-container *ngFor="let layout of layoutCollection.layouts || []">
              <app-list-layout
                class="layout-tab__column-item"
                [project]="vm?.project"
                [data]="layout.data"
                [readOnly]="vm?.readOnly"
                [layout]="layout"
                (saved)="onSave($event)"
              >
                <div *ngIf="layout.type === 'Quote'">
                  <app-standard-quote-select-component
                    [projectType]="vm?.project.type"
                    (standardQuoteChanged)="onStandardQuoteChanged(layout, $event)"
                  ></app-standard-quote-select-component>
                </div>
              </app-list-layout>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  }
</div>
