import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as XRegExp from 'xregexp';
import { CamelToReadablePipe } from '../shared/pipes/camel-to-readable.pipe';
import { TranslocoModule } from '@ngneat/transloco';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpImageComponent } from '@jump-tech-frontend/cards';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'app-images-modal',
  templateUrl: 'installation-images-modal.component.html',
  styleUrls: ['project-detail.component.scss'],
  standalone: true,
  imports: [
    NgxSpinnerModule,
    PinchZoomModule,
    TranslocoModule,
    CamelToReadablePipe,
    HttpImageComponent,
    CoreComponentsAngularModule
  ]
})
export class InstallationImagesModalComponent implements OnInit {
  @Input() galleryImages: any[];
  @Input() projectData: any;
  @Input() currentImage: string;
  currentImageKey = '';
  currentImageIndex: number;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.currentImageIndex = this.galleryImages.indexOf(this.currentImage);
    this.currentImageKey = this.galleryImages[this.currentImageIndex] || '';
  }

  galleryLeft() {
    this.currentImageIndex === 0 ? (this.currentImageIndex = this.galleryImages.length - 1) : this.currentImageIndex--;
    this.currentImage = this.galleryImages[this.currentImageIndex];
    this.currentImageKey = this.galleryImages[this.currentImageIndex] || '';
  }

  galleryRight() {
    this.currentImageIndex === this.galleryImages.length - 1 ? (this.currentImageIndex = 0) : this.currentImageIndex++;
    this.currentImage = this.galleryImages[this.currentImageIndex];
    this.currentImageKey = this.galleryImages[this.currentImageIndex] || '';
  }

  getSrc() {
    const indexMatch = XRegExp(/_(\d+)$/);
    if (indexMatch.test(this.currentImage)) {
      const index = this.currentImage.match(indexMatch)[1];
      return this.projectData[this.currentImage.replace(indexMatch, '')][index];
    } else {
      return this.projectData[this.galleryImages[this.currentImageIndex]];
    }
  }
}
