import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { concat, distinctUntilChanged, Observable, of, Subject, Subscription, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as Analytics from '../../app.analytics';
import { Project } from '../../core/domain/project';
import { HttpGateway } from '../../core/http-gateway.service';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import GetEnaFormDataTestStub from '../../TestTools/stubs/GetEnaFormDataTestStub';
import { ProjectAttachment } from '../project-attachments/project-attachments.component';
import { DisplayTabLayout } from '../project-detail.component';
import {
  ApplicationFormControl,
  ApplicationFormGroup,
  ApplicationFormItem,
  ApplicationFormItemWithHooks,
  BLANK_DEVICE_ITEM,
  DEVICE_MANUAL_CONTROLS,
  DEVICE_SEARCH_CONTROLS,
  EnaActionsDm,
  EnaAttachmentInputs,
  EnaError,
  EnaErrorType,
  EnaFormDm,
  EnaFormDto,
  EnaFormI18n,
  GroupState,
  RegisteredDeviceData
} from './ena.model';
import { EnaAttachmentsService } from './services/ena-attachments.service';
export const FINALIZED_STATES = ['SUBMITTED', 'APPROVED', 'IN REVIEW'];

@Injectable({ providedIn: 'root' })
export class EnaRepository {
  ena$: Subject<EnaFormDm>;
  errors$: Subject<EnaError>;
  enaSubscription: Subscription;
  enaErrorsSubscription: Subscription;

  enaActionsSubscription: Subscription;
  enaActions$: Subject<EnaActionsDm>;

  formChangeSubscriptions: { [key: string]: Subscription } = {};
  asyncDataLookupSubscriptions: { [key: string]: Subscription } = {};
  formStateSubscriptions: Subscription[] = [];

  previousDevices = null;

  cache: EnaFormDm = null;
  i18n: EnaFormI18n = {};
  defaultDm: EnaFormDm = {
    showRetry: false,
    applicationStatus: '',
    externalStatus: '',
    applicationId: null,
    formGroups: [],
    i18ns: this.i18n,
    enableSave: false,
    saveInProgress: false,
    approveInProgress: false,
    submitInProgress: false,
    loading: false,
    project: null
  };
  project: Project;
  tabLayouts: DisplayTabLayout[];
  lastSavedFormValue: any;
  actionsState: EnaActionsDm = null;
  cachedAttachmentInputs: EnaAttachmentInputs = null;
  applicationId: string = null;
  firstFuseCutoutImageUrl: string = null;
  originalAttachments: ProjectAttachment[] = [];
  hydrateDelayMs = 2500;

  ANALYTICS_OPTIONS_TO_TRACK = [
    'phaseCode',
    'isCtMetered',
    'isLoopedSupply',
    'isNoIssues',
    'isNoSafetyConcerns',
    'isImportLimitingDevicePresent',
    'premisesCutOutRating'
  ];

  DEVICES_KEY = 'devicesToInstall';

  // TESTING
  useMocks = false;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private fb: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private enaAttachmentsService: EnaAttachmentsService,
    private modalService: NgbModal
  ) {
    this.init();
  }

  init(): void {
    this.ena$ = new Subject<EnaFormDm>();
    this.errors$ = new Subject<EnaError>();
    this.enaActions$ = new Subject<EnaActionsDm>();
    this.defaultDm = { ...this.defaultDm };
    this.initI18ns();
    this.actionsState = {
      disableSave: true,
      saveInProgress: false,
      hasActions: true,
      submitInProgress: false,
      approveInProgress: false,
      isInReview: false,
      applicationStatus: '',
      i18nSubmitLabel: this.i18n.submitApplication,
      i18nResubmitLabel: this.i18n.resubmitApplication,
      i18nSaveLabel: this.i18n.saveApplication,
      i18nManuallyApproveLabel: this.i18n.manuallyApprove,
      i18nUndoLabel: this.i18n.undoChanges
    };
  }

  load(project: Project, layouts: DisplayTabLayout[], cb): void {
    this.enaSubscription?.unsubscribe();
    this.enaSubscription = this.ena$.subscribe(cb);
    this.tabLayouts = layouts;
    this.project = project;
    this.spinnerService.show('enaApplicationSpinner').then();
    this.loadForm().then();
  }

  loadActions(cb): void {
    this.enaActionsSubscription?.unsubscribe();
    this.enaActionsSubscription = this.enaActions$.subscribe(cb);
    this.enaActions$.next(this.actionsState);
  }

  loadAttachments(attachmentInputs: EnaAttachmentInputs) {
    this.cachedAttachmentInputs = { ...attachmentInputs };
    this.enaAttachmentsService.notifyAttachmentSettingSubs(attachmentInputs);
  }

  async hydrateProject(project: Project): Promise<void> {
    setTimeout(async () => {
      this.project = { ...project, data: { ...project.data }, attachments: [...project.attachments] };

      if (this.fuseCutoutChanged().changed) {
        await this.saveApplication();
      }

      if (this.project.attachments.length > this.originalAttachments.length) {
        await this.saveApplication();
      }

      if (!this.fuseCutoutChanged().changed && this.project.attachments.length === this.originalAttachments.length) {
        await this.loadForm(false);
      }
    }, this.hydrateDelayMs);
  }

  private fuseCutoutChanged(): { changed: boolean; updatedValue: string | [] } {
    const projectFuseCutout = this.project.data.fuseCutoutPhoto;
    if (this.firstFuseCutoutImageUrl && (!projectFuseCutout || !projectFuseCutout.length)) {
      return {
        changed: true,
        updatedValue: []
      };
    }

    if (this.firstFuseCutoutImageUrl && projectFuseCutout) {
      return projectFuseCutout[0].match(/^.*fuseCutoutPhoto_\d+/)?.[0] !==
        this.firstFuseCutoutImageUrl.match(/^.*fuseCutoutPhoto_\d+/)?.[0]
        ? { changed: true, updatedValue: projectFuseCutout[0] }
        : { changed: false, updatedValue: projectFuseCutout[0] };
    }

    if (!this.firstFuseCutoutImageUrl && projectFuseCutout && projectFuseCutout.length) {
      return { changed: true, updatedValue: projectFuseCutout[0] };
    }

    return { changed: false, updatedValue: [] };
  }

  handleAttachmentChecked(): void {
    this.validateManualDevicesHaveAttachments();
  }

  toggleGroup(group: ApplicationFormGroup): void {
    const groupToToggle: ApplicationFormGroup = this.cache.formGroups.find(x => x.title === group.title);
    groupToToggle.isOpen = !groupToToggle.isOpen;
    this.ena$.next(this.cache);
  }

  async loadForm(showLoader = true): Promise<void> {
    let apiResponse: EnaFormDto;
    try {
      this.clearErrors();
      if (showLoader) {
        this.ena$.next({ ...this.defaultDm, loading: true });
      }
      if (this.useMocks) {
        apiResponse = GetEnaFormDataTestStub('GET_SUBMITTED');
      } else {
        apiResponse = await this.gateway.get(
          `${environment.apiEnaApplicationUrl}/${this.project.id}/form`,
          {},
          {
            skip: 'true'
          }
        );
      }
      this.parseAndNotify(apiResponse, showLoader);
    } catch (e) {
      this.handleError(EnaErrorType.GetApplication, e);
    }
  }

  undoChanges(): void {
    this.resetDevices();

    this.cache.formGroups.forEach((group: ApplicationFormGroup): void => {
      if (group.items) {
        (group.form.get(group.key) as FormArray).reset(this.lastSavedFormValue[group.key][group.key]);
      } else {
        group.form.reset(this.lastSavedFormValue[group.key]);
      }
    });
    this.listenToFormStateChange();
    this.ena$.next(this.cache);
  }

  private getPutPayload() {
    this.cache.formGroups.forEach(group => {
      this.enableAllDeviceFields(group);
    });

    const formDelta = this.getFormDelta();
    this.cache.formGroups.forEach((group: ApplicationFormGroup): void => {
      group.form.disable();
    });

    Object.entries(formDelta).forEach(([key, value]): void => {
      if (key === this.DEVICES_KEY) {
        value[this.DEVICES_KEY].forEach((item, j) => {
          Object.entries(item).forEach((k): void => {
            if (k[1] === '') {
              item[k[0]] = null;
            }
            if (
              k[0] === 'targetInstallDate' &&
              typeof k[1] === 'object' &&
              k[1] !== null &&
              Object.prototype.hasOwnProperty.call(k[1], 'month')
            ) {
              formDelta[key][key][j]['targetInstallDate'] = this.convertDateStructToIso(k[1]);
            }
          });

          const deviceGroup = this.getDevicesToInstallFormGroup();
          const foundItem = deviceGroup.items.find((item, idx) => idx === j);

          item.manualEntry = foundItem.manualEntry;
          // todo would prefer the API to infer the device class
          item.deviceClass = item.manualEntry
            ? 'DEMAND_DEVICE_GENERAL_NOT_REGISTERED'
            : 'DEMAND_DEVICE_GENERAL_ENA_REGISTERED';
        });
      }
    });

    if (formDelta[this.DEVICES_KEY]) {
      formDelta[this.DEVICES_KEY] = formDelta[this.DEVICES_KEY][this.DEVICES_KEY].map(device => {
        if (!device.manualEntry) {
          return {
            ...device,
            deviceSysRef: device.deviceSysRef?.externalDeviceId ?? null,
            selectedDeviceData: device.deviceSysRef ?? null
          };
        } else {
          return { ...device, deviceSysRef: null, selectedDeviceData: null };
        }
      });
    }

    return formDelta;
  }

  private unsetIsNewItems(): void {
    const deviceGroup = this.getDevicesToInstallFormGroup();
    deviceGroup.items = deviceGroup.items.map(item => {
      return { ...item, isNew: false };
    });
  }

  private setSaveInProgress(saveInProgress: boolean) {
    this.actionsState = { ...this.actionsState, disableSave: true, saveInProgress };
    this.unsetIsNewItems();
    this.setSavedStateOnGroups();
    this.ena$.next({ ...this.cache, saveInProgress });
    this.enaActions$.next(this.actionsState);
    this.enaAttachmentsService.notifyAttachmentSettingSubs({
      ...this.cachedAttachmentInputs,
      readonly: this.actionsState.submitInProgress ? true : saveInProgress
    });

    if (!saveInProgress) {
      this.originalAttachments = [...this.cache.project.attachments];
      this.cache.formGroups.forEach((group: ApplicationFormGroup): void => {
        group.form.enable();
      });
      this.ena$.next(this.cache);
    }
  }
  setApproveInProgress(approveInProgress: boolean) {
    this.actionsState = { ...this.actionsState, approveInProgress };
    this.ena$.next({ ...this.cache, approveInProgress });
    this.enaActions$.next(this.actionsState);
  }

  private setSubmitInProgress(submitInProgress: boolean) {
    this.actionsState = { ...this.actionsState, disableSave: true, submitInProgress };
    this.cache = { ...this.cache, submitInProgress };
    this.ena$.next(this.cache);
    this.enaActions$.next(this.actionsState);
    this.enaAttachmentsService.notifyAttachmentSettingSubs({
      ...this.cachedAttachmentInputs,
      readonly: submitInProgress
    });
  }

  private setSavedStateOnGroups(clear = false) {
    this.cache.formGroups.forEach(fg => {
      if (fg.form.status === 'DISABLED') {
        return;
      }
      fg.savedState = clear ? null : fg.form.invalid ? this.getIncompleteMessageForGroup(fg) : GroupState.complete;
      fg.savedStateCss = clear
        ? null
        : fg.form.invalid
        ? 'ena-application__group-state ena-application__group-state--invalid'
        : 'ena-application__group-state ena-application__group-state--valid';
    });
  }

  getIncompleteMessageForGroup(grp: ApplicationFormGroup): string {
    if (grp.key === 'additionalAttachments') {
      const deviceGroup = this.getDevicesToInstallFormGroup();
      const numManualDevices = deviceGroup.items.filter(itm => itm.manualEntry).length;
      grp.invalidGroupMessage = this.i18n.valueMissingDatasheet.replace('%n%', numManualDevices.toString());
    }
    return grp.invalidGroupMessage ? `${GroupState.incomplete} - ${grp.invalidGroupMessage}` : GroupState.incomplete;
  }

  beginSubmitApplication(): void {
    this.setSubmitInProgress(true);
    this.submitApplication().then();
  }

  private sendAnalytics(): void {
    const supplyDetailsFormGroup = this.cache.formGroups.find(fg => fg.key === 'supplyDetails');
    const deviceDetailsFormGroup = this.cache.formGroups.find(fg => fg.key === this.DEVICES_KEY);
    const fieldsToSendForAnalytics = Object.keys(supplyDetailsFormGroup.form.value).filter(key =>
      this.ANALYTICS_OPTIONS_TO_TRACK.includes(key)
    );

    const analyticsDataObject = {};
    for (const field of fieldsToSendForAnalytics) {
      analyticsDataObject[field] = supplyDetailsFormGroup.form.value[field];
    }
    Analytics.logEvent('EnaApplicationSubmission', analyticsDataObject);

    const devicesFormArray = deviceDetailsFormGroup.form.get(this.DEVICES_KEY) as FormArray;
    deviceDetailsFormGroup.items.forEach((itm: ApplicationFormItemWithHooks, i: number): void => {
      if (itm.manualEntry) {
        const manualDeviceData = devicesFormArray.at(i).value;
        manualDeviceData.targetInstallDate = this.convertDateStructToIso(manualDeviceData.targetInstallDate);
        Analytics.logEvent('EnaApplicationManualDeviceSubmission', manualDeviceData);
      }
    });
  }

  async submitApplication(): Promise<void> {
    await this.saveApplication();
    try {
      await this.gateway.post(
        `${environment.apiEnaApplicationUrl}/${this.project.id}/${this.cache.applicationId}/submit`,
        null,
        {
          skip: 'true'
        }
      );
      this.sendAnalytics();
      // optimistic update to submit pending
      this.cache = { ...this.cache, applicationStatus: 'SUBMITTED' };
      this.ena$.next(this.cache);
      this.actionsState = { ...this.actionsState, hasActions: false };
      this.enaActions$.next(this.actionsState);

      this.loadForm(false).then();
      this.setSubmitInProgress(false);
    } catch (e) {
      this.handleError(EnaErrorType.PostApplication, e);
    }
  }

  async saveApplication(): Promise<void> {
    try {
      this.setSaveInProgress(true);

      const delta = this.getPutPayload();
      if (Object.keys(delta).length) {
        await this.gateway.put(
          `${environment.apiEnaApplicationUrl}/${this.project.id}/${this.cache.applicationId}`,
          delta,
          { skip: 'true' }
        );
        this.lastSavedFormValue = this.getCurrentFormValue();
        this.tearDownSaveSuccess();
      } else {
        this.tearDownSaveSuccess();
      }
    } catch (e) {
      this.handleError(EnaErrorType.PutApplication, e);
    }
  }
  manuallyApproveApplicationDialog(): void {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.config = {
      title: this.i18n.manuallyApproveModalTitle,
      messages: [this.i18n.manuallyApproveModalMessage],
      confirm: this.i18n.yesLabel,
      cancel: this.i18n.noLabel
    };
    modalRef.result.then(() => {
      this.manuallyApproveApplication();
    });
  }
  async manuallyApproveApplication(): Promise<void> {
    this.setApproveInProgress(true);
    try {
      await this.gateway.put(
        `${environment.apiEnaApplicationUrl}/${this.project.id}/${this.cache.applicationId}/status`,
        { status: 'APPROVED' },
        {
          skip: 'true'
        }
      );
      this.cache = { ...this.cache, applicationStatus: 'APPROVED' };
      this.ena$.next(this.cache);
      this.actionsState = { ...this.actionsState, hasActions: false, applicationStatus: 'APPROVED' };
      this.enaActions$.next(this.actionsState);

      this.setApproveInProgress(false);
    } catch (e) {
      this.handleError(EnaErrorType.PutApplication, e);
    }
  }

  private tearDownSaveError(): void {
    this.setSaveInProgress(false);
    this.cache.formGroups.forEach((group: ApplicationFormGroup): void => {
      group.form.enable();
      if (group.items) {
        group.items.map(itm => {
          const controls = itm.sections.map(sec => sec.controls).flat();
          controls.forEach(control => {
            this.listenToDisplayIfFormChanges(control, group.form);
          });
        });
      } else {
        const controls = group.sections.map(sec => sec.controls).flat();
        controls.forEach(control => {
          this.listenToDisplayIfFormChanges(control, group.form);
        });
      }
    });
  }

  private tearDownSaveSuccess(): void {
    if (!this.actionsState.submitInProgress) {
      this.loadForm(false).then();
    }
    this.setSaveInProgress(false);
  }

  private convertDateStructToIso(date: NgbDateStruct | any): string {
    return JumptechDate.from({
      year: date.year,
      month: date.month,
      day: date.day
    }).toIso();
  }

  private getCurrentFormValue() {
    const currentForm = {};
    for (const group of this.cache.formGroups) {
      currentForm[group.key] = group.form.value;
    }
    return currentForm;
  }

  private getFormDelta() {
    const currentForm = this.getCurrentFormValue();
    const getDifference = (originalObj, newObj) => {
      return Object.fromEntries(
        Object.entries(newObj).filter(
          ([key, val]) =>
            !Object.prototype.hasOwnProperty.call(originalObj, key) ||
            // Recursively call getDifference if the values we are trying to compare are objects
            (typeof val === 'object' && val !== null && !Array.isArray(val)
              ? Object.keys(getDifference(originalObj[key], val)).length
              : originalObj[key] !== val)
        )
      );
    };
    let deltaObject = {};

    for (const key of Object.keys(currentForm)) {
      const currentKeyObject = currentForm[key];
      const originalFormKeyObject = this.lastSavedFormValue[key];
      let diff;
      const arrayDiffs = [];
      if (Array.isArray(originalFormKeyObject[key])) {
        if (
          key === this.DEVICES_KEY &&
          originalFormKeyObject[this.DEVICES_KEY].length !== currentKeyObject[key].length
        ) {
          // exit early as we know something has changed
          deltaObject[this.DEVICES_KEY] = { arrayLen: true };
        } else {
          originalFormKeyObject[key].map((original, i) => {
            if (currentKeyObject[key][i]) {
              const deviceDiff = getDifference(original, currentKeyObject[key][i]);
              if (Object.keys(deviceDiff).length) {
                arrayDiffs.push(deviceDiff);
              }
            }
          });
        }
      } else {
        diff = getDifference(originalFormKeyObject, currentKeyObject);
      }

      if (arrayDiffs.length) {
        deltaObject[this.DEVICES_KEY] = arrayDiffs;
      }

      if (diff && Object.keys(diff).length) {
        deltaObject[key] = diff;
      }
    }

    // run custom fuse cutout diff check
    const fuseCutoutDiff = this.fuseCutoutChanged();
    if (fuseCutoutDiff.changed) {
      deltaObject = { ...deltaObject, cutOutImages: { fuseCutoutPhoto: fuseCutoutDiff.updatedValue } };
    }

    if (Object.prototype.hasOwnProperty.call(deltaObject, 'additionalAttachments')) {
      deltaObject['additionalAttachments'] = Object.entries(currentForm['additionalAttachments'])
        .filter(entry => entry[1])
        .map(arr => {
          return { fileName: arr[0].replace(/__([^__]*)$/, '.$1') };
        });
    }

    if (Object.prototype.hasOwnProperty.call(deltaObject, this.DEVICES_KEY)) {
      deltaObject[this.DEVICES_KEY] = currentForm[this.DEVICES_KEY];
    }

    return deltaObject;
  }

  private buildItems(items: ApplicationFormItem[]): ApplicationFormItem[] {
    items.forEach(item => {
      item.sections.forEach(section => {
        section.controls.forEach(control => {
          if (control.type === 'asyncDataLookup') {
            control.options$ = new Observable<RegisteredDeviceData[]>();
            control.optionInput$ = new Subject<string>();
            control.optionLoading$ = new Subject<boolean>();
            control.optionSelectedData = control.value ?? null;
          }
        });
      });
    });
    return items;
  }

  private addDeviceTypeToSearch(): ApplicationFormItem[] {
    const itemsFg = this.cache.formGroups.find(fg => fg.items);
    itemsFg.items.forEach((item, index) => {
      const devicesToInstall = this.getDeviceGroupAndFormArray();
      const deviceType = devicesToInstall.deviceFormArray.at(index).get('deviceType').value;
      item.sections.forEach(section => {
        section.controls.forEach(control => {
          if (control.type === 'asyncDataLookup') {
            this.loadDeviceItems(control, deviceType);
          }
        });
      });
    });
    return itemsFg.items;
  }

  loadDeviceItems(control: ApplicationFormControl, deviceType: string): void {
    control.options$ = concat(
      of([]),
      control.optionInput$.pipe(
        distinctUntilChanged(),
        tap((): void => {
          control.optionLoading$.next(true);
        }),
        switchMap(async (searchTerm: string) => {
          const apiResponse: RegisteredDeviceData[] = await this.gateway.get(environment.apiEnaDeviceSearchUrl, {
            deviceType,
            searchTerm
          });
          control.optionLoading$.next(false);
          return apiResponse;
        })
      )
    );
  }

  private parseAndNotify(dto: EnaFormDto, isFirstLoad = false) {
    let apiFormGroups = dto.groups.map((grp): ApplicationFormGroup => {
      return {
        title: grp.title,
        key: grp.key,
        useHug: grp.key === 'additionalAttachments',
        sections: grp.sections,
        items: grp.items ? this.buildItems(grp.items) : null,
        form: grp.items
          ? this.buildForm(grp.key, null, grp.items)
          : this.buildForm(grp.key, grp.sections.map(sec => sec.controls).flat(), null),
        isOpen: this.cache ? this.cache.formGroups.find(x => x.key === grp.key).isOpen : true,
        toggleClass: 'expand_less'
      };
    });

    apiFormGroups = this.validateCutoutImages(apiFormGroups);
    apiFormGroups = this.addImageDataToFormControls(apiFormGroups);
    apiFormGroups = apiFormGroups.map(group => ({
      ...group,
      isOpen: isFirstLoad ? group.form.invalid : group.isOpen
    }));

    this.updateActionsForStatus(dto, apiFormGroups);

    this.cache = {
      applicationStatus: dto.applicationStatus,
      externalStatus: dto.application.externalStatus,
      manualApplicationTransition: dto.application.manualApplicationTransition,
      externalDno: dto.application.externalDno,
      applicationId: dto.application.internalApplicationId,
      formGroups: apiFormGroups,
      i18ns: this.i18n,
      showRetry: false,
      loading: false,
      enableSave: false,
      saveInProgress: false,
      approveInProgress: false,
      submitInProgress: false,
      project: { ...this.project, data: { ...this.project.data } }
    };

    this.originalAttachments = this.project.attachments;

    // add device type to device search form items
    this.addDeviceTypeToSearch();
    this.updateDeviceItemIds();

    this.validateManualDevicesHaveAttachments();

    this.setPreviousDevices();
    this.setCurrentForm(apiFormGroups);
    this.listenToFormStateChange();
    this.updateDeviceFormState();
    this.ena$.next(this.cache);
    this.setSavedStateOnGroups(true);
  }

  private updateDeviceFormState(): void {
    if (this.isApplicationNotInFinalizedState()) {
      this.cache.formGroups.forEach(group => {
        if (group.items) {
          group.items.forEach(groupItem => {
            this.updateItemManualEntry(groupItem.name, groupItem.manualEntry);
          });
        }
      });
    }
  }

  private updateActionsForStatus(dto: EnaFormDto, apiFormGroups: ApplicationFormGroup[]): void {
    if (this.isApplicationInFinalizedState(dto)) {
      apiFormGroups.forEach((group: ApplicationFormGroup): void => {
        group.form.disable();
      });
      this.actionsState = { ...this.actionsState, hasActions: false, applicationStatus: dto.applicationStatus };
      this.enaActions$.next(this.actionsState);
      this.enaAttachmentsService.notifyAttachmentSettingSubs({ ...this.cachedAttachmentInputs, readonly: true });
    } else if (dto.applicationStatus === 'REJECTED') {
      this.actionsState = { ...this.actionsState, hasActions: true, applicationStatus: dto.applicationStatus };
      this.enaActions$.next(this.actionsState);
      this.enaAttachmentsService.notifyAttachmentSettingSubs({ ...this.cachedAttachmentInputs });
    } else {
      this.actionsState = { ...this.actionsState, hasActions: true, applicationStatus: dto.applicationStatus };
      this.enaActions$.next(this.actionsState);
      this.enaAttachmentsService.notifyAttachmentSettingSubs({ ...this.cachedAttachmentInputs });
    }
  }

  private enableAllDeviceFormFields(fg: ApplicationFormGroup): void {
    fg.items.forEach((_, idx): void => {
      const deviceGroup = (fg.form.get(this.DEVICES_KEY) as FormArray).at(idx);
      [...DEVICE_SEARCH_CONTROLS, ...DEVICE_MANUAL_CONTROLS].forEach((control): void => {
        deviceGroup.get(control).enable();
      });
    });
  }

  private setCurrentForm(formGroups: ApplicationFormGroup[]) {
    const currentForm = {};
    for (const group of formGroups) {
      this.enableAllDeviceFields(group);
      currentForm[group.key] = group.form.value;
    }
    this.lastSavedFormValue = currentForm;
  }

  private enableAllDeviceFields(group: ApplicationFormGroup) {
    if (group.key === this.DEVICES_KEY && this.isApplicationNotInFinalizedState()) {
      this.enableAllDeviceFormFields(group);
    }
  }

  private getValidatorForControl(control: ApplicationFormControl) {
    const validators = [];
    if (control.validators.find(x => x === 'required')) {
      validators.push(Validators.required);
    }
    if (control.validators.find(x => x === 'email')) {
      validators.push(Validators.email);
    }

    if (control.minLength) {
      validators.push(Validators.minLength(control.minLength));
    }
    if (control.regex) {
      validators.push(Validators.pattern(control.regex));
    }

    return validators;
  }

  private validateCutoutImages(formGroups: ApplicationFormGroup[]) {
    return formGroups.map(grp => {
      if (grp.key === 'cutOutImages') {
        if (this.project.data['fuseCutoutPhoto']?.length) {
          grp.form.setValue({ fuseCutoutPhoto: [{ fileUrl: this.project.data['fuseCutoutPhoto'][0] }] });
          this.firstFuseCutoutImageUrl = this.project.data['fuseCutoutPhoto'][0];
        } else {
          grp.form.setValue({ fuseCutoutPhoto: null });
          this.firstFuseCutoutImageUrl = null;
        }
        return grp;
      }
      return grp;
    });
  }

  private addImageDataToFormControls(formGroups: ApplicationFormGroup[]): ApplicationFormGroup[] {
    return formGroups.map(group => {
      if (group.items) {
        return { ...group };
      } else {
        return {
          ...group,
          sections: group.sections.map(sec => {
            return {
              ...sec,
              controls: sec.controls.map(control => {
                if (control.type === 'image') {
                  this.tabLayouts.forEach(tl => {
                    tl.layouts.forEach(l => {
                      l.layouts[0].items.forEach(itm => {
                        if (itm.editConfig.key === control.name) {
                          control.data = itm;
                        }
                      });
                    });
                  });
                  return control;
                } else {
                  return control;
                }
              })
            };
          })
        };
      }
    });
  }

  private listenToDisplayIfFormChanges(control: ApplicationFormControl, fg: FormGroup): void {
    if (control.displayIf) {
      this.formChangeSubscriptions[control.name]?.unsubscribe();
      this.formChangeSubscriptions[control.name] = fg.get(control.displayIf.property).valueChanges.subscribe(v => {
        if (control.displayIf.value !== v) {
          fg.get(control.name).reset();
          fg.get(control.name).disable();
        } else {
          fg.get(control.name).enable();
        }
        fg.updateValueAndValidity();
      });

      const dependentValue = fg.get(control.displayIf.property).value;
      if (!dependentValue) {
        fg.get(control.name).disable();
      }
    }
  }

  private listenToFormStateChange(): void {
    this.formStateSubscriptions.forEach(sub => sub.unsubscribe());
    this.cache.formGroups.forEach(formGroup => {
      if (formGroup.form.valueChanges) {
        this.formStateSubscriptions.push(
          formGroup.form.valueChanges.subscribe(() => {
            if (!this.actionsState.submitInProgress) {
              const formDelta = this.getFormDelta();
              if (Object.keys(formDelta).length) {
                this.enaActions$.next({ ...this.actionsState, disableSave: false });
              } else {
                this.enaActions$.next({ ...this.actionsState, disableSave: true });
              }
            }
          })
        );
      }
    });
  }

  private listenToAsyncDataLookupChanges(control: ApplicationFormControl, formGroup: FormGroup, itemKey: string): void {
    this.asyncDataLookupSubscriptions[itemKey]?.unsubscribe();

    this.asyncDataLookupSubscriptions[itemKey] = formGroup.get(control.name).valueChanges.subscribe(v => {
      if (!v) {
        control.optionSelectedData = null;
      }
    });
  }

  private buildForm(key: string, controls?: ApplicationFormControl[], items?: ApplicationFormItem[]): FormGroup {
    let form: FormGroup;
    if (items) {
      form = new FormGroup({
        [key]: this.buildFormArray(items)
      });
    } else {
      form = this.buildFormGroup(key, controls);
    }
    return form;
  }

  private buildFormArray(items: ApplicationFormItem[]): FormArray {
    const formArrayItems = [];
    items.forEach(item => {
      formArrayItems.push(
        this.buildFormGroup(item.name, item.sections.map(sec => sec.controls).flat(), item.manualEntry)
      );
    });
    return this.fb.array(formArrayItems);
  }

  private buildFormGroup(key: string, controls: ApplicationFormControl[], manualEntry?: boolean): FormGroup {
    const myControls = {};
    if (key === 'additionalAttachments' && this.project.attachments.length) {
      this.project.attachments.forEach(attachment => {
        const controlName = attachment.key.replace(/.([^.]*)$/, '__$1');
        myControls[controlName] = !!controls.find(c => c.name === attachment.key)?.value;
      });
    } else {
      controls.forEach((control): void => {
        const controlValidators = this.getValidatorForControl(control);
        if (control.type === 'date' && typeof control.value === 'string') {
          control.value = this.isoStringToDateStruct(control.value);
        }

        if (control.type === 'address' && control.value) {
          control.value = this.removeNullsFromAddressFields(control.value);
        }
        myControls[control.name] = [control.value ?? '', controlValidators];
      });
    }

    const formGroup = this.fb.group(
      {
        ...myControls
      },
      { updateOn: 'change' }
    );

    if (key.indexOf('deviceToInstall') !== -1) {
      formGroup.addControl('manualEntry', this.fb.control(!!manualEntry, [Validators.required]));
      this.updateDeviceFormValidity(formGroup);
    }

    controls.forEach(control => {
      if (control.name === 'deviceSysRef') {
        this.listenToAsyncDataLookupChanges(control, formGroup, key);
      }
      this.listenToDisplayIfFormChanges(control, formGroup);
    });

    return formGroup;
  }

  private updateDeviceFormValidity(fg: FormGroup): void {
    if (fg.get('manualEntry').value === true) {
      DEVICE_SEARCH_CONTROLS.forEach(control => {
        fg.get(control).disable();
      });
      DEVICE_MANUAL_CONTROLS.forEach(control => {
        fg.get(control).enable();
      });
    } else {
      DEVICE_MANUAL_CONTROLS.forEach(control => {
        fg.get(control).disable();
      });
      DEVICE_SEARCH_CONTROLS.forEach(control => {
        fg.get(control).enable();
      });
    }
  }

  setSelectedDevice(registeredDevice: RegisteredDeviceData, itemIdx: number): void {
    const deviceGroup = this.getDevicesToInstallFormGroup();
    const foundItem = deviceGroup.items.find((item, i) => i === itemIdx);
    const control = foundItem.sections.find(sec => sec.id === `deviceToInstall_${itemIdx}_asyncDataLookup`).controls[0];
    control.optionSelectedData = registeredDevice;
    control.value = registeredDevice;
    const fg = (deviceGroup.form.get(this.DEVICES_KEY) as FormArray).at(itemIdx) as FormGroup;
    fg.get('deviceSysRef').patchValue(registeredDevice);
    this.ena$.next(this.cache);
  }

  updateItemManualEntry(itemName: string, manualEntry: boolean): void {
    const deviceGroup = this.getDevicesToInstallFormGroup();
    const foundItem = deviceGroup.items.find(item => item.name === itemName);
    const foundItemIndex = deviceGroup.items.findIndex(item => item.name === itemName);
    foundItem.manualEntry = manualEntry;
    const fg = (deviceGroup.form.get(this.DEVICES_KEY) as FormArray).at(foundItemIndex) as FormGroup;
    fg.get('manualEntry').patchValue(manualEntry);
    this.updateDeviceFormValidity(fg);
    this.validateManualDevicesHaveAttachments();
  }

  private validateManualDevicesHaveAttachments(): void {
    // get manual device item number
    const deviceGroup = this.getDevicesToInstallFormGroup();
    const numOfManualDevices = deviceGroup.items.filter(itm => itm.manualEntry).length;

    // update validation in attachments
    const attachmentGroup = this.cache.formGroups.find(fg => fg.key === 'additionalAttachments');
    let checkedAttachments = 0;
    Object.keys(attachmentGroup.form.controls).forEach(key => {
      if (attachmentGroup.form.controls[key].value) {
        checkedAttachments++;
      }
    });

    if (numOfManualDevices > checkedAttachments) {
      attachmentGroup.form.setValidators(() => {
        return { missingDatasheet: true };
      });
      attachmentGroup.form.updateValueAndValidity();
      attachmentGroup.invalidGroupMessage = this.i18n.valueMissingDatasheet.replace(
        '%n%',
        numOfManualDevices.toString()
      );
    } else {
      attachmentGroup.form.clearValidators();
      attachmentGroup.form.updateValueAndValidity();
    }
  }

  private updateDeviceItemIds(newItemPosition?: number): void {
    const deviceGroup = this.getDevicesToInstallFormGroup();
    deviceGroup.items = deviceGroup.items.map((item, i) => {
      return {
        ...item,
        isNew: newItemPosition === i,
        name: `deviceToInstall_${i}`,
        sections: item.sections.map(sec => {
          return {
            ...sec,
            id: sec.id?.includes('manualSection')
              ? `deviceToInstall_${i}_manualSection`
              : sec.id?.includes('asyncDataLookup')
              ? `deviceToInstall_${i}_asyncDataLookup`
              : null,
            controls: sec.controls.map(control => ({ ...control }))
          };
        })
      };
    });
    deviceGroup.items = this.buildItems(deviceGroup.items);
    this.addDeviceTypeToSearch();
  }

  private getDeviceGroupAndFormArray(): { deviceGroup: ApplicationFormGroup; deviceFormArray: FormArray } {
    const deviceGroup = this.getDevicesToInstallFormGroup();
    const deviceFormArray: FormArray = deviceGroup.form.get(this.DEVICES_KEY) as FormArray;
    return { deviceGroup, deviceFormArray };
  }

  private getDevicesToInstallFormGroup(): ApplicationFormGroup {
    return this.cache.formGroups.find(fg => fg.key === this.DEVICES_KEY);
  }

  addDevice(): void {
    const { deviceGroup, deviceFormArray } = this.getDeviceGroupAndFormArray();
    const blankDeviceItem = BLANK_DEVICE_ITEM;
    const updatedName = `${blankDeviceItem.name.split('_')[0]}_${deviceFormArray.length}`;

    const newFg = this.buildFormGroup(updatedName, blankDeviceItem.sections.map(sec => sec.controls).flat());
    deviceFormArray.push(newFg);

    blankDeviceItem.manualEntry = false;

    deviceGroup.items.push(blankDeviceItem);

    const newItemIndex = deviceGroup.items.length - 1;

    this.updateDeviceItemIds(newItemIndex);
    this.listenToFormStateChange();
    this.ena$.next(this.cache);
  }

  duplicateDevice(index: number): void {
    const { deviceGroup, deviceFormArray } = this.getDeviceGroupAndFormArray();
    const deviceToDuplicateFg = { ...deviceGroup.items[index] };
    const updatedId = `${deviceToDuplicateFg.name.split('_')[0]}_${index + 1}`;
    const newFg = this.buildFormGroup(
      updatedId,
      deviceToDuplicateFg.sections.map(sec => sec.controls).flat(),
      deviceToDuplicateFg.manualEntry
    );

    const patchingValue = deviceFormArray.at(index).value;
    if (patchingValue.targetInstallDate && typeof patchingValue.targetInstallDate === 'string') {
      patchingValue.targetInstallDate = this.isoStringToDateStruct(patchingValue.targetInstallDate);
    }

    newFg.patchValue(deviceFormArray.at(index).value);
    deviceFormArray.insert(index + 1, newFg);

    deviceGroup.items.splice(index + 1, 0, deviceToDuplicateFg);

    this.updateDeviceItemIds(index + 1);
    this.listenToFormStateChange();
    this.validateManualDevicesHaveAttachments();

    this.ena$.next(this.cache);
  }

  resetDevices(): void {
    this.cache.formGroups = this.cache.formGroups.map(fg => {
      if (fg.key === this.DEVICES_KEY) {
        return { ...this.previousDevices };
      } else {
        return fg;
      }
    });

    const deviceFormGroup = this.getDevicesToInstallFormGroup();
    deviceFormGroup.items.forEach(item => {
      this.updateItemManualEntry(item.name, item.manualEntry);
    });

    this.setPreviousDevices();
  }

  setPreviousDevices(): void {
    const previous: ApplicationFormGroup = this.getDevicesToInstallFormGroup();
    this.previousDevices = {
      ...previous,
      form: this.buildForm(this.DEVICES_KEY, null, previous.items),
      items: [...previous.items.map(itm => ({ ...itm }))]
    };
  }

  removeDevice(index: number): void {
    const { deviceGroup, deviceFormArray } = this.getDeviceGroupAndFormArray();
    const deviceName = deviceGroup.items[index].name;
    // add to form
    deviceFormArray.removeAt(index, { emitEvent: false });
    deviceGroup.items.splice(index, 1);

    this.updateDeviceItemIds();

    this.listenToFormStateChange();
    this.validateManualDevicesHaveAttachments();

    this.asyncDataLookupSubscriptions[deviceName]?.unsubscribe();
    this.ena$.next(this.cache);
  }

  private removeNullsFromAddressFields(address: IAddressV2) {
    const updatedAddress = {};
    Object.keys(address).forEach(key => {
      updatedAddress[key] = address[key] ?? '';
    });
    return updatedAddress;
  }

  private isoStringToDateStruct(isoString: string): NgbDateStruct {
    const date = new Date(isoString);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  public getErrors(cb): void {
    this.enaErrorsSubscription = this.errors$.subscribe(cb);
  }

  public clearErrors(): void {
    this.errors$.next({} as EnaError);
  }

  private setError(e: EnaErrorType, msg?): void {
    const message: string = msg && msg.detail ? msg.detail : this.i18nService.translate(e);
    this.errors$.next({ message, qaErrorMessage: 'enaErrorMessage', qaClearErrorsButton: 'enaClearErrorsButton' });
  }

  private handleError(type: EnaErrorType, e): void {
    if (!environment.production) {
      console.log('Ena Error:', e);
    }
    switch (type) {
      case EnaErrorType.GetApplication:
        this.setError(EnaErrorType.GetApplication, e.error);
        this.ena$.next({ ...this.defaultDm, showRetry: true, loading: false });
        break;
      case EnaErrorType.PutApplication:
        this.tearDownSaveError();
        this.setError(EnaErrorType.PutApplication, e.error);
        break;
      case EnaErrorType.PostApplication:
        this.loadForm(false).then();
        this.setSubmitInProgress(false);
        this.setError(EnaErrorType.PostApplication, e.error);
        break;
    }
  }

  private initI18ns(): void {
    this.i18n.valueRequired = this.i18nService.translate('enaForm.validation.required');
    this.i18n.valueMinLength = this.i18nService.translate('enaForm.validation.minLength');
    this.i18n.valueMissingDatasheet = this.i18nService.translate('enaForm.validation.missingDatasheet');
    this.i18n.valueEmail = this.i18nService.translate('enaForm.validation.email');
    this.i18n.title = this.i18nService.translate('enaForm.title');
    this.i18n.submitApplication = this.i18nService.translate('enaForm.submitApplication');
    this.i18n.resubmitApplication = this.i18nService.translate('enaForm.resubmitApplication');
    this.i18n.manuallyApprove = this.i18nService.translate('enaForm.manuallyApprove');
    this.i18n.manuallyApproveModalTitle = this.i18nService.translate('enaForm.modal.title');
    this.i18n.manuallyApproveModalMessage = this.i18nService.translate('enaForm.modal.message');
    this.i18n.manuallyApprovedLabel = this.i18nService.translate('enaForm.manuallyApprovedLabel');
    this.i18n.saveApplication = this.i18nService.translate('enaForm.saveApplication');
    this.i18n.undoChanges = this.i18nService.translate('enaForm.undoChanges');
    this.i18n.retryLabel = this.i18nService.translate('enaForm.retryLabel');
    this.i18n.retryMessage = this.i18nService.translate('enaForm.retryMessage');
    this.i18n.selectDate = this.i18nService.translate('enaForm.selectDate');
    this.i18n.addDevice = this.i18nService.translate('enaForm.addDevice');
    this.i18n.removeDevice = this.i18nService.translate('enaForm.removeDevice');
    this.i18n.duplicateDevice = this.i18nService.translate('enaForm.duplicateDevice');
    this.i18n.manualEntryDevice = this.i18nService.translate('enaForm.manualEntryDevice');
    this.i18n.searchForDevice = this.i18nService.translate('enaForm.searchForDevice');
    this.i18n.typeToSearchDevice = this.i18nService.translate('enaForm.typeToSearchDevice');
    this.i18n.deviceId = this.i18nService.translate('enaForm.deviceId');
    this.i18n.deviceType = this.i18nService.translate('enaForm.deviceType');
    this.i18n.manufacturer = this.i18nService.translate('enaForm.manufacturer');
    this.i18n.model = this.i18nService.translate('enaForm.model');
    this.i18n.phase = this.i18nService.translate('enaForm.phase');
    this.i18n.externalDno = this.i18nService.translate('enaForm.externalDno');
    this.i18n.invalidDateFormat = this.i18nService.translate('enaForm.errors.invalidDateFormat');
  }

  private isApplicationInFinalizedState(dto: EnaFormDto): boolean {
    return FINALIZED_STATES.includes(dto.applicationStatus);
  }
  private isApplicationNotInFinalizedState(): boolean {
    return !FINALIZED_STATES.includes(this.cache.applicationStatus);
  }
}
