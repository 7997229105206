import { Component, Input } from '@angular/core';
import { RescheduleReasonVm } from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'schedule-event-selected-job-reschedule-reason',
  templateUrl: 'schedule-event-selected-job-reschedule-reason.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  standalone: true,
  imports: [CoreComponentsAngularModule, ReactiveFormsModule]
})
export class ScheduleEventSelectedJobRescheduleReasonComponent {
  @Input() vm: RescheduleReasonVm;
}
