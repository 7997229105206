<pathway-schedule-errors-v3></pathway-schedule-errors-v3>
<div class="card">
  <div class="card-body">
    <ngx-spinner
      bdColor="var(--jds-theme-spinner-bd-rgba)"
      size="large"
      [fullScreen]="true"
      type="line-scale"
      name="calendarSpinner"
      [attr.data-qa]="'spinner'"
    >
    </ngx-spinner>

    <schedule-event-selected-job-details
      (closed)="onSelectedJobDetailsClosed()"
      (closedOnSuccess)="onSelectedJobDetailsSuccessClosed($event)"
    ></schedule-event-selected-job-details>
    <schedule-event-selected-job-details-toggle></schedule-event-selected-job-details-toggle>

    <div class="schedule-header">
      <div class="schedule-header__title-container">
        <span class="schedule-header__title">{{ viewTitle }}</span>
      </div>
      <div class="schedule-header__actions-container">
        <div class="schedule-header__filter">
          <jui-button
            color="low"
            size="sm"
            class="schedule-header__filter-btn"
            *ngIf="activeFilters"
            [attr.data-qa]="'resetFilterScheduleBtn'"
            (click)="resetFilter()"
          >
            {{ 'schedule.resetFilter' | transloco }}
          </jui-button>

          <ng-template #filterPopover>
            <schedule-filters-popover-v3
              [filterInfo]="generateFilterData()"
              (jobTypesSelected)="setJobTypes($event)"
              (absenceTypesSelected)="setAbsenceTypes($event)"
              (jobStatusesSelected)="setJobStatuses($event)"
              (engineersSelected)="setEngineers($event)"
              (popoverClosed)="closeFilterPopover()"
            ></schedule-filters-popover-v3>
          </ng-template>
          <jui-button
            color="low"
            size="sm"
            class="schedule-header__filter-btn"
            [ngbPopover]="filterPopover"
            #p="ngbPopover"
            placement="bottom-left"
            [autoClose]="'outside'"
            [attr.data-qa]="'filterScheduleBtn'"
          >
            <span slot="icon" class="material-icons">filter_alt</span>
            {{ 'schedule.filter' | transloco }}
          </jui-button>
          <jui-badge type="label" class="schedule-header__filter-badge" *ngIf="activeFilters"
            >{{ activeFilters }}
          </jui-badge>
        </div>
        <div>
          <ng-select
            class="schedule-header__select"
            [clearable]="false"
            [items]="scheduleViews"
            [(ngModel)]="defaultScheduleView"
            (change)="handleViewChange($event)"
            [searchable]="false"
            [attr.data-qa]="'scheduleViewSelect'"
            #viewSelect
          >
          </ng-select>
        </div>
        <div>
          <jui-button
            size="sm"
            color="primary"
            [disabled]="!scheduleEventTypeList"
            (click)="addToSchedule()"
            [attr.data-qa]="'addToScheduleBtn'"
          >
            <span slot="icon" class="material-icons">add</span>
            <span>{{ 'Buttons.addEvent.text' | transloco }}</span>
          </jui-button>
        </div>
      </div>
    </div>
    <div class="main-calendar-container" [class.main-calendar-container--in-project]="context === 'project'">
      <schedule-jobs-display-v3
        *ngIf="loadReadyToScheduleJobs"
        (displayToggled)="resizeCalendar()"
        (assignButtonClicked)="assignTradespersonToJob($event)"
      ></schedule-jobs-display-v3>
      <full-calendar #calendar [options]="calendarConfig" *ngIf="calendarOptionsReady"></full-calendar>
    </div>
    <schedule-key></schedule-key>
    <!--    <schedule-job-view #jobView (cardClosed)="handleCardClose()" [phase2Enabled]="true"></schedule-job-view>-->
  </div>
</div>
