import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { DocumentPackPresenter } from './document-pack.presenter';
import { DocumentActionType, IDocumentPackManagerConfig, IDocumentPackManagerVm } from './document-pack.model';
import { ProjectAttachment } from '../project-attachments/project-attachments.component';
import { DocumentPackLoaderComponent } from './components/document-pack-loader/document-pack-loader';
import { DocumentPackProgressComponent } from './document-pack-progress/document-pack-progress.component';
import { DocumentPackMainActionComponent } from './components/document-pack-main-action/document-pack-main-action.component';
import { DocumentPackDocumentRowComponent } from './components/document-pack-document-row/document-pack-document-row.component';
import { DocumentPackDocumentStageComponent } from './components/document-pack-document-stage/document-pack-document-stage.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor } from '@angular/common';
import { DocumentPackMessagesComponent } from './document-pack-messages/document-pack-messages.component';
import { InlineSpinnerComponent } from '@jump-tech-frontend/cards';
@Component({
  selector: 'app-document-pack',
  templateUrl: './document-pack.component.html',
  styleUrls: ['./document-pack.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    CoreComponentsAngularModule,
    NgFor,
    DocumentPackDocumentStageComponent,
    DocumentPackDocumentRowComponent,
    DocumentPackMainActionComponent,
    DocumentPackProgressComponent,
    DocumentPackLoaderComponent,
    DocumentPackMessagesComponent,
    InlineSpinnerComponent
  ],
  providers: [DocumentPackPresenter]
})
export class DocumentPackComponent implements OnDestroy, OnChanges {
  @Input() config: IDocumentPackManagerConfig;
  @Output() attachmentUpdated: EventEmitter<ProjectAttachment> = new EventEmitter<ProjectAttachment>();
  @Output() docPackRemoved: EventEmitter<void> = new EventEmitter<void>();
  vm: IDocumentPackManagerVm = null;

  constructor(private presenter: DocumentPackPresenter) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && this.config) {
      this.presenter.load(generatedVm => {
        this.vm = generatedVm;
      }, this.config);
    }
  }

  ngOnDestroy(): void {
    this.presenter.cleanup();
  }

  addDocumentPack() {
    this.presenter.addDocumentPack();
  }

  removeDocumentPack() {
    this.presenter.removeDocumentPack(this.docPackRemoved);
  }

  handleActionClick(htmlInput, doc) {
    switch (doc.actionType) {
      case DocumentActionType.UPLOAD_DOCUMENT:
        htmlInput.click();
        break;
      case DocumentActionType.PREVIEW_DOCUMENT:
        this.presenter.previewDocument(htmlInput, doc);
        break;
      case DocumentActionType.REVIEW_MISSING_INFO:
        this.presenter.reviewMissingInfo(htmlInput, doc);
        break;
      case DocumentActionType.REQUIRES_RESPONSE:
        this.presenter.provideResponse(doc, this.attachmentUpdated);
        break;
    }
  }

  handleRequired(doc) {
    this.presenter.toggleRequired(doc);
  }

  toggleStage(stage) {
    this.presenter.toggleStage(stage);
  }

  retry() {
    this.presenter.retry(this.config);
  }

  onFileChange(evt, document, projectId): void {
    this.presenter.uploadDocument(evt, document, projectId);
  }
}
