import { EventEmitter, Injectable } from '@angular/core';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { PresignedPost } from 'aws-sdk/clients/s3';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, firstValueFrom, Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../../core/api.service';
import { HttpGateway } from '../../core/http-gateway.service';
import { AttachmentsHelper } from '../../shared/attachments.helper';
import { DocumentPackMissingInfoComponent } from './document-pack-missing-info/document-pack-missing-info.component';
import { DocumentPackPreviewComponent } from './document-pack-preview/document-pack-preview.component';
import { DocumentPackResponseComponent } from './document-pack-response/document-pack-response.component';
import {
  ACCEPTED_FILE_TYPES,
  DOCUMENT_STATE_KEY_PREFIX,
  DocumentActionType,
  DocumentStageState,
  DocumentState,
  DocumentStateTransition,
  DocumentType,
  ErrorType,
  IAddDocumentPackDto,
  IDocumentDm,
  IDocumentPackDefinitionDetails,
  IDocumentPackDm,
  IDocumentPackI18n,
  IDocumentPackManagerConfig,
  IDocumentPackManagerDm,
  IDocumentProgress,
  IGetDocumentPackDto,
  IMessageDm,
  IMissingInfoDm,
  IPreviewDm,
  IResponseDm,
  IStageDm,
  TOGGLE_REQUIRED_TRANSITION_STATES,
  UpdateDocumentDocumentRequest,
  VALID_GROUPED_DOCUMENT_STATES
} from './document-pack.model';
import {
  GetActionLabel,
  GetI18nKeys,
  GetPreviewMessage,
  GetRegenerateHint,
  GetStateLabelTranslation,
  GetStateTransition,
  GetStateTransitionLabel,
  GetStateTransitionMessage,
  GetUploadHint
} from './document-pack.utils';

@Injectable({ providedIn: 'root' })
export class DocumentPackRepository {
  documentPackManager$: BehaviorSubject<IDocumentPackManagerDm> = null;
  errors$: Subject<string> = null;
  messages$: Subject<IMessageDm> = null;
  progress$: Subject<IDocumentProgress> = null;
  preview$: Subject<IPreviewDm> = null;
  missingInfo$: Subject<IMissingInfoDm> = null;
  response$: Subject<IResponseDm> = null;

  subscriptionList: Subscription[] = [];
  mainSubscription: Subscription = null;
  previewSubscription: Subscription = null;
  missingInfoSubscription: Subscription = null;
  responseSubscription: Subscription = null;

  previewModalSub: Subscription = null;
  responseModalSub: Subscription = null;
  missingInfoModalSub: Subscription = null;

  i18n: IDocumentPackI18n = {};
  defaultDocumentPackManager: IDocumentPackManagerDm = {} as IDocumentPackManagerDm;
  currentDocumentPack: IDocumentPackManagerDm = {} as IDocumentPackManagerDm;
  documentPackDefinition: IDocumentPackDefinitionDetails = {} as IDocumentPackDefinitionDetails;
  defaultMissingInfo: IMissingInfoDm = {} as IMissingInfoDm;
  currentMissingInfo: IMissingInfoDm = {} as IMissingInfoDm;
  currentPreview: IPreviewDm = {} as IPreviewDm;

  defaultResponse: IResponseDm = {} as IResponseDm;
  currentResponse: IResponseDm = {} as IResponseDm;

  documentLastModified: string;
  s3Root = 'https://s3.eu-west-2.amazonaws.com/';
  bucketName = `pathway-attachments-${environment.bucketSuffix}`;
  uploadInProgress: { fileName: string; progress: number } = null;
  refreshInProgress = false;
  refreshWaitMs = 5000;
  regenerateWaitMs = 9000;
  config: IDocumentPackManagerConfig = null;
  modalRef: NgbModalRef;

  MAIN_ACTION_SPINNER = 'dockPackMainActionSpinner';
  MESSAGES_SPINNER = 'dockPackMessagesSpinner';

  constructor(
    private httpGateway: HttpGateway,
    private i18nService: TranslocoService,
    private spinnerService: NgxSpinnerService,
    private attachmentsHelper: AttachmentsHelper,
    private apiService: ApiService,
    private modalService: NgbModal
  ) {
    this.init();
  }

  public init() {
    this.documentPackManager$ = new BehaviorSubject<IDocumentPackManagerDm>(null);
    this.errors$ = new Subject();
    this.messages$ = new Subject();
    this.progress$ = new Subject();
    this.preview$ = new Subject();
    this.missingInfo$ = new Subject();
    this.response$ = new Subject();
    this.setupDefaults();
  }

  private setupDefaults() {
    this.initI18ns();
    this.defaultDocumentPackManager = {
      hasDocumentPack: false,
      canRemoveDocumentPack: false,
      addDocumentPackLabel: this.i18n.addDocumentPack,
      removeDocumentPackLabel: this.i18n.removeDocumentPack,
      stageLabel: this.i18n.stage,
      addDocumentPackInProgress: false,
      autoAddDocumentPackInProgress: false,
      removeDocumentPackInProgress: false,
      documentPack: { documents: null, stages: null } as IDocumentPackDm,
      fetchingDocumentPack: false,
      allowRetry: false,
      retryLabel: this.i18n.retry,
      projectId: null,
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      uploadInProgress: false,
      stateChangeInProgress: false,
      isPreviewing: false,
      isRefreshing: false,
      isEmptyDocumentPack: false,
      emptyDocumentPackLabel: this.i18n.emptyDocumentPack
    };
    this.defaultMissingInfo = {
      missing: [],
      documentName: null,
      label: this.i18n.missingInfo,
      closeLabel: this.i18n.close,
      uploadHint: this.i18n.manualUploadHint,
      uploadLabel: this.i18n.upload,
      message: this.i18n.reviewMissingInfo,
      isUploading: false,
      qaUpload: null,
      qaCloseModal: null
    };
    this.defaultResponse = {
      id: null,
      approvedLabel: this.i18n.approvedLabel,
      stateLabel: this.i18n.awaitingResponse,
      responseLabel: this.i18n.response,
      closeLabel: this.i18n.close,
      completeHint: this.i18n.responseCompleteHint,
      completeLabel: this.i18n.complete,
      documentName: null,
      mainDocumentFileName: null,
      message: this.i18n.pleaseProvideResponse,
      rejectedLabel: this.i18n.rejectedLabel,
      reviseLabel: this.i18n.revise,
      uploadLabel: this.i18n.uploadEvidence,
      actionType: null,
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      isUploading: false,
      isRevising: false,
      isCompleting: false
    };
  }

  public initDocumentPackManager(callback, config: IDocumentPackManagerConfig) {
    if (this.mainSubscription) {
      this.mainSubscription.unsubscribe();
    }
    this.mainSubscription = this.documentPackManager$.subscribe(dm => {
      if (dm) {
        callback(dm);
      }
    });
    this.cacheConfig(config);
    if (!this.config.isRefreshing) {
      this.currentDocumentPack = null;
    }
    this.loadDocumentPackManager().then();
  }

  public getDocumentPackManagerSubscription(callback) {
    return this.documentPackManager$.subscribe(callback);
  }

  public retryGetDocumentPackManager(config) {
    this.cacheConfig(config);
    this.clearErrors();
    this.loadDocumentPackManager().then();
  }

  public getErrors(callback): void {
    this.subscriptionList.push(this.errors$.subscribe(callback));
  }

  private setError(e) {
    this.errors$.next(e);
  }

  public clearErrors() {
    this.errors$.next(null);
  }

  public getMessages(callback) {
    this.subscriptionList.push(this.messages$.subscribe(callback));
  }

  private setMessage(message, showLoader) {
    if (showLoader) {
      this.spinnerService.show(this.MESSAGES_SPINNER).then();
    } else {
      this.spinnerService.hide(this.MESSAGES_SPINNER).then();
    }
    this.messages$.next({ message, showLoader });
  }

  public clearMessages() {
    this.messages$.next(null);
  }

  private cacheConfig(config: IDocumentPackManagerConfig) {
    this.config = config;
  }

  private async loadDocumentPackManager() {
    if (this.config.autoAddInProgress) {
      this.setMessage(this.i18n.addingDocumentPack, false);
    } else {
      this.clearMessages();
    }

    if (this.config.documentPackId) {
      if (!this.refreshInProgress) {
        await this.getDocumentPackAndNotify(this.config.showLoading, this.config.isRefreshing);
      }
    } else {
      if (this.config.documentPackDefinition) {
        const { headers } = this.getApiHeadersAndParams();
        this.documentPackDefinition = await this.httpGateway.get(
          `${environment.apiDocumentPackDefinitionUrl}/${this.config.documentPackDefinition.id}/${this.config.documentPackDefinition.version}`,
          {},
          headers
        );
      }

      this.documentPackManager$.next({
        ...this.defaultDocumentPackManager,
        projectId: this.config.projectId,
        autoAddDocumentPackInProgress: this.config.autoAddInProgress,
        isEmptyDocumentPack: this.isEmptyDocumentPackDefinition(this.documentPackDefinition)
      });
    }
  }

  private isEmptyDocumentPackDefinition(docPackDefinition: IDocumentPackDefinitionDetails): boolean {
    return docPackDefinition && (!docPackDefinition.documents || !docPackDefinition.documents.length);
  }

  private notifyAddDocumentProgress(inProgress: boolean) {
    this.spinnerService.show(this.MAIN_ACTION_SPINNER).then();
    const dataDm: IDocumentPackManagerDm = {
      ...this.defaultDocumentPackManager,
      addDocumentPackInProgress: inProgress
    };
    this.documentPackManager$.next(dataDm);
  }

  private notifyRemoveDocumentProgress(inProgress: boolean) {
    this.spinnerService.show(this.MAIN_ACTION_SPINNER).then();
    const dataDm: IDocumentPackManagerDm = {
      ...this.currentDocumentPack,
      removeDocumentPackInProgress: inProgress
    };
    this.documentPackManager$.next(dataDm);
  }

  public async addDocumentPackToProject() {
    this.notifyAddDocumentProgress(true);
    try {
      const { headers, params } = this.getApiHeadersAndParams();
      const dto: IAddDocumentPackDto = await this.httpGateway.post(environment.apiDocumentPackUrl, params, headers);
      this.config = { ...this.config, documentPackId: dto.documentPackId };
      await this.getDocumentPackAndNotify(true);
      this.spinnerService.hide(this.MAIN_ACTION_SPINNER).then();
    } catch (e) {
      this.handleErrors(ErrorType.addDocumentPack, e);
    }
  }

  public async removeDocumentPackFromProject(docPackRemoved: EventEmitter<void>) {
    this.notifyRemoveDocumentProgress(true);
    try {
      const { headers } = this.getApiHeadersAndParams();
      await this.httpGateway.delete(`${environment.apiDocumentPackUrl}/${this.config.documentPackId}`, headers);
      this.currentDocumentPack = null;
      this.documentPackManager$.next({
        ...this.defaultDocumentPackManager,
        projectId: this.config.projectId,
        isEmptyDocumentPack: this.isEmptyDocumentPackDefinition(this.documentPackDefinition)
      });
      this.spinnerService.hide(this.MAIN_ACTION_SPINNER).then();
      docPackRemoved.emit();
      this.clearMessages();
    } catch (e) {
      this.handleErrors(ErrorType.removeDocumentPack, e);
    }
  }

  public loadMissingInfo(callback) {
    this.missingInfoSubscription = this.missingInfo$.subscribe(callback);
  }

  public loadPreview(callback) {
    this.previewSubscription = this.preview$.subscribe(callback);
  }

  public loadResponseModal(callback) {
    this.responseSubscription = this.response$.subscribe(callback);
  }

  public closeModal(reason) {
    this.modalService.dismissAll(reason);
  }

  private async regenerateDocument(document) {
    const { headers } = this.getApiHeadersAndParams();
    await this.httpGateway.put(
      `${environment.apiDocumentPackUrl}/${this.config.documentPackId}/document/${document.id}/regenerate`,
      null,
      headers
    );
  }

  private notifyRegenerating() {
    const previewDm = {
      ...this.currentPreview,
      actionEnabled: false,
      message: this.i18n.regeneratingDocument,
      isRegenerating: true
    };
    this.preview$.next(previewDm);
  }

  private async notifyRegeneratingComplete(doc) {
    const signedUrl = await firstValueFrom(this.apiService.getSignedAttachmentUrl(doc.fileName, this.config.projectId));
    // notify the preview with the new resource url
    // timeout used here as we don't know how long it will take to regenerate the document
    setTimeout(async () => {
      await this.setDocumentLastModified(signedUrl);
      await this.getDocumentPackAndNotify();
      this.modalRef.componentInstance.setDocUrl = signedUrl + '#view=fitH';
      this.currentPreview = {
        ...this.currentPreview,
        actionEnabled: true,
        isRegenerating: false,
        lastModifiedDate: this.documentLastModified,
        canRegenerate: false
      };
      this.preview$.next(this.currentPreview);
    }, this.regenerateWaitMs);
  }

  public async regenerate(doc) {
    try {
      this.notifyRegenerating();
      const currentDoc = this.getCurrentDocumentById(doc.id);
      const hasMissingInfo = currentDoc.documentModel.missingInformation.length > 0;
      await this.regenerateDocument(doc);
      if (hasMissingInfo) {
        // we should close the modal and fetch the pack as we don't have a document to generate
        this.modalRef.dismiss('auto close - not ready to generate');
        await this.getDocumentPackAndNotify();
      } else {
        await this.notifyRegeneratingComplete(doc);
      }
    } catch (e) {
      this.handleErrors(ErrorType.regenerateDocument, e);
    }
  }

  public previewActionClick(doc) {
    this.performPreviewMainAction(doc).then();
  }

  private async performPreviewMainAction(doc) {
    this.clearErrors();
    this.notifyPreviewMainActionWorking(doc);
    await this.transitionDocumentState(doc);
    this.modalRef.close('success');
  }

  private notifyPreviewMainActionWorking(doc) {
    const previewDm = {
      ...this.currentPreview,
      message: GetStateTransitionMessage(doc.stateTransition, this.i18n),
      isUpdatingState: true,
      actionEnabled: false
    };
    this.preview$.next(previewDm);
  }

  public getProgress(callback) {
    this.subscriptionList.push(this.progress$.subscribe(callback));
  }

  public updateProgress(fileName, progress) {
    this.progress$.next({
      fileName,
      progress: parseInt(progress),
      inProgress: true,
      uploadingLabel: this.i18n.uploading
    });
    if (progress === 100) {
      this.clearProgress();
    }
  }

  public clearProgress() {
    setTimeout(() => {
      this.progress$.next({ fileName: null, progress: null, inProgress: false, uploadingLabel: this.i18n.uploading });
    }, 1000);
  }

  getSanitisedFilename(fileName: string) {
    return fileName.replace(/_\d{13}(\..*)$/, (match, p1) => {
      return p1;
    });
  }

  public async uploadDocument(evt, document, isAttachment = false, outputEvent?) {
    try {
      this.clearErrors();
      if (evt.target.files && evt.target.files.length) {
        const [file] = evt.target.files;
        if (!this.attachmentsHelper.checkIsFileTypeAndSizeOK(file, this.defaultDocumentPackManager.acceptedFileTypes)) {
          return;
        }

        const { key, fileName, fileType, cleanFileName, uploadDate } = this.getFileDetails(
          file,
          this.config.projectId,
          document
        );
        this.initFileProgress(cleanFileName);
        this.notifyManualUploadFromModal();
        const preSignedPost: PresignedPost = await firstValueFrom(
          this.apiService.getSignedPostUrl(key, this.bucketName, fileType)
        );
        this.subscriptionList.push(
          this.attachmentsHelper
            .postAndTrackProgress(preSignedPost, file, null, this.updateProgress.bind(this, cleanFileName))
            .subscribe(async () => {
              const doc = { ...document, fileName };
              if (isAttachment && outputEvent) {
                this.addToProjectAttachments(fileName, uploadDate, doc, outputEvent);
              }
              await this.transitionDocumentState(doc);
              await this.notifyManualUploadFromModalComplete(doc);
            })
        );
      }
    } catch (e) {
      this.handleErrors(ErrorType.uploadDocument, e);
    }
  }

  private addToProjectAttachments(fileName, uploadDate: number, doc, outputEvent) {
    const attachment = {
      key: fileName,
      uploadDate: uploadDate,
      uploadedBy: this.config.user.userName,
      uploadedByLabel: this.config.user.label,
      uploadedByTenant: this.config.tenant
    };
    doc.fileName = doc.sentDocumentName;
    outputEvent.emit(attachment);
  }

  private initFileProgress(cleanFileName: string) {
    this.setMessage(this.i18n.uploadingDocument, true);
    this.documentPackManager$.next({ ...this.currentDocumentPack, uploadInProgress: true });
    this.progress$.next({
      fileName: cleanFileName,
      progress: 5,
      inProgress: true,
      uploadingLabel: this.i18n.uploading
    });
  }

  private getFileDetails(file, projectId, document) {
    const { key, fileName, fileType, uploadDate } = this.attachmentsHelper.getFileUploadDetail(
      file,
      projectId,
      document.name
    );
    const cleanFileName = this.getSanitisedFilename(fileName);
    return { key, fileName, fileType, cleanFileName, uploadDate };
  }

  private async transitionDocumentState(doc, showMessage = true) {
    try {
      const url = this.s3Root + this.bucketName;
      if (showMessage) {
        this.setMessage(this.i18n.savingDocument, true);
      }
      const updateDocumentPack = this.updateDocumentPackDocument(doc, url, doc.fileName, doc.stateTransition);
      await Promise.all([updateDocumentPack]);
      this.documentPackManager$.next({
        ...this.currentDocumentPack,
        uploadInProgress: false,
        stateChangeInProgress: false
      });
      this.clearMessages();
      this.setOutstandingDocuments(this.currentDocumentPack.documentPack.stages);
    } catch (e) {
      this.handleStateTransitionError(doc.stateTransition, e);
    }
  }

  private async updateDocumentPackDocument(document, baseUrl, fileName, stateTransition) {
    const params: UpdateDocumentDocumentRequest = {
      documentPackId: this.config.documentPackId,
      documentId: document.id,
      stateTransition
    };

    const url = this.getDocumentUrl(baseUrl, fileName);
    if (url) {
      params.url = url;
    }
    const { headers } = this.getApiHeadersAndParams();
    await this.httpGateway.put(
      `${environment.apiDocumentPackUrl}/${this.config.documentPackId}/document/${document.id}`,
      params,
      headers
    );
    await this.getDocumentPackAndNotify();
  }

  private getDocumentUrl(baseUrl: string, fileName: string | null | undefined): string {
    if (fileName) {
      return `${baseUrl}/${this.config.projectId}/${fileName}`;
    } else {
      return null;
    }
  }

  private notifyPreviewingDocument(isPreviewing: boolean, stateChangeInProgress: boolean) {
    const dataDm = { ...this.currentDocumentPack, isPreviewing, stateChangeInProgress };
    this.documentPackManager$.next(dataDm);
  }

  public async previewDocument(htmlInput, doc) {
    try {
      this.previewModalSub = this.initPreview(doc);
      await this.openPreviewModal(htmlInput, doc, this.previewModalSub);
    } catch (e) {
      this.handleErrors(ErrorType.previewDocument, e);
    }
  }

  public async reviewMissingInfo(htmlInput, doc) {
    try {
      this.missingInfoModalSub = this.initReviewMissingInfo(doc);
      await this.openReviewMissingInfoModal(this.missingInfoModalSub, htmlInput);
    } catch (e) {
      this.handleErrors(ErrorType.reviewMissingInfo, e);
    }
  }

  public async provideResponse(doc, evtEmitter) {
    try {
      this.responseModalSub = this.initResponse(doc);
      await this.openResponseModal(doc, this.responseModalSub, evtEmitter);
    } catch (e) {
      this.handleErrors(ErrorType.provideResponse, e);
    }
  }

  public async setNotRequiredFromPreview(doc) {
    if (this.previewSubscription) {
      this.preview$.next({
        ...this.currentPreview,
        stateLabel: this.i18n.notRequired,
        isUploading: false,
        isSettingNotRequired: true,
        message: GetStateTransitionMessage(DocumentStateTransition.NOT_REQUIRED, this.i18n),
        actionEnabled: false
      });
    }
    await this.toggleRequired(doc);
    if (this.modalRef) {
      this.modalRef.dismiss('Set document not required');
    }
  }

  public async toggleRequired(doc) {
    this.clearErrors();
    this.documentPackManager$.next({ ...this.currentDocumentPack, stateChangeInProgress: true });
    const document = this.mapRequiredStateTransition(doc);
    await this.transitionDocumentState(document, false);
  }

  private mapRequiredStateTransition(doc) {
    if (
      doc.state === DocumentState.AWAITING_DOCUMENT ||
      doc.state === DocumentState.INITIAL ||
      doc.state === DocumentState.AWAITING_APPROVAL
    ) {
      return { ...doc, stateTransition: DocumentStateTransition.NOT_REQUIRED, fileName: doc.fileName };
    } else {
      if (doc.state !== DocumentState.READY_TO_REVIEW) {
        return { ...doc, fileName: doc.fileName };
      }
      return { ...doc };
    }
  }

  private initReviewMissingInfo(doc): Subscription {
    this.clearErrors();
    this.setMessage(this.i18n.openingMissingInfo, true);
    return this.subscribeAndNotifyActiveModal(doc);
  }

  private initResponse(doc): Subscription {
    this.clearErrors();
    this.setMessage(this.i18n.openingResponse, true);
    return this.subscribeAndNotifyActiveModal(doc);
  }

  private notifyManualUploadFromModal() {
    if (this.missingInfoSubscription) {
      this.missingInfo$.next({
        ...this.currentMissingInfo,
        label: this.i18n.manualOverride,
        isUploading: true,
        message: GetStateTransitionMessage(DocumentStateTransition.UPLOADED, this.i18n)
      });
    }
    if (this.previewSubscription) {
      this.preview$.next({
        ...this.currentPreview,
        stateLabel: this.i18n.manualOverride,
        isUploading: true,
        message: GetStateTransitionMessage(DocumentStateTransition.UPLOADED, this.i18n),
        actionEnabled: false
      });
    }
    if (this.responseSubscription) {
      this.response$.next({
        ...this.currentResponse,
        message: this.i18n.uploadingResponseEvidence,
        isUploading: true
      });
    }
  }

  private async notifyManualUploadFromModalComplete(doc) {
    if (this.previewSubscription && this.modalRef) {
      const signedUrl = await firstValueFrom(
        this.apiService.getSignedAttachmentUrl(doc.fileName, this.config.projectId)
      );
      await this.setDocumentLastModified(signedUrl);
      this.modalRef.componentInstance.setDocUrl = signedUrl + '#view=fitH';

      this.currentPreview = {
        ...this.currentPreview,
        actionEnabled: true,
        isUploading: false,
        lastModifiedDate: this.documentLastModified,
        canRegenerate: doc.documentType === DocumentType.AUTO
      };
      this.preview$.next(this.currentPreview);
    }

    if (this.missingInfoSubscription) {
      this.missingInfo$.next({ ...this.defaultMissingInfo });
    }

    if (this.responseSubscription) {
      this.response$.next(this.defaultResponse);
    }

    if (
      (this.modalRef && doc.actionType === DocumentActionType.REVIEW_MISSING_INFO) ||
      (this.modalRef && doc.actionType === DocumentActionType.REQUIRES_RESPONSE)
    ) {
      this.modalRef.close('manual upload success');
    }
  }

  private async openReviewMissingInfoModal(modalSub, htmlInput) {
    this.modalRef = this.modalService.open(DocumentPackMissingInfoComponent, {
      windowClass: 'document-pack-modal document-pack-modal--sm'
    });
    this.modalRef.componentInstance.fileInput = htmlInput;
    this.clearMessages();
    this.setOutstandingDocuments(this.currentDocumentPack.documentPack.stages);
    this.modalRef.result.finally(() => {
      this.missingInfo$.next(this.defaultMissingInfo);
      modalSub.unsubscribe();
      this.unsubscribeMissingInfo();
      this.modalRef = null;
    });
  }

  private async openResponseModal(doc, modalSub, evtEmitter) {
    this.modalRef = this.modalService.open(DocumentPackResponseComponent, {
      windowClass: 'document-pack-modal document-pack-modal--sm'
    });
    this.modalRef.componentInstance.outputEvent = evtEmitter;
    this.clearMessages();
    this.modalRef.result.finally(() => {
      this.clearMessages();
      this.cleanResponse();
      modalSub.unsubscribe();
      this.responseSubscription.unsubscribe();
      this.modalRef = null;
    });
  }

  private cleanResponse() {
    this.response$.next(this.defaultResponse);
  }

  private initPreview(doc): Subscription {
    this.clearErrors();
    this.setMessage(this.i18n.openingPreview, true);
    this.notifyPreviewingDocument(true, true);
    return this.subscribeAndNotifyActiveModal(doc);
  }

  private async openPreviewModal(htmlInput, doc, modalSub) {
    const signedUrl = await firstValueFrom(this.apiService.getSignedAttachmentUrl(doc.fileName, this.config.projectId));

    if (doc.state == DocumentState.READY_TO_REVIEW) {
      await this.transitionDocumentToAwaitingApproval(doc);
    }
    await this.setDocumentLastModified(signedUrl);
    this.modalRef = this.modalService.open(DocumentPackPreviewComponent, { windowClass: 'document-pack-modal' });
    this.modalRef.componentInstance.docUrl = signedUrl + '#view=fitH';

    this.modalRef.componentInstance.htmlFileInput = htmlInput;
    this.modalRef.componentInstance.fileChangeEvent.subscribe(async evt => {
      doc.stateTransition = DocumentStateTransition.UPLOADED; // force state transition for the upload
      await this.uploadDocument(evt, doc);
    });

    this.clearMessages();
    this.modalRef.result.finally(() => {
      this.cleanPreview();
      modalSub.unsubscribe();
      this.previewSubscription.unsubscribe();
      this.notifyPreviewingDocument(false, false);
      this.modalRef = null;
    });
  }

  private async setDocumentLastModified(url: string) {
    const res = await this.httpGateway.getBlobAsPlainText(url, { 'Content-Type': 'application/pdf' });

    let dateString = res.headers.get('Last-Modified');
    if (dateString) {
      dateString = dateString.replace(' GMT', '');
      this.documentLastModified = JumptechDate.from(dateString, {
        sourceFormat: 'EEE, dd MMM yyyy HH:mm:ss',
        sourceLocale: 'en-US'
      })?.toTimestampFormat();
    }
  }

  public async reviseDocument(docVm) {
    const doc = this.getCurrentDocumentById(docVm.id);
    doc.stateTransition = DocumentStateTransition.REVISE;
    this.notifyResponseRevisingDocument();
    await this.transitionDocumentState(doc, false);
    this.modalRef.close('revised');
  }

  public async completeDocument(docVm) {
    const doc = this.getCurrentDocumentById(docVm.id);
    doc.stateTransition = DocumentStateTransition.COMPLETED;
    this.notifyResponseCompletingDocument();
    await this.transitionDocumentState(doc, false);
    this.modalRef.close('completed');
  }

  private notifyResponseRevisingDocument() {
    const dataDm: IResponseDm = { ...this.currentResponse, isRevising: true, message: this.i18n.revisingDocument };
    this.response$.next(dataDm);
  }

  private notifyResponseCompletingDocument() {
    const dataDm: IResponseDm = { ...this.currentResponse, isCompleting: true, message: this.i18n.completingDocument };
    this.response$.next(dataDm);
  }

  private async transitionDocumentToAwaitingApproval(doc): Promise<void> {
    await this.transitionDocumentState(doc, false);
    doc.state = DocumentState.AWAITING_APPROVAL;
    doc.stateTransition = DocumentStateTransition.APPROVED;
    doc.stateLabel = this.i18n.awaitingApproval;

    this.notifyPreviewingDocument(true, false);
  }

  private cleanPreview() {
    this.preview$.next({
      id: null,
      name: null,
      documentType: null,
      message: null,
      state: null,
      stateLabel: null,
      stateTransition: null,
      cancelLabel: null,
      actionLabel: null,
      actionSubmitLabel: null,
      actionEnabled: null,
      actionHidden: null,
      fileName: null,
      isUploading: false,
      uploadHint: null,
      uploadAlternativeHint: null,
      regenerateHint: null,
      uploadLabel: null,
      regenerateLabel: null,
      canRegenerate: null,
      isRegenerating: false,
      hasMissingInfo: false,
      lastModifiedDate: null,
      lastModifiedLabel: null,
      canSetNotRequired: false,
      notRequiredLabel: null,
      isSettingNotRequired: false,
      isUpdatingState: false,
      qaMainAction: null,
      qaNotRequired: null,
      qaCloseModal: null,
      qaUpload: null,
      qaRegenerate: null
    });
  }

  private subscribeAndNotifyActiveModal(doc: { actionType: DocumentActionType }) {
    return this.modalService.activeInstances.subscribe(x => {
      if (x.length) {
        try {
          if (doc.actionType === DocumentActionType.PREVIEW_DOCUMENT) {
            const dataDm = this.getPreviewDm(doc);
            this.currentPreview = { ...dataDm };
            setTimeout(() => this.preview$.next(dataDm));
          }
          if (doc.actionType === DocumentActionType.REVIEW_MISSING_INFO) {
            const dataDm = this.getMissingInfoDm(doc);
            this.currentMissingInfo = { ...dataDm };
            setTimeout(() => this.missingInfo$.next(dataDm));
          }
          if (doc.actionType === DocumentActionType.REQUIRES_RESPONSE) {
            const dataDm = this.getResponseDm(doc);
            this.currentResponse = { ...dataDm };
            setTimeout(() => this.response$.next(dataDm));
          }
        } catch (e) {
          this.handleModalErrors(doc, e);
        }
      }
    });
  }

  private handleModalErrors(doc: { actionType: DocumentActionType }, e) {
    let errorType;
    switch (doc.actionType) {
      case DocumentActionType.PREVIEW_DOCUMENT:
        errorType = ErrorType.previewDocument;
        break;
      case DocumentActionType.REVIEW_MISSING_INFO:
        errorType = ErrorType.reviewMissingInfo;
        break;
      case DocumentActionType.REQUIRES_RESPONSE:
        errorType = ErrorType.provideResponse;
    }
    this.handleErrors(errorType, e);
  }

  private getPreviewDm(doc): IPreviewDm {
    const currentDoc = this.getCurrentDocumentById(doc.id);
    return {
      ...doc,
      uploadHint: GetUploadHint(doc, this.i18n),
      uploadAlternativeHint: this.i18n.uploadAlternativeHint,
      message: GetPreviewMessage(doc.state, this.i18n),
      actionSubmitLabel: GetStateTransitionLabel(doc.state, doc.isSendingEmailOnApproval, this.i18n),
      uploadLabel: this.i18n.upload,
      cancelLabel: this.i18n.cancel,
      regenerateLabel: this.i18n.regenerate,
      regenerateHint: GetRegenerateHint(doc, this.i18n),
      canSetNotRequired: this.canToggleRequired(currentDoc),
      notRequiredLabel: this.i18n.notRequired,
      canRegenerate:
        doc.documentType === DocumentType.AUTO &&
        doc.state === DocumentState.AWAITING_APPROVAL &&
        (currentDoc.manuallyOverwritten ?? true),
      hasMissingInfo: doc.documentType === DocumentType.AUTO && doc.missingInfo.length > 0,
      lastModifiedDate: this.documentLastModified,
      lastModifiedLabel: this.i18n.reviewingVersion
    };
  }

  private getMissingInfoDm(doc): IMissingInfoDm {
    const data = doc.missingInfo.map(x => ({
      section: x.name,
      fields: [...x.subFields]
    }));
    return {
      ...this.defaultMissingInfo,
      missing: data,
      uploadHint: doc.infoBox || this.i18n.manualUploadHint,
      documentName: doc.name
    };
  }

  private getResponseDm(doc): IResponseDm {
    const currentDoc = this.getCurrentDocumentById(doc.id);
    return {
      ...this.defaultResponse,
      id: doc.id,
      documentName: doc.name,
      mainDocumentFileName: currentDoc.url.split('/').pop(),
      actionType: DocumentActionType.REQUIRES_RESPONSE
    };
  }

  private async getDocumentPackAndNotify(showLoading = false, isRefreshing = false) {
    const dataDm: IDocumentPackManagerDm = {
      ...this.defaultDocumentPackManager,
      hasDocumentPack: true,
      fetchingDocumentPack: showLoading,
      projectId: this.config.projectId,
      autoAddDocumentPackInProgress: this.config.autoAddInProgress
    };

    if (showLoading) {
      this.documentPackManager$.next(dataDm);
    }

    if (isRefreshing && this.currentDocumentPack?.documentPack) {
      this.refreshInProgress = true;
      this.currentDocumentPack.isRefreshing = true;
      this.documentPackManager$.next(this.currentDocumentPack);
    }

    try {
      const dto: IGetDocumentPackDto = await this.fetchDocumentPackById(isRefreshing);
      dataDm.hasDocumentPack = true;
      dataDm.documentPack = this.transformDto(dto);
      dataDm.canRemoveDocumentPack = dataDm.documentPack.deletable;
      dataDm.fetchingDocumentPack = false;
      dataDm.isRefreshing = false;
      dataDm.isEmptyDocumentPack = !dataDm.documentPack.documents?.length;

      this.setStageOpenState(dataDm);

      this.refreshInProgress = false;
      this.currentDocumentPack = dataDm;
      this.documentPackManager$.next(dataDm);
    } catch (e) {
      this.handleErrors(ErrorType.fetchDocumentPackById, e);
    }
  }

  private setStageOpenState(dataDm: IDocumentPackManagerDm) {
    if (
      this.currentDocumentPack &&
      this.currentDocumentPack.documentPack &&
      this.currentDocumentPack.documentPack.stages?.length
    ) {
      let updatedStages;
      const existingActiveStage = this.currentDocumentPack.documentPack.stages.find(
        stage => stage.state === DocumentStageState.ACTIVE
      ).name;
      const newActiveStage = dataDm.documentPack.stages.find(stage => stage.state === DocumentStageState.ACTIVE).name;

      if (existingActiveStage !== newActiveStage) {
        updatedStages = dataDm.documentPack.stages.map(x => {
          return { ...x, isOpen: x.state === DocumentStageState.ACTIVE };
        });
      } else {
        updatedStages = dataDm.documentPack.stages.map(x => {
          return {
            ...x,
            isOpen: this.currentDocumentPack.documentPack.stages.find(stage => stage.name === x.name).isOpen
          };
        });
      }
      dataDm.documentPack.stages = updatedStages;
    }
  }

  private async fetchDocumentPackById(isRefreshing = false) {
    if (!this.config.documentPackId) {
      return;
    }

    if (isRefreshing) {
      await this.wait(this.refreshWaitMs);
    }
    const { headers } = this.getApiHeadersAndParams();
    return await this.httpGateway.get(`${environment.apiDocumentPackUrl}/${this.config.documentPackId}`, {}, headers);
  }

  private wait(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public toggleStage(stage) {
    this.currentDocumentPack.documentPack.stages = this.currentDocumentPack.documentPack.stages.map(x => {
      if (x.name === stage.name) {
        return { ...x, isOpen: !x.isOpen };
      }
      return x;
    });
    this.documentPackManager$.next(this.currentDocumentPack);
  }

  private hasDocumentGrouping(documentDefinition): boolean {
    if (!documentDefinition) {
      return false;
    }

    return !!documentDefinition.requiredByProjectMetaStatus;
  }

  private getStageName(metaStatus) {
    return this.config.projectStates.find(x => x.metaStatus === metaStatus)?.label;
  }

  private getStageState(metaStatus) {
    const currentStatusIndex = this.config.projectStates.findIndex(x => x.status === this.config.currentProjectState);
    const stageIndex = this.config.projectStates.findIndex(x => x.metaStatus === metaStatus);
    if (currentStatusIndex > stageIndex) {
      return DocumentStageState.PREVIOUS;
    }
    if (currentStatusIndex < stageIndex) {
      return DocumentStageState.FUTURE;
    }
    return DocumentStageState.ACTIVE;
  }

  private setOutstandingDocuments(stages): IStageDm[] | null {
    if (stages) {
      const activeStage = stages.find(stage => stage.state === DocumentStageState.ACTIVE);
      let docsOutstanding = 0;
      activeStage.documents.map(doc => {
        if (!VALID_GROUPED_DOCUMENT_STATES.includes(doc.state.currentState)) {
          docsOutstanding++;
        }
      });
      if (docsOutstanding) {
        const messageSuffix =
          docsOutstanding === 1 ? this.i18n.documentOutstandingSingle : this.i18n.documentOutstandingMultiple;
        this.setMessage(`${docsOutstanding} ${messageSuffix}`, false);
      }
      return stages.map(stage => {
        if (stage.state === DocumentStageState.ACTIVE) {
          return { ...stage, outstandingDocuments: docsOutstanding };
        }
        return stage;
      });
    }
  }

  private setActiveStage(stages): IStageDm[] {
    const currentStatusIndex = this.config.projectStates.findIndex(x => x.status === this.config.currentProjectState);
    let activeStage = null;

    stages.forEach((stage, index) => {
      if (index === 0) {
        activeStage = stage.metaStatus;
      }
      if (currentStatusIndex >= stage.metaStatusIndex) {
        activeStage = stage.metaStatus;
      }
    });

    return stages.map(stage => {
      if (stage.metaStatus === activeStage) {
        return { ...stage, state: DocumentStageState.ACTIVE, isOpen: true };
      }
      return { ...stage, isOpen: false };
    });
  }

  private parseDocument(doc): IDocumentDm {
    const documentActionType = this.getActionType(doc);
    return {
      ...doc,
      actionType: documentActionType,
      allowRequiredTransition: this.canToggleRequired(doc),
      stateLabel: this.getStateLabelTranslation(doc),
      stateTransition: GetStateTransition(doc.state.currentState),
      actionLabel: GetActionLabel(documentActionType, doc.state.currentState, this.i18n),
      actionSubmitLabel: GetStateTransitionLabel(doc.state.currentState, doc.isSendingEmailOnApproval, this.i18n)
    };
  }

  private getStatusIndex(metaStatus): number {
    return this.config.projectStates.findIndex(x => x.metaStatus === metaStatus);
  }

  private buildGroupedDocuments(pack): IDocumentPackDm {
    const grouped = [];
    pack.documents.forEach(doc => {
      if (!grouped[doc.documentDefinition.requiredByProjectMetaStatus]) {
        grouped[doc.documentDefinition.requiredByProjectMetaStatus] = {
          name: this.getStageName(doc.documentDefinition.requiredByProjectMetaStatus),
          metaStatus: doc.documentDefinition.requiredByProjectMetaStatus,
          metaStatusIndex: this.getStatusIndex(doc.documentDefinition.requiredByProjectMetaStatus),
          state: this.getStageState(doc.documentDefinition.requiredByProjectMetaStatus),
          documents: []
        };
      }
      grouped[doc.documentDefinition.requiredByProjectMetaStatus].documents.push(this.parseDocument(doc));
    });

    let stages = Object.entries(grouped)
      .sort(([, a], [, b]) => a.metaStatusIndex - b.metaStatusIndex)
      .map((stage, i) => ({ ...stage[1], stageNumber: ++i }));

    stages = this.setActiveStage(stages);
    stages = this.setOutstandingDocuments(stages);

    return {
      ...pack,
      stages
    };
  }

  private transformDto(dto): IDocumentPackDm {
    const pack = dto;
    const hasGroupedDocuments = this.hasDocumentGrouping(pack.documents[0]?.documentDefinition);
    if (hasGroupedDocuments) {
      return this.buildGroupedDocuments(pack);
    } else {
      return {
        ...pack,
        documents: pack.documents.map(doc => {
          return this.parseDocument(doc);
        })
      };
    }
  }

  private getStateLabelTranslation(doc): string {
    if (
      doc.state.currentState === DocumentState.INITIAL &&
      doc.documentDefinition.requiredProjectMetaStatus &&
      this.config.projectStates
    ) {
      const foundState = this.config.projectStates.find(
        state => state.metaStatus === doc.documentDefinition.requiredProjectMetaStatus
      );
      if (foundState) {
        const stateLabel = foundState.label;
        return `${this.i18n.pending}: '${stateLabel}'`;
      }
    }
    return GetStateLabelTranslation(doc.state.displayStateTranslationKey, this.i18n);
  }

  private canToggleRequired(doc): boolean {
    let canTransition = false;
    doc?.state.allowedTransitions.forEach(transition => {
      if (TOGGLE_REQUIRED_TRANSITION_STATES.includes(transition)) {
        canTransition = true;
      }
    });
    if (doc?.documentModel?.documentSuppressed && doc?.documentDefinition.documentType === DocumentType.AUTO) {
      canTransition = false;
    }
    return canTransition;
  }

  private getActionType(doc: IDocumentDm): DocumentActionType {
    if (this.isActionManualUpload(doc)) {
      return DocumentActionType.UPLOAD_DOCUMENT;
    }
    if (this.isActionPreview(doc)) {
      return DocumentActionType.PREVIEW_DOCUMENT;
    }
    if (this.isActionReviewMissingInfo(doc)) {
      return DocumentActionType.REVIEW_MISSING_INFO;
    }
    if (this.isActionRequiresResponse(doc)) {
      return DocumentActionType.REQUIRES_RESPONSE;
    }
    return DocumentActionType.UNKNOWN;
  }

  private isActionManualUpload(doc) {
    return (
      doc.documentDefinition.documentType === DocumentType.MANUAL &&
      doc.state.currentState === DocumentState.AWAITING_DOCUMENT
    );
  }

  private isActionPreview(doc) {
    return (
      doc.state.currentState === DocumentState.AWAITING_APPROVAL ||
      doc.state.currentState === DocumentState.APPROVED ||
      doc.state.currentState === DocumentState.READY_TO_REVIEW ||
      doc.state.currentState === DocumentState.COMPLETED
    );
  }

  private isActionReviewMissingInfo(doc) {
    return (
      doc.documentDefinition.documentType === DocumentType.AUTO &&
      doc.state.currentState === DocumentState.AWAITING_DOCUMENT &&
      doc.documentModel.missingInformation.length > 0
    );
  }

  private isActionRequiresResponse(doc) {
    return doc.state.currentState === DocumentState.AWAITING_RESPONSE;
  }

  private getApiHeadersAndParams() {
    const headers = {
      'x-jt-project-id': this.config.projectId
    };
    const params = {
      projectId: this.config.projectId,
      documentPackDefinitionReference: this.config.documentPackDefinition
    };
    return { headers, params };
  }

  private initI18ns(): void {
    const keys = GetI18nKeys();
    keys.forEach(key => {
      let suffix;
      if (key.includes(DOCUMENT_STATE_KEY_PREFIX)) {
        const stateSuffix = key.split(`${DOCUMENT_STATE_KEY_PREFIX}.`).pop();
        suffix = stateSuffix.replace('.', '');
      } else {
        suffix = key.split('.').pop();
      }
      this.i18n[suffix] = this.i18nService.translate(key);
    });
  }

  private getCurrentDocumentById(docId) {
    return this.currentDocumentPack.documentPack.documents.find(doc => doc.id === docId);
  }

  private notifyRetryFetch() {
    const dataDm: IDocumentPackManagerDm = {
      ...this.defaultDocumentPackManager,
      hasDocumentPack: true,
      fetchingDocumentPack: false,
      isRefreshing: false,
      allowRetry: true
    };
    this.documentPackManager$.next(dataDm);
  }

  private handleStateTransitionError(transition: DocumentStateTransition, e) {
    switch (transition) {
      case DocumentStateTransition.UPLOADED:
        return this.handleErrors(ErrorType.uploadDocument, e);
      case DocumentStateTransition.APPROVED:
        return this.handleErrors(ErrorType.approveDocument, e);
      case DocumentStateTransition.REQUIRED:
      case DocumentStateTransition.NOT_REQUIRED:
        return this.handleErrors(ErrorType.requiredDocument, e);
      case DocumentStateTransition.WITHDRAW_APPROVAL:
        return this.handleErrors(ErrorType.withdrawApproveDocument, e);
      case DocumentStateTransition.REVIEWED:
        return this.handleErrors(ErrorType.autoGenDocReviewed, e);
      case DocumentStateTransition.REVISE:
        return this.handleErrors(ErrorType.reviseDocument, e);
    }
  }

  private handleErrors(type: ErrorType | unknown, e) {
    if (!environment.production) {
      console.error(e);
    }
    switch (type) {
      case ErrorType.fetchDocumentPackById:
        this.setError(ErrorType.fetchDocumentPackById);
        this.notifyRetryFetch();
        break;
      case ErrorType.addDocumentPack:
        this.setError(ErrorType.addDocumentPack);
        this.notifyAddDocumentProgress(false);
        this.spinnerService.hide(this.MAIN_ACTION_SPINNER).then();
        break;
      case ErrorType.removeDocumentPack:
        this.setError(ErrorType.removeDocumentPack);
        this.notifyRemoveDocumentProgress(false);
        this.spinnerService.hide(this.MAIN_ACTION_SPINNER).then();
        break;
      case ErrorType.uploadDocument:
        this.setError(ErrorType.uploadDocument);
        this.documentPackManager$.next({ ...this.currentDocumentPack, uploadInProgress: false });
        if (this.missingInfoSubscription) {
          this.modalService.dismissAll();
        }
        this.clearMessages();
        this.clearProgress();
        break;
      case ErrorType.regenerateDocument:
        this.setError(ErrorType.regenerateDocument);
        if (this.modalRef) {
          this.modalRef.dismiss('Regeneration error');
        }
        break;
      case ErrorType.approveDocument:
        this.setError(ErrorType.approveDocument);
        this.clearMessages();
        break;
      case ErrorType.withdrawApproveDocument:
        this.setError(ErrorType.withdrawApproveDocument);
        this.clearMessages();
        break;
      case ErrorType.requiredDocument:
        this.setError(ErrorType.requiredDocument);
        this.clearMessages();
        this.documentPackManager$.next({
          ...this.currentDocumentPack,
          stateChangeInProgress: false
        });
        break;
      case ErrorType.previewDocument:
        this.setError(ErrorType.previewDocument);
        this.notifyPreviewingDocument(false, false);
        if (this.previewModalSub) {
          this.previewModalSub.unsubscribe();
        }
        this.clearMessages();
        break;
      case ErrorType.reviewMissingInfo:
        this.setError(ErrorType.reviewMissingInfo);
        this.clearMessages();
        if (this.missingInfoModalSub) {
          this.missingInfoModalSub.unsubscribe();
        }
        break;
      case ErrorType.provideResponse:
        this.setError(ErrorType.provideResponse);
        this.clearMessages();
        if (this.responseModalSub) {
          this.responseModalSub.unsubscribe();
        }
        break;
      case ErrorType.reviseDocument:
        this.setError(ErrorType.reviseDocument);
        this.clearMessages();
        break;
      case ErrorType.autoGenDocReviewed:
        this.setError(ErrorType.autoGenDocReviewed);
        this.clearMessages();
        break;
      default:
        this.setError(ErrorType.unknown);
    }
  }

  private unsubscribeMissingInfo() {
    if (this.missingInfoSubscription) {
      this.missingInfoSubscription.unsubscribe();
      this.missingInfoSubscription = null;
    }
  }

  public unsubscribe(): void {
    this.subscriptionList.forEach(sub => sub.unsubscribe());
    this.subscriptionList = [];
    if (this.mainSubscription) {
      this.mainSubscription.unsubscribe();
      this.mainSubscription = null;
    }
  }
}
