<nav class="sidenav">
  <div
    *ngIf="isUserSignedIn()"
    class="sidenav__menu-item pathway-menu-item"
    container="body"
    placement="right"
    tooltipClass="jt-tooltip--secondary-darker"
    [ngbTooltip]="menuToggle"
    (click)="onNavToggle()"
  >
    <ng-template #menuToggle
      ><div class="pathway-menu-item__tooltip">
        {{ (isClosed ? 'navbar.show' : 'navbar.hide') | transloco }}
      </div></ng-template
    >
    <button
      class="sidenav__menu-toggle pathway-menu-icon"
      [class.sidenav__menu-btn--open]="!isClosed"
      type="button"
      [attr.data-qa]="'sideNavToggle'"
    >
      <span class="sidenav__menu-icon"></span>
    </button>
  </div>

  <ng-container *ngIf="isPreview">
    <div
      class="pre-branch-switch pathway-menu-item"
      container="body"
      tooltipClass="jt-tooltip--secondary-darker"
      [ngbTooltip]="branchTooltip"
    >
      <jui-icon
        class="pathway-menu-icon"
        [color]="activeTheme === 'dark' ? 'info' : 'info'"
        isButton="false"
        name="account_tree"
      ></jui-icon>
      <span class="pathway-menu-label" *ngIf="!isClosed || isSmallScreen">
        <span class="nonProdEnvironment" [title]="branchName">&nbsp;{{ branchShortName }}</span>
        <span>
          <jui-button
            size="xs"
            color="info"
            [display]="activeTheme === 'dark' ? 'ghost' : 'contained'"
            (click)="onBranchReset.emit()"
          >
            Reset
          </jui-button>
        </span>
      </span>
    </div>

    <ng-template #branchTooltip>
      <div class="pathway-menu-item__tooltip pathway-menu-item__tooltip--flex">
        <jui-icon
          class="pathway-menu-item__tooltip-icon"
          [color]="activeTheme === 'dark' ? 'info' : 'info'"
          isButton="false"
          name="account_tree"
          size="xs"
        ></jui-icon>
        <div>
          Pathway Branch: <span class="pathway-menu-item__tooltip-emp">{{ branchName }}</span
          >. This is the branch of frontend code that you are testing on.
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isPreview || isLocal">
    <div
      class="pre-branch-switch pathway-menu-item"
      container="body"
      tooltipClass="jt-tooltip--secondary-darker"
      [ngbTooltip]="environmentToolTip"
    >
      <span>
        <jui-icon
          class="pathway-menu-icon"
          [color]="activeTheme === 'dark' ? 'info' : 'info'"
          isButton="false"
          name="api"
        ></jui-icon>
      </span>

      <span class="pathway-menu-control" *ngIf="!isClosed || isSmallScreen">
        <span class="nonProdEnvironment pathway-menu-control__label" [title]="apiEnvironmentName">&nbsp;API:</span>
        <span class="pathway-menu-control__select">
          <ng-select
            class="pathway-menu-control__ctrl"
            [items]="apiEnvironmentList"
            [clearable]="false"
            [(ngModel)]="selectedApiEnvironment"
            (change)="onChangeEnvironment()"
          ></ng-select>
        </span>
      </span>
    </div>

    <ng-template #environmentToolTip>
      <div class="pathway-menu-item__tooltip pathway-menu-item__tooltip--flex">
        <jui-icon
          class="pathway-menu-item__tooltip-icon"
          [color]="activeTheme === 'dark' ? 'info' : 'info'"
          isButton="false"
          name="api"
          size="xs"
        >
        </jui-icon>
        <div>
          The currently selected API/data environment is
          <span class="pathway-menu-item__tooltip-emp">{{ apiEnvironmentName }}</span
          >. Changing this option will change the API calls to the backend, and retrieve data from a completely
          different environment. Data is not shared between environments. Preview currently uses data from the local
          environment.
        </div>
      </div>
    </ng-template>
  </ng-container>

  <div *ngIf="menuOpen && isUserSignedIn()">
    <ng-container *ngIf="hasProjectsAccess">
      <ng-container *ngIf="prefetching$ | async">
        <app-menu-item
          [label]="projectsLoadingMenuItem.label"
          [icon]="projectsLoadingMenuItem.icon"
          [qaHook]="projectsLoadingMenuItem.qaHook"
          [hasAccess]="projectsLoadingMenuItem.hasAccess"
          [isDisabled]="true"
          [isClosed]="isClosed"
          [isSmallScreen]="isSmallScreen"
        ></app-menu-item>
      </ng-container>

      <ng-container *ngIf="(prefetching$ | async) === false">
        <app-menu-item
          [label]="projectsMenuItem.label"
          [icon]="projectsMenuItem.icon"
          [qaHook]="projectsMenuItem.qaHook"
          [isActive]="isRouteActive(projectsMenuItem.routeLink)"
          [hasAccess]="projectsMenuItem.hasAccess"
          [isClosed]="isClosed"
          [isSmallScreen]="isSmallScreen"
          (click)="openMenuItem($event, projectsMenuItem)"
        ></app-menu-item>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let item of menuList">
      <!-- todo refactor to template? -->
      <ng-container *ngIf="item.useFeatureToggle">
        <ng-container *featureFlag="item.useFeatureToggle">
          <app-menu-item
            [label]="item.label"
            [icon]="item.icon"
            [qaHook]="item.qaHook"
            [externalLink]="item.externalLink"
            [isActive]="isRouteActive(item.routeLink)"
            [hasAccess]="item.hasAccess"
            [isBetaFeature]="item.isBetaFeature"
            [isDisabled]="item.isDisabled"
            [isClosed]="isClosed"
            [isSmallScreen]="isSmallScreen"
            (menuItemClick)="openMenuItem($event, item)"
            (externalLinkClick)="openExternalLink($event)"
          ></app-menu-item>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!item.useFeatureToggle">
        <app-menu-item
          [label]="item.label"
          [icon]="item.icon"
          [qaHook]="item.qaHook"
          [externalLink]="item.externalLink"
          [isActive]="isRouteActive(item.routeLink)"
          [hasAccess]="item.hasAccess"
          [isBetaFeature]="item.isBetaFeature"
          [isDisabled]="item.isDisabled"
          [isClosed]="isClosed"
          [isSmallScreen]="isSmallScreen"
          (menuItemClick)="openMenuItem($event, item)"
          (externalLinkClick)="openExternalLink($event)"
        ></app-menu-item>
      </ng-container>
    </ng-container>
  </div>

  <div class="jt-signed" *ngIf="!isUserSignedIn() || !isClosed || isSmallScreen">
    <div class="jt-signed__body">
      <div class="jt-signed__logo">
        <div class="powered-by">{{ 'common.poweredBy' | transloco }}</div>
        <img [src]="logoSrc" alt="" />
      </div>
      <div class="jt-signed__status">
        <jui-button
          size="xs"
          [color]="activeTheme === 'dark' ? 'info' : 'low'"
          display="ghost"
          (click)="openExternalLink('https://jumptechstatus.statuspage.io')"
          [attr.data-qa]="'systemStatusLink'"
          >{{ 'common.systemStatus' | transloco }}</jui-button
        >
      </div>
    </div>
  </div>
</nav>
