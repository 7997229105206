import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Project } from '../../core/domain/project';
import { LayoutComponentPresenter } from './layout-component.presenter';
import { BehaviorSubject } from 'rxjs';
import { LayoutComponentVm } from './layout-component.vm';
import { DisplayTabLayout, ExpandedCardLayout } from '../project-detail.component';
import { StandardQuoteSelectComponent } from '../../feature-modules/configuration/standard-data/quotes/components/selector/standard-quote-select.component';
import { ListLayoutComponent } from '../../core/cardLayouts/list-layout.component';
import { NgIf, AsyncPipe, NgForOf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [AsyncPipe, NgIf, NgForOf, StandardQuoteSelectComponent, ListLayoutComponent, CoreComponentsAngularModule],
  providers: [LayoutComponentPresenter]
})
export class LayoutComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() readonly: boolean;
  @Input() tabLayout: DisplayTabLayout;
  @Output() tabLayoutSaved = new EventEmitter<unknown>();
  @Output() standardQuoteChanged = new EventEmitter<{ layout: ExpandedCardLayout; data: any }>();

  public vm$: BehaviorSubject<LayoutComponentVm> = new BehaviorSubject<LayoutComponentVm>(null);

  constructor(private presenter: LayoutComponentPresenter) {}

  ngOnInit() {
    this.presenter.load({ project: this.project, readonly: this.readonly, tabLayout: this.tabLayout }, this.vm$);
  }

  ngOnDestroy() {
    this.presenter.unload(this.vm$);
  }

  // TODO: Move to the vm
  floor(number: number) {
    return Math.floor(number);
  }

  onSave($event) {
    this.tabLayoutSaved.emit($event);
  }

  onStandardQuoteChanged(layout: ExpandedCardLayout, data: any) {
    this.standardQuoteChanged.emit({ layout, data });
  }
}
