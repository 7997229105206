import { Address } from './address';
import { DnoNotificationStatus } from './dno-notification-status';
import { ResourceSummary } from './resource-summary';

export class DnoDemandSummary implements ResourceSummary {
  type: string;
  summary: {
    dno: string;
    dnoId?: string;
    proceed: string;
    dnoApplicationRequiredInAdvance: string;
    considerLoadMonitoring: string;
    suggestLoadManageChargeStation: string;
    maximumDemand: string;
    cutOutRating: string;
    newInstallationDemand: string;
    newInstallationDetails: string;
    earthingArrangement: string;
    phase?: string;
    proposedEarthingArrangements?: string;
    additionalNotes?: string;
  };
}

export interface DnoNotification {
  tenant: string;
  id: string;
  dno: string;
  dnoId: string;
  dnoApplicationEmail: string;
  dnoNotificationEmail: string;
  mpan: string;
  email: string;
  projectTenant: string;
  projectId: string;
  projectType: string;
  address: Address;
  postCode: string;
  summary: DnoDemandSummary;
  installChargePoint: string;
  installer: string;
  installerEmail: string;
  installerPhone: string;
  installerWebsite: string;
  installerPrivacyPolicy: string;
  installerTermsAndConditions: string;
  status?: DnoNotificationStatus;
  assessmentNotes?: string;
  images?: { name: string; source: string }[];
  enaNotificationUrl?: string;
  enaApplicationUrl?: string;
  created_on: string;
  created_by: string;
  created_by_name: string;
  created_by_team: string;
  created_by_roles: string[];
}
