import { IntegrationInterface } from './integration.interface';

export interface LogEmailsAsAttachmentsConfig {
  template: {
    bucket: string; // e.g. 'jumptech-templates-{env}',
    key: string; // e.g. 'default/email2PDF.html',
    type: string; // e.g. 's3'
  };
  output: {
    ext: string; // e.g. 'html' or 'pdf,
    contentType: string; // e.g. 'text/html' or 'application/pdf,
    keyTemplate: string; // e.g. "{{tenant}}/{{projectId}}/{{subject}}_{{sent | date: 'YYYYMMDD_HHmmss'}}.{{ext}}",
    bucket: string; // e.g. 'pathway-attachments-{env}'
  };
}

export interface TenantConfiguration {
  tenant: string;
  configuration: {
    installer: string;
    installerContact?: string;
    installerSignature?: string;
    installerEmail: string;
    notificationsEmail?: string;
    installerPhone: string;
    installerWebsite: string;
    installerPrivacyPolicy: string;
    installerTermsAndConditions: string;
    installerEvhs?: string;
    installerAccreditationBody?: string;
    smsSenderId: string;
    registeredCompanyName?: string;
    address?: {
      line1: string;
      line2: string;
      town: string;
      county: string;
      postCode: string;
    };
    webHookTransform?: string;
    delegates?: string[];
    integrations?: IntegrationInterface[];
    logEmailsAsAttachments?: LogEmailsAsAttachmentsConfig;
    olevRemainingCostContribution?: string;
    // Extensions from SubTenant
    smsMessage?: string;
  };
  forms?: {
    [name: string]: any;
  };
}
