import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import {
  AssignedTradesPeopleVm,
  DateTimeChangeEvent,
  RemoveTradespersonSlotEvent,
  TradesPersonVm
} from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JsonPipe, NgForOf, NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { ScheduleEventSelectedJobDetailsSlotTimeFormComponent } from '../schedule-event-selected-job-details-slot-time-form/schedule-event-selected-job-details-slot-time-form.component';
import { ScheduleEventSelectedJobDetailsTradespersonComponent } from '../schedule-event-selected-job-details-tradesperson/schedule-event-selected-job-details-tradesperson.component';
import { ScheduleEventSelectedJobDetailsAddTradespersonFormComponent } from '../schedule-event-selected-job-details-add-tradesperson-form/schedule-event-selected-job-details-add-tradesperson-form.component';

@Component({
  selector: 'schedule-event-selected-job-details-assigned-engineers',
  templateUrl: 'schedule-event-selected-job-details-assigned-engineers.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  standalone: true,
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgForOf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    ScheduleEventSelectedJobDetailsSlotTimeFormComponent,
    ScheduleEventSelectedJobDetailsTradespersonComponent,
    ScheduleEventSelectedJobDetailsAddTradespersonFormComponent,
    JsonPipe
  ]
})
export class ScheduleEventSelectedJobDetailsAssignedEngineersComponent {
  @Input() vm: AssignedTradesPeopleVm;
  @Output() setLeadTradesperson: EventEmitter<TradesPersonVm> = new EventEmitter<TradesPersonVm>();
  @Output() removeTradesperson: EventEmitter<TradesPersonVm> = new EventEmitter<TradesPersonVm>();
  @Output() removeTradespersonSlot: EventEmitter<RemoveTradespersonSlotEvent> =
    new EventEmitter<RemoveTradespersonSlotEvent>();
  @Output() addTradesperson: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
  @Output() addSpecificSlot: EventEmitter<TradesPersonVm> = new EventEmitter<TradesPersonVm>();
  @Output() cancelAddTradesperson: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmAddTradesperson: EventEmitter<void> = new EventEmitter<void>();
  @Output() slotDateTimeChange: EventEmitter<DateTimeChangeEvent> = new EventEmitter<DateTimeChangeEvent>();
}
