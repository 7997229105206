<div class="schedule-event-selected-job-details__actions">
  <jui-icon
    *ngIf="!vm.hideCloseIcon"
    class="schedule-event-selected-job-details__close"
    name="close"
    color="current"
    size="xs"
    (click)="close.emit()"
  ></jui-icon>
  <jui-button color="secondary" display="ghost" size="sm" (click)="goToProject.emit()">{{vm.i18nGoToProjectBtn}}</jui-button>
  <jui-button
    size="sm"
    [loading]="vm.scheduleInProgress"
    [disabled]="vm.isScheduleButtonDisabled"
    (click)="scheduleNow.emit()"
    >{{ vm.i18nScheduleNowBtn }}</jui-button
  >
  <jui-icon name="chevron_right" color="current" size="sm" (click)="hide.emit()"></jui-icon>
</div>
@if (vm.showCheckCollisionsButton) {
<div class="schedule-event-selected-job-details__actions--second">
  <jui-button size="sm" color="warning" [loading]="vm.collisionCheckInProgress" (click)="checkCollisions.emit()">
    {{ vm.i18nCheckCollisionsBtn }}
  </jui-button>
</div>
}
