import { Address } from './address';

export interface SubTenant {
  id: string;
  type: string;
  tenant: string;
  configuration: SubTenantConfiguration<SubTenantConfigurationType>;
  enabled: boolean;
}

export enum SubTenantConfigurationType {
  EVHS = 'EVHS'
}

export interface SubTenantConfiguration<T> {
  name: string;
  type: T;
  tenantConfiguration: SubTenantTenantConfiguration;
  pathwayConfiguration: SubTenantPathwayConfiguration;
  relayConfiguration: SubTenantRelayConfiguration;
}

export interface SubTenantTenantConfiguration {
  address: Address;
  installer?: string;
  registeredCompanyName?: string;
  installerContact?: string;
  installerEmail?: string;
  installerEvhs?: string;
  installerAccreditationBody?: string;
  installerPhone?: string;
  installerWebsite?: string;
  installerPrivacyPolicy?: string;
  installerTermsAndConditions?: string;
  smsSenderId?: string;
  smsMessage?: string;
  outgoingCustomerEmail?: string;
  olevRemainingCostContribution?: string;
}

export interface SubTenantPathwayConfiguration {
  logo?: string;
  styles?: string;
  font?: string;
}

export interface SubTenantRelayConfiguration {
  smsMessage?: string;
  customerLogo?: string;
  customer?: string;
}
