import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Card, CardAction, CardActionDataItem } from '@jump-tech-frontend/cards';
import { IfCheck, IfDefinition, QuestionBase } from '@jump-tech-frontend/domain';
import * as _ from 'lodash';
import * as XRegExp from 'xregexp';
import { ConfirmModalConfig, RequiredFieldsConfig } from './domain/confirm-modal.config';
import { makeBase64EncodedData } from './utils/base64.helper';
import { makeFileResource } from './utils/file-resource';
import { checkIfExpression, RenderHelper } from '@jump-tech-frontend/angular-common';

export const convertBase64IntoPaths = (form: any): any => {
  const clone = _.cloneDeep(form);
  for (const sectionKey of Object.keys(clone)) {
    const source = clone[sectionKey];
    for (const [key, value] of Object.entries(source)) {
      if (value instanceof Array) {
        const newValue = value.map((item: any, index: number): any => {
          const fileResource = makeFileResource(item);
          if (fileResource === null) {
            // This is not a file resource
            return item;
          }

          const data = makeBase64EncodedData(fileResource.getValue());
          if (data === null) {
            // This is not a base64 encoded file
            return item;
          }

          return `unsigned:${key}${index}_${Date.now()}.${data.extension}`;
        });
        clone[sectionKey][key] = newValue;
      }
    }
  }
  return clone;
};

export const expandCardValues = (cards: Card<unknown>[], context: Record<string, unknown>) => {
  const renderHelper = new RenderHelper();

  for (const card of cards) {
    for (const field of Object.keys(card)) {
      card[field] = renderHelper.renderTemplate(card[field], context);
    }
  }
};

export const expandQuestionValues = (cards: Card<unknown>[], context: Record<string, unknown>) => {
  const renderHelper = new RenderHelper();

  for (const card of cards) {
    for (const group of card.infoGroups || []) {
      group.groupLabel = renderHelper.renderTemplate(group.groupLabel, context);
    }

    for (const question of card.questions || []) {
      question.hint = renderHelper.renderTemplate(question.hint, context);
      question.label = renderHelper.renderTemplate(question.label, context);

      if (question.content) {
        question.value = renderHelper.renderTemplate(question.content, context);
      }
    }
  }
};

export const toFormGroup = (cards: Card<any>[], existingProject?: any, data?: any) => {
  const sectionGroup: any = {};

  cards.forEach(card => {
    const questionGroup: any = {};
    if (card.questions) {
      card.questions.forEach((question: QuestionBase<any>) => {
        questionGroup[question.key] = createFormControl(question, data);
        if (
          existingProject &&
          existingProject[card.key] &&
          card.key.toLowerCase() !== 'address' &&
          'undefined' === typeof existingProject[card.key][question.key]
        ) {
          questionGroup[question.key].patchValue(_.cloneDeep(question.value || null));
        }
      });
    }
    if (card.actions) {
      card.actions.forEach((action: CardAction) => {
        if (action.data) {
          for (const item of action.data) {
            questionGroup[item.key] = createFormControl({ key: item.key } as QuestionBase<unknown>, data);
          }
        }
      });
    }
    sectionGroup[card.key] = new UntypedFormGroup(questionGroup);
  });

  const group = new UntypedFormGroup(sectionGroup);

  if (existingProject) {
    Object.keys(existingProject).forEach(formKey => {
      const formGroupToUpdate = group.get(formKey);
      const value = existingProject[formKey];
      if (!value) {
        return;
      }
      const formGroupKeys = Object.keys(value);
      formGroupKeys.forEach(formGroupKey => {
        if (formGroupToUpdate && formGroupToUpdate.get(formGroupKey)) {
          const existingProjectValue = existingProject[formKey][formGroupKey];
          if (existingProjectValue != null) {
            formGroupToUpdate.get(formGroupKey)?.patchValue(existingProject[formKey][formGroupKey]);
          }
        }
      });
    });
  }

  group.updateValueAndValidity();

  return group;
};

export const createFormControl = (question: QuestionBase<any>, data: any) => {
  const validators = [];

  if (question.required) {
    validators.push(Validators.required);
  }

  if (question.pattern) {
    validators.push(Validators.pattern(XRegExp(question.pattern)));
  }

  const fieldValue = _.cloneDeep(getFieldValue(question.key, data, question.value));
  return new UntypedFormControl(
    {
      value: fieldValue,
      disabled: question.disabled === true
    },
    validators
  );
};

export const setFormData = (card: Card<unknown>, group: UntypedFormGroup, data: CardActionDataItem[]) => {
  const cardKey = card?.key;
  const cardGroup = group.get(cardKey);
  if (!cardGroup) {
    return;
  }
  data.forEach(item => {
    const control = cardGroup.get(item.key);
    if (!control) {
      return;
    }
    control.patchValue(item.value);
  });
};

export const getFieldValue = (key: string, data: any, questionValue: string) => {
  const fieldValue = _.get(data, key);
  return fieldValue === false ? fieldValue : fieldValue || questionValue || null;
};

export const cleanForm = (cards: any[], form: any, cardNumber?: number) => {
  // Remove any card that does not have a section (it does not contribute to progress).
  let filteredCards = [];

  if (cardNumber !== undefined) {
    for (let i = 0; i <= cardNumber; i++) {
      filteredCards.push(cards[i]);
    }
  } else {
    filteredCards = cards;
  }

  filteredCards
    .filter(card => card.section === undefined)
    .forEach(card => {
      delete form[card.key];
    });

  // Removed any form.value key that is not present within the filtered cards.
  for (const formKey of Object.keys(form)) {
    const filteredCard = filteredCards.find(fc => fc.key === formKey);
    if (!filteredCard) {
      delete form[formKey];
    }
  }

  // Filter out null values
  removeNulls(form);
};

export const removeNulls = (obj: unknown) => {
  Object.keys(obj).forEach(k => {
    const value = obj[k];
    if (!value) {
      delete obj[k];
      return;
    }
    if (Array.isArray(obj[k])) {
      for (let i = 0; i < obj[k].length; i++) {
        if (typeof obj[k] === 'object') {
          removeNulls(obj[k]);
        }
      }
      obj[k] = obj[k].filter(x => x !== null);
      return;
    }
    if (typeof obj[k] === 'object') {
      removeNulls(obj[k]);
    }
  });
  return obj;
};

export const hasRequiredData = (config: ConfirmModalConfig, parameters = {}) => {
  const requiresConfig: RequiredFieldsConfig | undefined = config?.requires;
  return requiresConfig && requiresConfig.requiredData && match(requiresConfig.requiredData, parameters);
};

export const match = (requiredData: IfDefinition[], parameters = {}) => {
  let matched = false;
  requiredData.forEach(requiredDataItem => {
    let result = false;

    if (!checkIfExpression(requiredDataItem as IfCheck, parameters)) {
      result = true;
    }

    if (Array.isArray(requiredDataItem['requiredData']) && requiredDataItem['requiredData']?.length > 0) {
      result = result && match(requiredDataItem['requiredData'], parameters);
    }

    matched = result || matched;
  });

  return matched;
};

export interface PackedSignedURLs {
  putURL: string;
  getURL: string;
}

/**
 * Explode a packed signed-url string, which is formed by:
 * 1- a flag identifying whether it's a new set of signed URLs:
 * 2- the signed URL for the PUT operation
 * 3- the signed URL for the GET operation
 * Values are colon-separated.
 * @param value
 */
export const explodeSignedURLs = (value: any): PackedSignedURLs | null => {
  const NEW_SIGNED_URL_PREFIX = 'signed-new';
  const SIGNED_URLS_SEPARATOR = ':';

  // Value can be null, so ...
  if (value === null) {
    return null;
  }

  if (typeof value !== 'string') {
    return null;
  }

  const parts = value.split(SIGNED_URLS_SEPARATOR);
  if (parts.length !== 3 || parts[0] !== NEW_SIGNED_URL_PREFIX) {
    return null;
  }

  // All good; we can extract the URLs.
  return {
    putURL: atob(parts[1]).toString(),
    getURL: atob(parts[2]).toString()
  } as PackedSignedURLs;
};
