import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { CalendarEvent } from '../../../../core/domain/event';
import { Job } from '../../../../core/domain/job';
import { FeatureFlagService } from '../../../../core/feature-flag/feature-flag.service';
import { FullCalendarEvent, TooltipInfo } from '../../utils/schedule-types';
import { getStyleForStatus } from '../../utils/schedule.helper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'schedule-event-tooltip-v3',
  templateUrl: 'schedule-event-tooltip.component.html',
  styleUrls: ['../../schedule.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, NgIf, NgFor]
})
export class ScheduleEventTooltipComponent {
  showTooltip = '';
  tooltipStyle: Record<string, unknown>;
  info?: TooltipInfo;
  rollingMonthViewEnabled = false;

  @ViewChild('tooltip') tooltip: HTMLDivElement;

  @Input() currentEvent: FullCalendarEvent;
  @Input() engineer: string;
  @Input() eventTypeData: any;
  @Input() translations: any;

  @Input() set trigger(event: MouseEvent) {
    if (event) {
      this.hydrateInfo();
      let target = event.target as HTMLElement;
      while (!Array.from(target.classList).includes('fc-event')) {
        target = target.parentElement;
      }
      const { offsetLeft, offsetTop, tooltipBottom } = ScheduleEventTooltipComponent.getOffset(target);
      this.tooltipStyle = {
        left: `${offsetLeft}px`,
        top: `${offsetTop}px`,
        display: 'block',
        width: '200px'
      };
      this.showTooltip = `show ${tooltipBottom ? 'bs-tooltip-bottom' : 'bs-tooltip-top'}`;
    } else {
      this.info = null;
      this.showTooltip = '';
      this.tooltipStyle = { left: '0px', top: '0px', display: 'none' };
    }
  }

  constructor(private featureFlagService: FeatureFlagService) {}

  async OnInit() {
    this.rollingMonthViewEnabled = await this.featureFlagService.isFeatureEnabled('rolling-month-schedule-view');
  }

  private static getOffset(target: HTMLElement) {
    // If there is less than 150px from the target event element to the bottom of the screen then place tooltip above
    const tooltipBottom = window.innerHeight - target.getBoundingClientRect().top >= 150;
    let targetCardParent = target.offsetParent as HTMLElement;
    while (targetCardParent.className !== 'card') {
      targetCardParent = targetCardParent.offsetParent as HTMLElement;
    }
    const cardParentBoundingRect = targetCardParent.getBoundingClientRect();
    let offsetTop = target.getBoundingClientRect().top - cardParentBoundingRect.top;
    if (tooltipBottom) {
      offsetTop += target.getBoundingClientRect().height;
    } else {
      // Remove the 'rough' height of a tooltip from the offsetTop if displaying above the target
      offsetTop -= 175;
    }
    const offsetLeft =
      target.getBoundingClientRect().left - cardParentBoundingRect.left - (100 - target.offsetWidth / 2);
    return { offsetTop, offsetLeft, tooltipBottom };
  }

  private hydrateJob(job) {
    const bodyItems = [];

    const style = getStyleForStatus(job.status);
    if (job.firstName) {
      bodyItems.push(`${this.translations.name} ${job.firstName} ${job.lastName}`);
    }
    if (job.phoneNumber) {
      bodyItems.push(`${this.translations.phone} ${job.phoneNumber}`);
    }
    if (job.address) {
      bodyItems.push(`${job.address.line1} ${job.address.postCode}`);
    }
    if (job.riskAssessor) {
      bodyItems.push(`${this.translations.riskAssessor} ${job.riskAssessor}`);
    }
    if (job.siteSupervisor) {
      bodyItems.push(`${this.translations.siteSupervisor} ${job.siteSupervisor}`);
    }
    if (job.installChargePoint) {
      bodyItems.push(`${this.translations.chargePoint} ${job.installChargePoint}`);
    }
    if (job.natureOfWorks) {
      bodyItems.push(job.natureOfWorks);
    }
    this.info = {
      title: this.currentEvent.title,
      status: style.label,
      bodyItems,
      assignedTo: job.assignedToDisplayName,
      colour: '#000'
    };
  }

  private getDisplayDate(dateString: string): string {
    return JumptechDate.from(dateString).toDateFormat();
  }

  private getDisplayTime(dateString: string): string {
    return JumptechDate.from(dateString).toTimeFormat();
  }

  private hydrateAbsence(absence) {
    const bodyItems = [];
    const startDate = this.getDisplayDate(absence.startIso);
    const endDate = this.getDisplayDate(absence.endIso);
    const startTime = this.getDisplayTime(absence.startIso);
    const endTime = this.getDisplayTime(absence.endIso);

    const title = this.currentEvent.title !== 'null' && this.currentEvent.title ? this.currentEvent.title : null;

    if (absence.description) {
      bodyItems.push(absence.description);
    }

    if (startDate && endDate && !absence.allDayEvent) {
      bodyItems.push(`${this.translations.start} ${startDate} : ${startTime}`);
      bodyItems.push(`${this.translations.end} ${endDate} : ${endTime}`);
    }

    if (startDate && endDate && absence.allDayEvent) {
      if (startDate === endDate) {
        bodyItems.push(`${startDate} (<span class="u-text-upper">${this.translations.allDay}</span>)`);
      } else {
        bodyItems.push(`<span class="u-text-upper">${this.translations.allDay}</span> <br> ${startDate} - ${endDate}`);
      }
    }

    this.info = {
      title: title,
      showTitle: this.eventTypeData.title,
      status: this.eventTypeData.name,
      bodyItems,
      assignedTo: this.engineer,
      icon: this.eventTypeData.icon,
      isAbsence: true,
      colour: this.eventTypeData.colour,
      textColour: this.eventTypeData.textColour
    };
  }

  private hydrateInfo() {
    const { extendedProps } = this.currentEvent;
    if (extendedProps.eventType === 'Job') {
      const job = extendedProps as Job;
      return this.hydrateJob(job);
    } else {
      const absence = extendedProps as CalendarEvent;
      return this.hydrateAbsence(absence);
    }
  }
}
