import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageManipulationService } from './image-manipulation.service';
import { OcrService } from './ocr.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionFormErrorComponent } from '@jump-tech-frontend/question-components';
import { PostcodeQuestionComponent } from './question-components/postcode-question.component';
import { TextboxQuestionComponent } from './question-components/textbox-question.component';
import { SignaturePadComponent } from './signature-pad.component';
import { SignatureQuestionComponent } from './question-components/signature-question.component';
import { DropdownQuestionComponent } from './question-components/dropdown-question.component';
import { ToggleQuestionComponent } from './question-components/toggle-question.component';
import { GeneralQuestionComponent } from './question-components/general-question.component';
import { ImageUploadQuestionComponent } from './question-components/image-upload-question.component';
import { ButtonGroupQuestionComponent } from './question-components/button-group-question.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImageAssetFactory } from '../domain/card';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  QuestionHintComponent,
  QuestionHintPopoverComponent,
  QuestionLabelContentComponent
} from '@jump-tech-frontend/question-components';
import { OcrImageUploadQuestionComponent } from './question-components/ocr-image-upload-question.component';
import { ScrollService } from './scroll.service';
import { AddressListDropdownQuestionComponent } from './question-components/address-list-dropdown-question.component';
import { GoogleMapsMarkerQuestionComponent } from './question-components/google-maps-marker-question.component';
import { MultiImageUploadQuestionComponent } from './question-components/multi-image-upload-question/multi-image-upload-question.component';
import { TextareaQuestionComponent } from './question-components/textarea-question.component';
import { MultiDropdownWithValueQuestionComponent } from './question-components/multi-dropdown-with-value-question.component';
import { GoogleAddressToAddressTransformer } from '../transformers/google-address-to-address.transformer';
import { ImageAssetComponent } from './image-asset.component';
import { DocumentViewerQuestionComponent } from './question-components/document-viewer-question.component';
import { DocumentViewerModalComponent } from './modals/document-viewer-modal.component';
import { FileUploadQuestionComponent } from './question-components/file-upload-question/file-upload-question.component';
import { PostCodeLookupModule } from '@jump-tech-frontend/address-lookup';
import { PostcodeQuestionV2Component } from './question-components/postcode-question-v2.component';
import { GoogleMapsMultiMarkerQuestionComponent } from './question-components/google-maps-multi-marker-question.component';
import { FileUploadQuestionInputComponent } from './question-components/file-upload-question/file-upload-question-input.component';
import { ImageOrFileUploadQuestionComponent } from './question-components/image-or-file-upload-question.component';
import { MultiImageUploadQuestionInputComponent } from './question-components/multi-image-upload-question/multi-image-upload-question-input.component';
import { CheckboxButtonGroupQuestionComponent } from './question-components/checkbox-button-group-question.component';
import { MimeTypeService } from './mime-type.service';
import { DateQuestionComponent } from './question-components/date-question.component';
import { InlineSpinnerComponent } from './inline-spinner-component/inline-spinner.component';
import { HtmlQuestionComponent } from './question-components/html-question.component';
import { HttpImageComponent } from './http-image.component';
import { GooglePlacesAutocompleteComponent } from './question-components/google-places-autocomplete.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete-esb';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormlyModule } from '@ngx-formly/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    PostCodeLookupModule,
    GooglePlaceModule,
    FormlyBootstrapModule,
    FormlyModule,
    CoreComponentsAngularModule,
    GoogleMapsModule,
    GeneralQuestionComponent,
    ImageUploadQuestionComponent,
    MultiImageUploadQuestionComponent,
    OcrImageUploadQuestionComponent,
    PostcodeQuestionComponent,
    PostcodeQuestionV2Component,
    DropdownQuestionComponent,
    FileUploadQuestionComponent,
    AddressListDropdownQuestionComponent,
    ImageAssetComponent,
    SignatureQuestionComponent,
    TextboxQuestionComponent,
    TextareaQuestionComponent,
    ToggleQuestionComponent,
    QuestionFormErrorComponent,
    SignaturePadComponent,
    ButtonGroupQuestionComponent,
    CheckboxButtonGroupQuestionComponent,
    QuestionHintComponent,
    QuestionHintPopoverComponent,
    QuestionLabelContentComponent,
    GoogleMapsMarkerQuestionComponent,
    GoogleMapsMultiMarkerQuestionComponent,
    MultiDropdownWithValueQuestionComponent,
    DocumentViewerQuestionComponent,
    DocumentViewerModalComponent,
    FileUploadQuestionInputComponent,
    MultiImageUploadQuestionInputComponent,
    ImageOrFileUploadQuestionComponent,
    DateQuestionComponent,
    InlineSpinnerComponent,
    HtmlQuestionComponent,
    HttpImageComponent,
    GooglePlacesAutocompleteComponent
  ],
  providers: [
    ImageManipulationService,
    OcrService,
    ScrollService,
    GoogleAddressToAddressTransformer,
    ImageAssetFactory,
    MimeTypeService
  ],
  exports: [
    GeneralQuestionComponent,
    ImageUploadQuestionComponent,
    MultiImageUploadQuestionComponent,
    OcrImageUploadQuestionComponent,
    PostcodeQuestionComponent,
    PostcodeQuestionV2Component,
    DropdownQuestionComponent,
    FileUploadQuestionComponent,
    AddressListDropdownQuestionComponent,
    ImageAssetComponent,
    SignatureQuestionComponent,
    TextboxQuestionComponent,
    TextareaQuestionComponent,
    ToggleQuestionComponent,
    QuestionFormErrorComponent,
    SignaturePadComponent,
    ButtonGroupQuestionComponent,
    CheckboxButtonGroupQuestionComponent,
    QuestionHintComponent,
    QuestionHintPopoverComponent,
    QuestionLabelContentComponent,
    GoogleMapsMarkerQuestionComponent,
    GoogleMapsMultiMarkerQuestionComponent,
    MultiDropdownWithValueQuestionComponent,
    DocumentViewerQuestionComponent,
    FileUploadQuestionInputComponent,
    MultiImageUploadQuestionInputComponent,
    ImageOrFileUploadQuestionComponent,
    DateQuestionComponent,
    InlineSpinnerComponent,
    HtmlQuestionComponent,
    HttpImageComponent,
    GooglePlacesAutocompleteComponent
  ]
})
export class CoreModule {}
