import { DestroyRef, inject, Injectable } from '@angular/core';
import { UserService } from './user.service';

import { ActivatedRouteSnapshot } from '@angular/router';
import {
  IS_FREE_LEADS_DOMAIN,
  MAIN_PATH,
  PROJECTS_PATH,
  SCHEDULE_PATH,
  SCHEDULE_V2_PATH, SCHEDULE_V3_PATH,
  TASKS_PATH
} from '../../app.routes';
import { AuthenticationService } from './authentication.service';
import { User } from '../../core/domain/user';
import { AuthStateEvent } from '../utils/auth-types';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type RouteInfo = Pick<ActivatedRouteSnapshot, 'data' | 'routeConfig'>;

@Injectable({
  providedIn: 'root'
})
export class RouteAccessService {
  private user: User;
  private authState: AuthStateEvent;
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private authenticationService: AuthenticationService, private userService: UserService) {
    this.authenticationService.authStateObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(state => {
      this.authState = state;
    });

    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
      }
    });
  }

  isAuthenticatedForRoute(route: RouteInfo): Promise<boolean> {
    const self = this;
    const path = route.routeConfig.path;
    // Look in the route definition to see if allowAdmin is true
    const allowAdmin = route.data && route.data.allowAdmin;

    if (this.authState === AuthStateEvent.SignOut) {
      return Promise.resolve(false);
    }

    return new Promise(resolve => {
      this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: User) => {
        if (user !== null) {
          resolve(self.isRouteAccessAllowed(path, allowAdmin));
        }
      });
    });
  }

  isRouteAccessAllowed(route: string, allowAdmin = false): boolean {
    if (!this.user) {
      return false;
    }

    if (allowAdmin && this.user.isAdmin) {
      return true;
    }

    if (this.user.isSuperAdmin) {
      return true;
    }

    if (!this.user.accessInfo) {
      return false;
    }

    if (route === TASKS_PATH) {
      return true;
    }

    if (route === MAIN_PATH && !IS_FREE_LEADS_DOMAIN) {
      return true;
    }

    if (route === TASKS_PATH) {
      return true;
    }

    // We have to do a nasty swap here so we don't have to regenerate
    // everyone's accessInfo
    switch (route) {
      case PROJECTS_PATH:
        return this.hasPermission('dashboard');
      case SCHEDULE_V2_PATH:
      case SCHEDULE_V3_PATH:
        return this.hasPermission(SCHEDULE_PATH);
      default:
        return this.hasPermission(route);
    }
  }

  hasPermission(route) {
    return !!(this.user.accessInfo.features || []).find(feature => feature.type === 'Menu' && feature.path === route);
  }
}
