import { Injectable } from '@angular/core';
import { AWSError, CognitoIdentityCredentials, config as AWSConfig } from 'aws-sdk/global';

import S3, { DeleteObjectOutput } from 'aws-sdk/clients/s3';
import { AuthenticationService } from '../auth/services/authentication.service';
import {
  HostConfiguration,
  PathwayConfiguration,
  PathwayConfigurationService
} from '../auth/services/pathway-configuration.service';

@Injectable({ providedIn: 'root' })
export class S3Service {
  pathwayConfiguration: PathwayConfiguration;
  cognitoAwsCredentials: CognitoIdentityCredentials;
  region = 'eu-west-2';

  constructor(
    private authenticationService: AuthenticationService,
    private pathwayConfigurationService: PathwayConfigurationService
  ) {}

  async initialise(): Promise<S3Service> {
    if (!this.pathwayConfiguration) {
      this.pathwayConfiguration = await this.pathwayConfigurationService.configure();
    }
    return this;
  }

  async getS3Client(): Promise<S3> {
    await this.initialise();
    const idToken = await this.authenticationService.getIdToken();
    await this.initialiseIdentity(this.pathwayConfiguration.configuration, idToken);

    return new S3({
      region: this.region,
      apiVersion: '2006-03-01'
    });
  }

  initialiseIdentity(configuration: HostConfiguration, idToken: unknown) {
    const logins = {};
    logins[`cognito-idp.${this.region}.amazonaws.com/${configuration.poolId}`] = idToken;
    this.cognitoAwsCredentials = new CognitoIdentityCredentials(
      { IdentityPoolId: configuration.identityPoolId, Logins: logins },
      { region: this.region }
    );
    return new Promise((resolve, reject) => {
      this.cognitoAwsCredentials.refresh((err: AWSError) => {
        if (err) {
          reject('Unable to refresh identity');
        } else {
          AWSConfig.credentials = this.cognitoAwsCredentials;
          resolve(null);
        }
      });
    });
  }

  async remove(key: string, bucketName: string): Promise<DeleteObjectOutput> {
    const s3 = await this.getS3Client();
    return await s3.deleteObject({ Key: key, Bucket: bucketName }).promise();
  }
}
