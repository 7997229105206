@if (vm.show) {
  <div class="schedule-event-selected-job-details__row-split schedule-event-selected-job-details__content" [formGroup]="vm.form">
    <div class="schedule-event-selected-job-details__input">
      <jui-input
        size="sm"
        clearable="true"
        formControlName="rescheduleReason"
        [label]="vm.i18nRescheduleReasonInputLabel"
        [placeholder]="vm.i18nRescheduleReasonInputPlaceholder"
        [attr.data-qa]="vm.qaRescheduleReasonInput"
      >
      </jui-input>
    </div>
  </div>
}
