import { PathwayApiEnvironment } from './pathway-api-environment';
import * as appVersion from '../app-version.json';
const versionFile = appVersion;

export const environment: Partial<PathwayApiEnvironment> = {
  production: true,
  name: 'production',
  appVersion: versionFile.appVersion,
  translationPath: 'production',
  bucketSuffix: 'production',
  analytics: true,
  supportEmail: 'support@jumptech.co.uk',
  pathwaySupportUrl: 'https://support.jumptech.co.uk/',
  domain: 'jumptech.co.uk',
  apiRoot: 'https://api.jumptech.co.uk',
  launchDarkly: {
    clientToken: '629f5c4e696f831520d5c138'
  },
  datadog: {
    site: 'datadoghq.eu',
    service: 'pathway',
    clientTokenLogging: 'pub287d3da1eff9dd83936e3bcbe4343915',
    clientToken: 'pubea42ebcd7ee2bc312a9e5bad46e22381',
    applicationId: 'dd42dadf-b62b-40e1-9ec7-8410afb0a560',
    sampleRate: 100,
    replaySampleRate: 0,
    actionNameAttribute: 'data-qa',
    trackInteractions: true
  },
  httpRetryConfig: {
    initialInterval: 500,
    maxRetries: 7,
    resetOnSuccess: false
  },
  socketRetryConfig: {
    initialInterval: 1000,
    maxRetries: 10,
    resetOnSuccess: true
  },
  staticDistribution: 'https://static.jt.eco'
};
