import { Injectable } from '@angular/core';
import { fromEvent, map, Observable, startWith, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutComponentColumnsService {
  private readonly minProjectDetailCols = 2;
  private readonly maxProjectDetailCols = 4;
  private readonly maxProjectColWidth = 500;
  private readonly defaultWidth = 1000;
  private readonly destroy$ = new Subject<void>();

  public subscribe(layoutsLength = 1, id: string): Observable<number> {
    if (layoutsLength < this.minProjectDetailCols) {
      layoutsLength = this.minProjectDetailCols;
    }
    const getTabsWidth = () => document?.getElementById(id)?.offsetWidth ?? this.defaultWidth;
    const currentCols = () =>
      Math.min(Math.ceil(getTabsWidth() / this.maxProjectColWidth), Math.min(layoutsLength, this.maxProjectDetailCols));
    return fromEvent(window, 'resize').pipe(
      startWith(currentCols()),
      takeUntil(this.destroy$),
      map(() => currentCols())
    );
  }

  public unsubscribe(): void {
    this.destroy$.next();
  }
}
