import { Injectable } from '@angular/core';
import { Job } from '../../core/domain/job';
import { EventInfo, JobInfo } from './utils/schedule-types';
import { ErrorType } from './schedule.model';
import { ScheduleRepositoryV3 } from './schedule.repository.v3';

@Injectable()
export class SchedulePresenter {
  constructor(private repository: ScheduleRepositoryV3) {}

  setSelectedJob(job: Job): void {
    this.repository.setSelectedJob(job);
  }

  public openScheduleDetails(jobInformation: JobInfo, cb, calEvent: EventInfo): void {
    this.repository.openJobDetails(jobInformation, cb, calEvent);
  }

  public showError(type: ErrorType, e): void {
    this.repository.handleErrors(type, e);
  }

  public showSelectedJob() {
    this.repository.showDetailsToggle(false);
  }
}
