import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { BehaviorSubject } from 'rxjs';
import { EnaApplicationPresenter } from '../ena-application.presenter';
import { EnaActionsVm } from '../ena.model';

@Component({
  selector: 'ena-application-actions',
  standalone: true,
  imports: [CommonModule, CoreComponentsAngularModule],
  templateUrl: './ena-application-actions.component.html',
  styleUrl: './ena-application-actions.component.scss'
})
export class EnaApplicationActionsComponent implements OnInit {
  vm$: BehaviorSubject<EnaActionsVm> = new BehaviorSubject<EnaActionsVm>(null);

  constructor(private presenter: EnaApplicationPresenter) {}

  ngOnInit(): void {
    this.presenter.loadActions(this.vm$);
  }

  saveApplication(): void {
    this.presenter.saveApplication();
  }
  manuallyApprove(): void {
    this.presenter.manuallyApproveApplication();
  }

  undoChanges(): void {
    this.presenter.undoChanges();
  }

  submitDisabled(): boolean {
    return this.presenter.submitDisabled();
  }

  submitApplication(): void {
    this.presenter.submitApplication();
  }
}
