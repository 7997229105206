import { Injectable } from '@angular/core';
import { ScheduleRepositoryV3 } from '../../schedule.repository.v3';
import { ScheduleError } from '../../schedule.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ScheduleErrorsPresenter {
  constructor(private repository: ScheduleRepositoryV3) {}

  load(vmSubject$: BehaviorSubject<ScheduleError>): void {
    this.repository.getErrors((dm: ScheduleError) => {
      const vm = { ...dm };
      vmSubject$.next(vm);
    });
  }

  clearErrors(): void {
    this.repository.clearErrors();
  }
}
