@if (vm$ | async; as vm) { @if (vm.hasActions) {
<div class="ena-actions">
  @if (!vm.disableSave) {
  <div class="ena-actions__action">
    <jui-button
      size="sm"
      (click)="undoChanges()"
      color="low"
      [disabled]="vm.saveInProgress"
      [attr.data-qa]="vm.qaUndoButton"
      >{{ vm.undoLabel }}</jui-button
    >
  </div>
  } @if (!vm.submitInProgress) {
  <div class="ena-actions__action">
    <jui-button
      size="sm"
      (click)="saveApplication()"
      [disabled]="vm.disableSave"
      [loading]="vm.saveInProgress"
      [attr.data-qa]="vm.qaSaveButton"
      >{{ vm.saveLabel }}</jui-button
    >
  </div>
  }
  <div class="ena-actions__action">
    <jui-button
      size="sm"
      [disabled]="submitDisabled()"
      (click)="submitApplication()"
      [loading]="vm.submitInProgress"
      [attr.data-qa]="vm.qaSubmitButton"
      >{{ vm.submitLabel }}</jui-button
    >
  </div>
</div>
} @if(vm.isInReview) {
<div class="ena-actions__action">
  <jui-button
    size="sm"
    (click)="manuallyApprove()"
    [attr.data-qa]="vm.qaManuallyApprove"
    [disabled]="!vm.isInReview"
    [loading]="vm.approveInProgress"
    >{{ vm.manuallyApproveLabel }}</jui-button
  >
</div>
}}
