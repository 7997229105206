import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { AbstractControl, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { NgbActiveModal, NgbDatepickerModule, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { FeatureFlagService } from '../../../core/feature-flag/feature-flag.service';
import { DropDownElement } from '../../../shared/form-components/multiple-selection-dropdown.component';
import * as Analytics from '../../../app.analytics';
import {
  ALL_DAY_TIME_SLOT,
  TIME_SLOTS,
  VALIDATOR_MAX_LEN_DESCRIPTION,
  VALIDATOR_MAX_LEN_TITLE
} from '../../utils/schedule-constants';
import { EventTypeDropDownElement } from '../../utils/schedule-types';
import { pad } from '../../utils/schedule.helper';

@Component({
  selector: 'app-schedule-event-modal',
  templateUrl: 'schedule-event-modal.component.html',
  styleUrls: ['../../schedule.component.scss'],
  standalone: true,
  imports: [
    TranslocoModule,
    CommonModule,
    NgbDatepickerModule,
    NgSelectModule,
    ReactiveFormsModule,
    CoreComponentsAngularModule
  ]
})
export class ScheduleEventModalComponent implements OnInit {
  @Input() mode: 'edit' | 'add' | string;
  @Input() savingEvent: boolean;
  @Input() deletingEvent: boolean;
  @Input() eventData: any;
  @Input() isDeleting: boolean;
  @Input() engineerList: DropDownElement[];
  @Input() eventTypes: EventTypeDropDownElement[];
  @Input() tz: string;
  @Input() currentEventTitle: string;

  @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();

  titleRequired = true;
  form: UntypedFormGroup;
  maxLenDesc = VALIDATOR_MAX_LEN_DESCRIPTION;
  maxLenTitle = VALIDATOR_MAX_LEN_TITLE;
  timeSlots = TIME_SLOTS;
  datePickerPlaceholder = '';
  newSchedulerEnabled = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private domSanitizer: DomSanitizer,
    private featureFlagService: FeatureFlagService
  ) {}

  get isAllDayEvent() {
    return this.form.get('allDayEvent').value;
  }

  get startDate() {
    return this.form.get('startDate').value;
  }

  get endDate() {
    return this.form.get('endDate').value;
  }

  get localStartDateControl() {
    return this.form.get('localStartDate');
  }

  get localEndDateControl() {
    return this.form.get('localEndDate');
  }

  get startTimeControl() {
    return this.form.get('startTime');
  }

  get endTimeControl() {
    return this.form.get('endTime');
  }

  get titleControl() {
    return this.form.get('title');
  }

  async ngOnInit() {
    this.datePickerPlaceholder = JumptechDate.now().toDateFormat();
    this.initForm();
    const rollingMonthViewEnabled = await this.featureFlagService.isFeatureEnabled('rolling-month-schedule-view');
    const scheduleV3Enabled = await this.featureFlagService.isFeatureEnabled('scheduler-v3');
    this.newSchedulerEnabled = rollingMonthViewEnabled || scheduleV3Enabled;
    this.localiseTimeSlotLabels();
    this.onAllDayEventChange();
    if (this.eventData) {
      // edit mode
      let showTitle;
      if (this.eventData.extendedProps.eventType) {
        showTitle = this.eventTypes.find(x => x.id === this.eventData.extendedProps.eventType).title;
      } else {
        showTitle = this.eventTypes[0].title;
      }
      this.onEventTypeChange({ title: showTitle });
    } else {
      // create mode
      this.onEventTypeChange({ title: this.eventTypes[0].title });
    }
  }

  localiseTimeSlotLabels(): void {
    this.timeSlots = TIME_SLOTS.map(slot => {
      const date = JumptechDate.now().set({
        hour: Number(slot.label.split(':')[0]),
        minute: Number(slot.label.split(':')[1])
      });
      return {
        ...slot,
        label: date.toTimeFormat(false)
      };
    });
  }

  initForm() {
    this.form = this.fb.group({
      id: [this.eventData ? this.eventData.id : null],
      tz: [this.tz ?? ''],
      type: [
        this.eventData && this.eventData.extendedProps.eventType
          ? this.eventData.extendedProps.eventType
          : this.eventTypes[0].id,
        Validators.required
      ],
      title: [
        this.eventData ? this.eventData.title : '',
        [Validators.required, Validators.maxLength(VALIDATOR_MAX_LEN_TITLE)]
      ],
      description: [
        this.eventData ? this.eventData.extendedProps.description : null,
        Validators.maxLength(VALIDATOR_MAX_LEN_DESCRIPTION)
      ],
      engineer: [this.eventData ? this.eventData.extendedProps.userIds[0] : null, Validators.required],
      startDate: [
        this.eventData ? this.isoStringToDateStruct(this.eventData.extendedProps.startIso) : null,
        Validators.required
      ],
      endDate: [
        this.eventData ? this.isoStringToDateStruct(this.eventData.extendedProps.endIso) : null,
        Validators.required
      ],
      localStartDate: [null],
      localEndDate: [null],
      startTime: [this.eventData ? this.isoStringToTime(this.eventData.extendedProps.startIso) : this.timeSlots[0]],
      endTime: [
        this.eventData
          ? this.isoStringToTime(this.eventData.extendedProps.endIso)
          : this.timeSlots[this.timeSlots.length - 1]
      ],
      allDayEvent: [this.eventData ? this.eventData.extendedProps.allDayEvent : true]
    });

    this.form.valueChanges.subscribe(val => {
      this.validateSameDayTimes(val);
    });
  }

  private validateSameDayTimes(formValue): void {
    const from = JumptechDate.from(this.startDate);
    const to = JumptechDate.from(this.endDate);

    if (from.equals(to, { unit: 'day' }) && !this.isAllDayEvent) {
      if (parseInt(formValue.endTime.id) <= parseInt(formValue.startTime.id)) {
        this.form.setErrors({ eventEndTimeBeforeStartTime: true });
      }
    }
  }

  getMinEndDate(): NgbDateStruct {
    return this.startDate;
  }

  getMaxStartDate(): NgbDateStruct {
    return this.endDate;
  }

  formData() {
    // patch in the local dates
    this.localStartDateControl.patchValue(this.startDate);
    this.localEndDateControl.patchValue(this.endDate);
    return this.form.getRawValue();
  }

  onAllDayEventChange() {
    if (this.isAllDayEvent) {
      this.clearTimes();
    } else {
      this.resetTimes();
    }
  }

  clearTimes() {
    this.startTimeControl.patchValue(ALL_DAY_TIME_SLOT);
    this.endTimeControl.patchValue(ALL_DAY_TIME_SLOT);
  }

  resetTimes() {
    // if we have a valid time slot (NOT ALL DAY) then use it
    this.startTimeControl.patchValue(
      this.eventData && !this.eventData.extendedProps.allDayEvent
        ? this.isoStringToTime(this.eventData.extendedProps.startIso)
        : this.timeSlots[0]
    );
    this.endTimeControl.patchValue(
      this.eventData && !this.eventData.extendedProps.allDayEvent
        ? this.isoStringToTime(this.eventData.extendedProps.endIso)
        : this.timeSlots[this.timeSlots.length - 1]
    );
  }

  isoStringToDateStruct(isoString: string): NgbDateStruct {
    if (isoString) {
      const date = new Date(isoString);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
  }

  isoStringToTime(isoString: string): DropDownElement {
    if (isoString) {
      const date = new Date(isoString);
      const hrs = pad(date.getHours());
      const mins = pad(date.getMinutes());
      return this.timeSlots.find(slot => slot.id === hrs + mins);
    }
  }

  async submit() {
    if (this.isFormInvalid()) {
      return;
    }
    this.form.get('title').patchValue(this.domSanitizer.sanitize(SecurityContext.HTML, this.form.get('title').value));
    const formData = this.formData();
    this.saveEvent.emit(formData);

    const type = formData.type;
    const typeMatch = this.eventTypes.find(x => x.id === type);
    if (!typeMatch) {
      return;
    }

    Analytics.logEvent('CalendarEventSubmit', { title: formData.title, translationKey: typeMatch['translation'] });
  }

  onDelete() {
    this.isDeleting = true;
  }

  async onDeleteConfirm() {
    this.deleteEvent.emit(this.formData());
  }

  isFormInvalid() {
    return this.form.invalid;
  }

  asFormGroup(control: AbstractControl) {
    return control as UntypedFormGroup;
  }

  get formControls() {
    return this.form.controls;
  }

  onEventTypeChange(event) {
    this.titleRequired = event.title;
    if (!this.titleRequired) {
      this.titleControl.patchValue('');
    }
    this.updateFormTitleRequired(this.titleRequired);
  }

  updateFormTitleRequired(isRequired: boolean) {
    this.form.controls.title.setValidators(isRequired ? [Validators.required] : null);
    this.form.controls.title.updateValueAndValidity();
  }
}
