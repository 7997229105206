import { AuthenticationService } from '../auth/services/authentication.service';
import { AccessInfo, User } from '../core/domain/user';
import { checkIfExpression } from '../core/utils/filter';
import { arraysIntersect } from './utils';

export interface RoleOrTeamInput {
  roleName?: string[];
  teamName?: string[];
  roleId?: string[];
  teamId?: string[];
  tenant?: string;
  tier?: string;
}

export class AccessInfoHelper {
  public static accessInfoHasRoleOrTeam(accessInfo: AccessInfo, input: RoleOrTeamInput) {
    if (!accessInfo) {
      return false;
    }

    if (
      input.roleName &&
      arraysIntersect(
        accessInfo.roles.map(r => r.name),
        input.roleName
      )
    ) {
      return true;
    }

    if (
      input.roleId &&
      arraysIntersect(
        accessInfo.roles.map(r => r.id),
        input.roleId
      )
    ) {
      return true;
    }

    if (
      input.teamName &&
      arraysIntersect(
        accessInfo.teams.map(t => t.name),
        input.teamName
      )
    ) {
      return true;
    }

    return (
      input.teamId != undefined &&
      arraysIntersect(
        accessInfo.teams.map(t => t.id),
        input.teamId || []
      )
    );
  }

  public static filterListForUser(item, user: User, customFilterField = 'filter') {
    if (!user.accessInfo) {
      return false;
    }

    if (!item[customFilterField] || !item[customFilterField]?.length) {
      return true;
    }

    const roleOrTeamInput: RoleOrTeamInput = {
      roleId: (user.accessInfo.roles || []).reduce((a, role) => {
        return [...a, role.id];
      }, []),
      roleName: (user.accessInfo.roles || []).reduce((a, role) => {
        return [...a, role.name];
      }, []),
      teamId: (user.accessInfo.teams || []).reduce((a, team) => {
        return [...a, team.id];
      }, []),
      teamName: (user.accessInfo.teams || []).reduce((a, team) => {
        return [...a, team.name];
      }, []),
      tenant: user.tenant,
      tier: AuthenticationService.getTier()
    };

    let allowItem = false;

    for (const taskComponentFilter of item[customFilterField]) {
      const filter = AccessInfoHelper.formatLegacyFilters(taskComponentFilter);
      const shouldShow = checkIfExpression(filter, roleOrTeamInput);
      if (shouldShow) {
        allowItem = true;
      }
    }
    return allowItem;
  }

  private static formatLegacyFilters(filter) {
    for (const key of Object.keys(filter)) {
      if (!['roleId', 'roleName', 'teamId', 'teamName'].includes(key)) {
        continue;
      }
      const operator = Object.keys(filter[key])[0];
      if (operator === '$eq') {
        filter[key]['$inc'] = filter[key]['$eq'];
        delete filter[key]['$eq'];
      }
      if (operator === '$ne') {
        filter[key]['$ninc'] = filter[key]['$ne'];
        delete filter[key]['$ne'];
      }
    }
    return filter;
  }
}
