import { HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { checkIfExpression } from '@jump-tech-frontend/angular-common';
import { EnvironmentToken, pickLanguage } from '@jump-tech-frontend/app-config';
import { Card, CardActionDataItem, I18nKeys } from '@jump-tech-frontend/cards';
import { IfDefinition, JumptechDate, JumptechDateSettings } from '@jump-tech-frontend/domain';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { decode } from 'base64-arraybuffer';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, catchError, firstValueFrom, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { ConfirmModalConfig } from './domain/confirm-modal.config';
import { RelayError } from './domain/error';
import { i18n } from './domain/i18n';
import { ListQuestion } from './domain/list-question';
import { RelayCard } from './domain/relay-cards.model';
import { RelayEnvironment } from './domain/relay-environment';
import { RelayConfiguration, RelayParameters } from './domain/relay-parameters';
import { RelaySummary } from './domain/relay-summary';
import { UploadProgress } from './domain/upload-progress';
import { HttpGateway } from './http-gateway.service';
import { PersistenceService } from './persistence.service';
import {
  cleanForm,
  convertBase64IntoPaths,
  expandCardValues,
  expandQuestionValues,
  explodeSignedURLs,
  hasRequiredData,
  setFormData,
  toFormGroup
} from './utils';
import { CardsLibTranslationService } from './utils/cards-lib-translation.service';
import { makeFileResource } from './utils/file-resource';

export const TENANT_CONFIG = { tenant: null };

@Injectable({
  providedIn: 'root'
})
export class RelayRepository {
  subscriptionList: Subscription[] = [];

  parameters$: BehaviorSubject<RelayParameters | null>;

  currentCardNumber$: BehaviorSubject<number>;

  currentCard$: BehaviorSubject<RelayCard>;

  summary$: BehaviorSubject<RelaySummary>;

  customLogo$: BehaviorSubject<SafeResourceUrl>;

  error$: Subject<RelayError>;

  uploadProgress$: Subject<UploadProgress>;

  private tenant: string;
  private viewingTenant: string;
  private suid: string;
  private formName: string;
  private projectId: string;
  private projectType: string;
  private data: Record<string, unknown>;
  private originalCards: Card<unknown>[] = [];
  private filteredCards: Card<unknown>[] = [];
  private relayProject: Record<string, any> = {};
  private submissionMessage?: string;
  private online?: boolean;
  private ignoreShowIf?: boolean;
  private i18nKeys?: I18nKeys;
  private form?: UntypedFormGroup;
  private customStyle: SafeResourceUrl;
  private customFont: SafeResourceUrl;
  private isCsat = false;
  private useBranding = false;
  private dontSubmitCards = false;
  private readOnly = false;

  /** used in the render function to give access to urls eg {{apiFormBase}}/doc
   * DO NOT REMOVE */
  private apiFormBase;

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpGateway: HttpGateway,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private cardsLibTranslationService: CardsLibTranslationService,
    private sanitizer: DomSanitizer,
    private spinnerService: NgxSpinnerService,
    private persistenceService: PersistenceService,
    @Inject(EnvironmentToken) private environment: RelayEnvironment
  ) {
    this.init();
    this.apiFormBase = environment.apiFormBase;
  }

  private readonly SPINNER = 'relaySpinner';

  public init() {
    this.parameters$ = new BehaviorSubject<RelayParameters | null>(null);
    this.currentCardNumber$ = new BehaviorSubject<number>(0);
    this.currentCard$ = new BehaviorSubject<RelayCard>(null);
    this.summary$ = new BehaviorSubject<RelaySummary>(null);
    this.customLogo$ = new BehaviorSubject<string>(null);
    this.error$ = new Subject<RelayError>();
    this.uploadProgress$ = new Subject<UploadProgress>();
    this.subscribeToParameters();
    this.subscriptionList.push(
      this.activatedRoute.queryParams.subscribe(async (params: Params) => {
        if (!params) {
          return;
        }
        this.suid = params['suid'];
        this.formName = params['f'];
        if (this.suid) {
          this.spinnerService.show(this.SPINNER).then();
          const parameters = await this.getRelayParameters();
          this.setParameters(parameters);
          this.spinnerService.hide(this.SPINNER).then();
        }
      })
    );
  }

  public setParameters(parameters: RelayParameters) {
    this.parameters$.next(parameters);
  }

  public getCurrentCard(callback) {
    this.subscriptionList.push(this.currentCard$.subscribe(callback));
  }

  public getSummary(callback) {
    this.subscriptionList.push(this.summary$.subscribe(callback));
  }

  public getCustomLogo(callback) {
    this.subscriptionList.push(this.customLogo$.subscribe(callback));
  }

  public getError(callback) {
    this.subscriptionList.push(this.error$.subscribe(callback));
  }

  public clearError() {
    this.error$.next(null);
  }

  public getUploadProgress(callback) {
    this.subscriptionList.push(this.uploadProgress$.subscribe(callback));
  }

  public clearUploadProgress() {
    this.uploadProgress$.next(null);
  }

  public async previousCard(readOnly = true, data?: CardActionDataItem[]) {
    this.setData(data);
    this.filteredCards = this.filterForShowIf();

    if (!readOnly) {
      await this.submitCards();
    }

    this.currentCardNumber$.next(this.currentCardNumber$.value - 1);
  }

  public async nextCard(readOnly = true, data?: CardActionDataItem[]) {
    this.setData(data);
    this.filteredCards = this.filterForShowIf();

    const confirmConfig = this.filteredCards[this.currentCardNumber$.value]?.confirmConfig;
    const params = this.form?.get(this.filteredCards[this.currentCardNumber$.value].key)?.value;
    if (!readOnly && hasRequiredData(confirmConfig, params)) {
      const confirmed = await this.showConfirm(confirmConfig);
      if (!confirmed) {
        return;
      }
    }

    if (!readOnly) {
      await this.submitCards();
    }

    this.currentCardNumber$.next(this.currentCardNumber$.value + 1);
    this.hydrateIsNextDisabled();
    this.hydrateIsSubmitDisabled();
  }

  public async submitCard(readOnly = true, data?: CardActionDataItem[]) {
    this.setData(data);
    this.filteredCards = this.filterForShowIf();

    if (!readOnly) {
      await this.submitCards();
    }

    if (readOnly || (await this.sendRelayForm())) {
      const summary = this.filteredCards.length - 1;
      this.currentCardNumber$.next(summary);
    }
    this.hydrateIsNextDisabled();
    this.hydrateIsSubmitDisabled();
  }

  public async reset() {
    this.form.reset();
    this.currentCardNumber$.next(0);
  }

  public unsubscribe(): void {
    this.subscriptionList.forEach(sub => sub.unsubscribe());
    this.subscriptionList = [];
  }

  public subscribeToParameters() {
    if (this.parameters$.observed) {
      return;
    }
    this.subscriptionList.push(
      this.parameters$.subscribe(async parameters => {
        if (parameters) {
          await this.initialise(parameters);
        }
      })
    );
  }

  private setData(data: CardActionDataItem[]) {
    if (!data?.length) {
      return;
    }
    const card = this.currentCard$.value?.card;
    const form = this.form;
    setFormData(card, form, data);
  }

  private async getRelayParameters(): Promise<RelayParameters> {
    let parameters: RelayConfiguration;
    try {
      let params: HttpParams = new HttpParams();
      if (this.formName && this.formName !== 'undefined') {
        params = params.append('form', this.formName);
      }
      const options = { params };
      parameters = await this.httpGateway.get(`${this.environment.apiProjectParamsUrl}/${this.suid}`, options);
      if (parameters.error) {
        await this.initialiseI18n(parameters.i18n);
        if (!this.environment.production) {
          console.log(parameters.error);
        }
        let message = this.translocoService.translate('errors.invalidForm');
        if (parameters.error === 'Invalid Token') {
          message = this.translocoService.translate('errors.invalidToken');
        }
        this.notifyError(message);
      }
    } catch (err) {
      if (!this.environment.production) {
        console.log(err);
      }
    }

    return {
      tenant: parameters.relayParams?.tenant,
      projectId: parameters.relayParams?.id,
      projectType: parameters.relayParams?.type,
      relayProjectType: parameters.relayParams?.relayProjectType || parameters.relayParams?.type,
      customLogo: parameters.relayConfiguration?.configuration?.customerLogo,
      customStyle: parameters.relayConfiguration?.configuration?.styles,
      customFont: parameters.relayConfiguration?.configuration?.font,
      useTenantBranding: parameters.relayConfiguration?.configuration?.useTenantBranding,
      dontSubmitCards: parameters.relayConfiguration?.configuration?.dontSubmitCards,
      readOnly: parameters.relayParams?.readOnly,
      data: {
        ...parameters.relayParams?.data,
        resources: parameters.relayParams?.resources,
        relayProjectType: parameters.relayParams?.relayProjectType || parameters.relayParams?.type,
        installer: parameters.relayParams?.installer,
        installerPrivacyPolicy: parameters.relayParams?.installerPrivacyPolicy,
        installerTermsAndConditions: parameters.relayParams?.installerTermsAndConditions
      },
      cards: parameters.relayConfiguration?.configuration?.cards,
      relayProject: parameters.relayProject,
      i18n: parameters.i18n,
      error: parameters.error
    };
  }

  private async initialise(parameters: RelayParameters) {
    try {
      this.spinnerService.show(this.SPINNER).then();
      this.tenant = parameters.tenant;
      this.viewingTenant = parameters.viewingTenant;
      TENANT_CONFIG.tenant = parameters.tenant;
      this.projectId = parameters.projectId;
      this.projectType = parameters.projectType;
      this.data = parameters.data;
      this.originalCards = parameters.cards;
      this.isCsat = this.originalCards?.some(x => x.type === 'csat');
      this.readOnly = parameters.readOnly;
      this.dontSubmitCards = parameters.dontSubmitCards;

      this.relayProject = parameters.relayProject;
      this.submissionMessage = parameters.submissionMessage;
      this.ignoreShowIf = parameters.ignoreShowIf;

      const localProject = await this.persistenceService.get(this.suid);
      this.relayProject = { ...this.relayProject, ...localProject };

      parameters.customLogo = this.initialiseLogo(parameters);
      parameters.customStyle = this.initialiseStyles(parameters);
      parameters.customFont = this.initialiseFont(parameters);
      this.customLogo$.next(this.sanitizer.bypassSecurityTrustResourceUrl(parameters.customLogo));
      this.customStyle = this.sanitizer.bypassSecurityTrustResourceUrl(parameters.customStyle);
      this.customFont = this.sanitizer.bypassSecurityTrustResourceUrl(parameters.customFont);
      this.useBranding = parameters.useTenantBranding;

      await this.initialiseI18n(parameters.i18n);

      if (!parameters.i18nKeys) {
        parameters.i18nKeys = this.cardsLibTranslationService.loadTranslations();
      }
      this.i18nKeys = parameters.i18nKeys;

      if (this.isNonCameraBrowser()) {
        return;
      }
      if (this.originalCards) {
        await this.initialiseCards();
        await this.initialiseForm();
        this.subscribeToCardChanges();
      }

      this.spinnerService.hide(this.SPINNER).then();

      this.currentCardNumber$.next(this.relayProject?.currentCard || parameters.currentCard || 0);
    } catch (err) {
      this.spinnerService.hide(this.SPINNER).then();
      if (!this.environment.production) {
        console.log(err);
      }
      this.notifyError(this.translocoService.translate('errors.invalidForm'));
    }
  }

  private initialiseStyles(parameters: RelayParameters): string {
    const projectBranding = parameters.data?.['projectBranding'] as string;
    let styleUrl = parameters.customStyle;
    if (!styleUrl) {
      styleUrl = `${this.environment.staticDistribution}/tenant-styles/${
        projectBranding || this.viewingTenant || this.tenant || 'jumptech'
      }.css`;
    }

    styleUrl = styleUrl.replace(/{{tenant}}/g, parameters.tenant);
    styleUrl = styleUrl.replace(/{{projectBranding}}/g, projectBranding || parameters.tenant);
    return styleUrl;
  }

  private initialiseLogo(parameters: RelayParameters) {
    const projectBranding = parameters.data?.['projectBranding'] as string;
    let logoUrl = parameters.customLogo;
    if (!logoUrl) {
      logoUrl = `${this.environment.staticDistribution}/tenant-logos/${
        projectBranding || this.viewingTenant || this.tenant || 'jumptech'
      }.png`;
    }

    logoUrl = logoUrl.replace(/{{tenant}}/g, parameters.tenant);
    logoUrl = logoUrl.replace(/{{projectBranding}}/g, projectBranding || parameters.tenant);
    return logoUrl;
  }

  private initialiseFont(parameters: RelayParameters): string {
    return `https://fonts.googleapis.com/css?family=${parameters.customFont || 'Roboto'}:300`;
  }

  private async initialiseI18n(i18n: i18n) {
    if (!i18n) {
      return;
    }
    let allowedLanguageList = [];
    if (i18n) {
      allowedLanguageList = [i18n.locale, ...i18n.allowedLocales, ...allowedLanguageList];
    }
    this.translocoService.setAvailableLangs(allowedLanguageList);

    for (const desiredLang of navigator.languages) {
      const matchedLanguage = pickLanguage(allowedLanguageList, desiredLang, { loose: true });
      if (matchedLanguage) {
        this.translocoService.setActiveLang(matchedLanguage);
        if (!this.environment.production) {
          console.log('\x1b[32m%s\x1b[0m', `Active LANG: [${desiredLang}]`);
        }
        break;
      } else {
        this.translocoService.setActiveLang('en-GB');
      }
      if (!this.environment.production) {
        console.log('\x1b[35m%s\x1b[0m', `Desired LANG: [${desiredLang}]`);
      }
    }
    const activeLanguage = this.translocoService.getActiveLang();
    JumptechDateSettings.defaultLocale = activeLanguage;
    await firstValueFrom(this.translocoService.load(activeLanguage));
  }

  private async initialiseForm() {
    const data = this.data || {};
    this.form = toFormGroup(this.originalCards, this.relayProject, data);
    this.subscribeToFormChanges(this.form);
  }

  private async initialiseCards() {
    const context = {
      ...this.data,
      ...{
        suid: this.suid,
        projectId: this.projectId,
        formName: this.formName,
        apiFormBase: this.apiFormBase
      }
    };
    expandCardValues(this.originalCards, context);
    expandQuestionValues(this.originalCards, context);
    await this.loadQuestionLists(this.originalCards);
  }

  private isNonCameraBrowser() {
    const ua = navigator.userAgent || navigator.vendor;
    const isNonCameraBrowser = ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('OPR') > -1;
    if (isNonCameraBrowser) {
      this.notifyError(this.translocoService.translate('errors.unsupportedBrowser'));
      return true;
    }
    return false;
  }

  private subscribeToFormChanges(form: UntypedFormGroup) {
    this.subscriptionList.push(
      form?.valueChanges?.subscribe(() => {
        this.hydrateIsNextDisabled();
        this.hydrateIsSubmitDisabled();
      })
    );
  }

  private subscribeToCardChanges() {
    this.subscriptionList.push(
      this.currentCardNumber$.subscribe(cardNumber => {
        this.filteredCards = this.filterForShowIf();
        let currentCard = this.filteredCards[cardNumber];
        const hasSubmit =
          (cardNumber === this.filteredCards.length - 2 &&
            currentCard?.submitConfig &&
            this.filteredCards[this.filteredCards.length - 1].type === 'info') ||
          currentCard?.submit;
        const hasNext = !hasSubmit && (currentCard?.type !== 'summary' || cardNumber === 0);
        const hasPrevious = cardNumber > 0 && currentCard?.type !== 'summary';

        const total = this.filteredCards.length - 1; // ignore the summary
        const step = cardNumber + 1; // 1 based
        currentCard = { ...currentCard, step, total };

        const card: RelayCard = {
          form: this.form,
          i18nKeys: this.i18nKeys,
          customStyle: this.customStyle,
          customFont: this.customFont,
          useBranding: this.useBranding,
          cardNumber: cardNumber,
          card: currentCard,
          hasSubmit,
          submitLabel: this.translocoService.translate('_commons.submit'),
          submitDisabled: true,
          hasNext,
          nextLabel: this.translocoService.translate(cardNumber === 0 ? '_commons.start' : '_commons.next'),
          nextDisabled: true,
          hasPrevious,
          previousLabel: this.translocoService.translate('_commons.back'),
          percentLabel: this.translocoService.translate('_commons.percentComplete')
        };
        this.currentCard$.next(card);
        this.hydrateIsNextDisabled();
        this.hydrateIsSubmitDisabled();

        const includeInPercentage = this.filteredCards.filter(card => card.section !== undefined).length - 1; // ignore the summary;
        const excludeInPercentage = this.filteredCards.filter(card => card.section === undefined).length;
        const current = step - excludeInPercentage;
        const percent = Math.ceil((current / includeInPercentage) * 100);

        let showProgress = !!card?.card?.section;
        if (includeInPercentage <= 1) {
          showProgress = false;
        }

        const summary = {
          showProgress: showProgress,
          progress: `${percent}%`,
          label:
            card?.card?.section && showProgress
              ? `${percent}${card.percentLabel} ${card.card.label ? ' | ' + card.card.label : ''}`
              : card.card?.label,
          total: total,
          step: step
        };

        this.summary$.next(summary);
      })
    );
  }

  private hydrateIsNextDisabled() {
    const theForm = this.form.get(this.filteredCards[this.currentCardNumber$.value]?.key);

    this.currentCard$.next({
      ...this.currentCard$.value,
      nextDisabled: !theForm?.valid || theForm?.disabled
    });
  }

  private hydrateIsSubmitDisabled() {
    const key = this.filteredCards[this.currentCardNumber$.value]?.key;
    this.currentCard$.next({
      ...this.currentCard$.value,
      submitDisabled: this.form.get(key)?.invalid ?? false
    });
  }

  private async submitCards(): Promise<boolean> {
    try {
      await this.spinnerService.show(this.SPINNER);
      if (!this.suid || !this.form) {
        return false;
      }
      const form = await this.persistLocally();
      if (this.dontSubmitCards) {
        return true;
      }

      const parameters: { form?: string } = {
        form: this.formName
      };
      const data = await this.httpGateway.post(`${this.environment.relayCardUrl}/${this.suid}`, form, {
        headers: { 'Content-Type': 'application/json' },
        params: new HttpParams({ fromObject: parameters })
      });
      const successful = await this.processSubmitResponse(data);
      if (!successful) {
        return false;
      }
      await this.persistLocally();
      this.logProgress();
      return true;
    } catch (err) {
      if (!this.environment.production) {
        console.log(err);
      }
    } finally {
      await this.spinnerService.hide(this.SPINNER);
    }
  }

  private async persistLocally() {
    const form = {
      currentCard: this.currentCardNumber$.value,
      ...this.form.getRawValue()
    };

    cleanForm(this.filteredCards, form, this.currentCardNumber$.value);
    form.currentCard = this.currentCardNumber$.value;
    await this.persistenceService.persist(this.suid, form);

    return convertBase64IntoPaths(form);
  }

  private async sendRelayForm(): Promise<boolean> {
    if (!this.suid || !this.projectId) {
      return false;
    }

    const confirmationConfig = this.getConfirmationConfig(true);
    if (confirmationConfig.show && !this.isCsat) {
      if (!(await this.showConfirm(confirmationConfig))) {
        return;
      }
    }

    let suid = this.suid;
    const fromObject: { form?: string; projectId?: string } = {};
    if (!this.suid && this.projectId) {
      suid = 'legacy';
      fromObject.projectId = this.projectId;
    }

    if (this.formName) {
      fromObject.form = this.formName;
    }

    try {
      await this.spinnerService.show(this.SPINNER);
      const cloned = _.cloneDeep(this.form.value);
      cleanForm(this.filteredCards, cloned);
      await this.httpGateway.post(`${this.environment.relayFormUrl}/${suid}`, cloned, {
        headers: { 'Content-Type': 'application/json' },
        params: new HttpParams({ fromObject })
      });
      await this.persistenceService.remove(this.suid);
    } catch (err) {
      await this.spinnerService.hide(this.SPINNER);
      if (!this.environment.production) {
        console.log(err);
      }

      const message = this.translocoService.translate('errors.unableToSubmit');
      this.notifyError(message, async () => {
        if (await this.sendRelayForm()) {
          this.currentCardNumber$.next(this.currentCardNumber$.value + 1);
          this.hydrateIsNextDisabled();
          this.hydrateIsSubmitDisabled();
        }
      });
      return false;
    } finally {
      await this.spinnerService.hide(this.SPINNER);
    }
    return true;
  }

  private filterForShowIf(): Card<unknown>[] {
    return this.originalCards.filter((card: Card<unknown>) => {
      if (this.ignoreShowIf || !card.showIf) {
        return true;
      }
      const formAndDataValue = {
        ...(this.data || {}),
        ...this.form.getRawValue()
      };
      if (Array.isArray(card.showIf)) {
        return card.showIf.filter((c: IfDefinition) => {
          if (!Object.prototype.hasOwnProperty.call(c, 'key') || !Object.prototype.hasOwnProperty.call(c, 'value')) {
            return true;
          }
          return _.get(formAndDataValue, c.key as string) === c.value;
        }).length;
      } else if (typeof card.showIf === 'object') {
        return checkIfExpression(card.showIf, formAndDataValue);
      }
    });
  }

  private async loadQuestionLists(cards: Card<unknown>[], tenant?: string): Promise<void> {
    for (const card of cards) {
      if (!card.questionList) {
        continue;
      }

      let questions: ListQuestion[];
      try {
        questions = await this.getQuestionList(card.questionList, tenant);
        if (!questions) {
          continue;
        }
      } catch (err) {
        if (!this.environment.production) {
          console.log(err);
        }
        continue;
      }
      card.questions = questions
        .sort((q1, q2) => {
          return q1.order && q2.order ? q1.order - q2.order : 0;
        })
        .map(q => {
          const config = {
            controlType: q.controlType,
            key: q.name,
            label: q.description,
            required: true,
            options: (q.options?.split(',') || []).map((opt: string) => ({
              key: opt,
              value: opt
            })),
            hint: q.hint,
            showIf: undefined
          };

          if (!tenant && card.questionList) {
            config['showIf'] = {
              [card.questionList]: {
                $mr: q.name
              }
            };
          }

          return config;
        });

      card.questions.push({
        controlType: 'TextboxQuestion',
        key: card.questionList,
        label: '',
        show: false,
        value: undefined
      });
    }
  }

  private async getQuestionList(key: string, tenant: string): Promise<ListQuestion[]> {
    return await this.httpGateway.get(
      `${this.environment.apiGetListUrl}/${key}?tenant=${TENANT_CONFIG.tenant || tenant}`,
      {}
    );
  }

  private uploadFileToS3(signedURL: string, encodedFile: string): Observable<HttpEvent<unknown>> {
    const parts = encodedFile.match(/^data:([^;]+);base64,(.+)$/);
    if (!parts || parts.length !== 3) {
      return throwError(() => {
        throw new Error('Invalid encoded string:' + encodedFile);
      });
    }

    const contentType: string = parts[1];
    const body: ArrayBuffer = decode(parts[2]);
    return this.httpGateway.putObservable(signedURL, body, {
      headers: {
        'Content-Type': contentType
      },
      reportProgress: true,
      observe: 'events'
    });
  }

  private async processSubmitResponse(data: unknown): Promise<unknown> {
    const filesToUpload = [];
    if (!data) {
      return true;
    }

    // write any response values back to the form
    const sectionKeys = Object.keys(data);

    sectionKeys.forEach(sectionKey => {
      const questionKeys = Object.keys(data[sectionKey]);

      questionKeys.forEach(questionKey => {
        const value = data[sectionKey][questionKey];
        const formControl = (this.form.get(sectionKey) as UntypedFormGroup).get(questionKey);
        if (!formControl) {
          return;
        }
        if (Array.isArray(value)) {
          const newValues = [...formControl.value];
          for (let i = 0; i < newValues.length; i++) {
            if (value[i] === null) {
              // This value was unchanged, so there's nothing to do here.
              continue;
            }

            const signedURLs = explodeSignedURLs(value[i]);
            if (signedURLs) {
              const fileResource = makeFileResource(formControl.value[i]);
              if (fileResource) {
                filesToUpload.push({
                  signedURLs,
                  data: fileResource.getValue(),
                  newValues,
                  i,
                  formControl
                });
                fileResource.setValue(signedURLs.getURL);
                newValues[i] = fileResource.toJson();
              }
            } else {
              newValues[i] = value[i];
            }
          }
        }
      });
    });

    if (!filesToUpload.length) {
      return true;
    }

    await this.spinnerService.hide(this.SPINNER);
    return new Promise((resolve, reject) => {
      let filesUploaded = 0;
      const progress: UploadProgress = {
        progress: 0,
        show: true
      };
      try {
        const fileCount = filesToUpload.length;
        for (let j = 0; j < filesToUpload.length; j++) {
          progress.label = this.translocoService.translate('fileUpload.uploadProgress');
          const fileToUpload = filesToUpload[j];
          this.uploadFileToS3(fileToUpload.signedURLs.putURL, fileToUpload.data)
            .pipe(
              catchError(err => {
                if (!this.environment.production) {
                  console.log(err);
                }
                return of({ type: HttpEventType.User });
              })
            )
            .subscribe((event: HttpEvent<unknown>) => {
              if (event.type === HttpEventType.UploadProgress) {
                progress.progress = Math.ceil((event.loaded * 100.0) / event.total);
                progress.progressPercent = `${progress.progress}%`;
              } else if (event.type === HttpEventType.Response) {
                fileToUpload.formControl.patchValue(fileToUpload.newValues);
                filesUploaded++;
              } else if (event.type === HttpEventType.User) {
                progress.error = this.translocoService.translate('fileUpload.uploadFailed');
                resolve(false);
              }

              this.uploadProgress$.next(progress);
            })
            .add(() => {
              if (fileCount === filesUploaded) {
                this.uploadProgress$.next(null);
                resolve(true);
              }
            });
        }
      } catch (err) {
        if (!this.environment.production) {
          console.log(err);
        }
        this.uploadProgress$.next(null);
        reject(err);
      }
    });
  }

  private logProgress(relayProgress?: RelaySummary) {
    const relayStepProgress = `|${this.currentCardNumber$.value + 1}/${this.filteredCards.length - 1}`;

    const progress = relayProgress || {
      tenant: TENANT_CONFIG.tenant,
      projectId: '',
      suid: this.suid || '',
      progress: (this.summary$.value?.progress.replace('%', '') || '0') + relayStepProgress,
      progressDate: JumptechDate.now().toIso(),
      cardLabel:
        this.filteredCards[this.currentCardNumber$.value] && this.filteredCards[this.currentCardNumber$.value]?.label,
      form: this.formName || undefined,
      isForm: !!this.formName
    };

    this.httpGateway
      .post(this.environment.apiRelayProgressUrl, progress, {
        'Content-Type': 'application/json'
      })
      .then()
      .catch(err => {
        if (!this.environment.production) {
          console.log(err);
        }
      });
  }

  private async showConfirm(config: ConfirmModalConfig): Promise<boolean> {
    const confirmModalRef = this.modalService.open(ConfirmModalComponent, { modalDialogClass: 'bottom-modal' });
    confirmModalRef.componentInstance.config = config;
    try {
      return await confirmModalRef.result;
    } catch (error) {
      // dismissed
      return false;
    }
  }

  private getConfirmationConfig(isSubmit = false) {
    const confirmConfig = this.filteredCards[this.currentCardNumber$.value]?.confirmConfig;
    if (!isSubmit) {
      return confirmConfig;
    }

    const config =
      confirmConfig ||
      ({
        title: '',
        messages: this.submissionMessage ? [this.submissionMessage] : null,
        confirm: '',
        cancel: '',
        show: true
      } as ConfirmModalConfig);
    if (config.show === undefined) {
      config.show = true; // if not specified, then show it (for backward compatibility)
    }
    return config;
  }

  private notifyError(message?: string, action?: () => void) {
    const error: RelayError = {
      type: 'Error',
      title: this.translocoService.translate('errors.error'),
      message: message || this.translocoService.translate('errors.error'),
      okLabel: this.translocoService.translate('_commons.ok'),
      cancelLabel: this.translocoService.translate('_commons.cancel'),
      actionLabel: this.translocoService.translate('_commons.retry'),
      action: action
    };
    this.error$.next(error);
  }

  private subscribeToOnline() {
    window.addEventListener('load', () => {
      this.online = navigator.onLine;

      window.addEventListener('online', () => {
        this.online = true;
      });

      window.addEventListener('offline', () => {
        this.online = false;
      });
    });
  }
}
