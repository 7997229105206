import { Component, Input } from '@angular/core';
import { SelectedJobInfoVm } from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'schedule-event-selected-job-details-job-info',
  templateUrl: 'schedule-event-selected-job-details-job-info.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  standalone: true,
  imports: [CoreComponentsAngularModule]
})
export class ScheduleEventSelectedJobDetailsJobInfoComponent {
  @Input() vm: SelectedJobInfoVm;

  constructor() {}
}
