import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';

// View Models
export interface MyOrdersVm {
  packageName: string;
  productList: ProductLineItem[];
  accessoriesList: ProductLineItem[];

  hasPackage: boolean;

  numberOfOrders: number;
  driverAddress: ShippingAddress;
  defaultAddress: ShippingAddress;
  shipmentDatePlaceholder: string;
  shipmentDateEarliest: NgbDateStruct;
  orderForm: FormGroup;

  showCustomAddress: boolean;
  showDriverAddress: boolean;

  addressTypeCustom: SelectedAddressType;
  addressTypeDriver: SelectedAddressType;

  qaCreateOrderSection: string;
  qaOrdersSection: string;
  qaOrderForm: string;
  qaOrderFormHardwareSection: string;
  qaOrderFormAddressSection: string;
  qaPackageTitle: string;
  qaCreateOrderButton: string;
  qaAddressTypeControl: string;
  qaStartReorderButton: string;
  qaStopReorderButton: string;

  qaCustomAddress_name: string;
  qaCustomAddress_company: string;
  qaCustomAddress_email: string;
  qaCustomAddress_phone: string;
  qaCustomAddress_line1: string;
  qaCustomAddress_line2: string;
  qaCustomAddress_locality: string;
  qaCustomAddress_town: string;
  qaCustomAddress_county: string;
  qaCustomAddress_country: string;
  qaCustomAddress_postCode: string;

  qaDriverAddress_name: string;
  qaDriverAddress_company: string;
  qaDriverAddress_email: string;
  qaDriverAddress_phone: string;
  qaDriverAddress_line1: string;
  qaDriverAddress_line2: string;
  qaDriverAddress_locality: string;
  qaDriverAddress_town: string;
  qaDriverAddress_county: string;
  qaDriverAddress_country: string;
  qaDriverAddress_postCode: string;

  titleCreateOrder: string;
  titleShipping: string;
  titleOrders: string;
  titleContactDetails: string;
  titleAddress: string;

  messageOrdersEmpty: string;

  validationOptional: string;
  validationRequired: string;
  validationWeekday: string;
  validationMinShipDate: string;
  validationInvalidDate: string;
  validationInvalidEmail: string;
  validationCountryCode: string;

  placeholderSelectProducts: string;
  placeholderSelectAccessories: string;

  labelPackage: string;
  labelProducts: string;
  labelAccessories: string;
  labelPreferredShipmentDate: string;
  labelCustomAddress: string;
  labelDriverAddress: string;
  labelName: string;
  labelPhone: string;
  labelEmail: string;
  labelCompany: string;
  labelLine1: string;
  labelLine2: string;
  labelTown: string;
  labelLocality: string;
  labelCounty: string;
  labelCountry: string;
  labelPostcode: string;

  alertTitle: string;
  alertMessage1: string;
  alertMessage2: string;

  buttonOrderHardware: string;
  buttonStopReorder: string;
  buttonStartReorder: string;

  isLoading: boolean;
  isProcessingOrder: boolean;
  isOrderingEnabled: boolean;
  showOrderForm: boolean;
  showOrders: boolean;
  showStartReorderButton: boolean;
  showStopReorderButton: boolean;

  minShipmentDays: number;
  refresh: boolean;
}

export interface OrderViewVm {
  id: string;
  // labels
  messageAwaitingFulfilment: string;
  labelSummary: string;
  labelFulfilment: string;
  labelShippingAddress: string;
  labelShippedDate: string;
  labelPreferredShipmentDate: string;
  labelHardware: string;
  labelPackage: string;
  labelFoC: string;

  labelOrderNumber: string;
  labelOrderStatus: string;
  labelItems: string;
  labelCarrier: string;
  labelTracking: string;
  labelExternalError: string;

  // buttons
  buttonCancelOrder: string;

  // summary
  summaryAddress: string;
  summaryPreferredShipDate: string;
  summaryHardwareOrdered: string;
  summaryDefaultPackage: string;
  summaryStatus: string;
  showPackage: boolean;
  isOptimistic: boolean;
  isCancelling: boolean;
  isCancelled: boolean;

  // fulfilment
  hasFulfilmentParts: boolean;
  canCancel: boolean;
  fulfilmentParts: OrderFulfilment[];
  // hooks
  qaCancelOrderButton: string;
}

export interface OrderHistoryVm {
  titleHistory: string;
  numberOfOrders: string;
  thCreated: string;
  thItems: string;
  thShipping: string;
  thStatus: string;
  orders: OrderHistoryItemVm[];
}

export interface OrderHistoryItemVm {
  id: string;
  isActive: boolean;
  createdOnDisplay: string;
  itemNameList?: string;
  orderStatus: ResourceOrderStatus;
  orderStatusDisplay: string;
  inProgress: boolean;
  requestedShippingDate: string;
  isOptimistic: boolean;
  qaViewOrderAction: string;
}

// Domain Models
export interface MyOrdersDm {
  installationPackage?: ProductLineItem;
  packageName: string;
  packageId: string;
  products: ProductLineItem[];
  accessories: ProductLineItem[];
  minShipmentDate: NgbDateStruct;
  orders: Order[] | null;
  address: { [key: string]: ShippingAddress };
  isLoading: boolean;
  i18n: MyOrdersI18n;
  driverName: string;
  isProcessingOrder: boolean;
  needsRefresh: boolean;
  activeOrderId: string;
  orderForm: FormGroup;
  canReorder: boolean;
  showOrderForm: boolean;
}

export interface OrderViewDm {
  id?: string;
  i18n: MyOrdersI18n;
  summary: OrderSummary;
  fulfilments: OrderFulfilment[];
  isOptimistic: boolean;
  isCancelling: boolean;
}

export interface OrderHistoryDm {
  i18n: MyOrdersI18n;
  orders: Order[];
  isOptimistic: boolean;
}

// Data Transfer Object Models
export interface CreateOrderDto {
  orderRef?: string;
  requestedShippingDate: string;
  shippingAddress: ShippingAddress;
  items: OrderLineItem[];
}

export interface CancelOrderDto {
  orderStatus: ResourceOrderStatus.CANCELLATION_REQUESTED;
}

// Partials
export interface Order {
  summary: OrderSummary;
  fulfilments?: OrderFulfilment[];
  id?: string;
  isActive: boolean;
}

export interface ShippingAddress {
  name: string;
  company?: string;
  phone: string;
  email: string;
  line1: string;
  line2?: string;
  locality?: string;
  town: string;
  county: string;
  country: string;
  postCode: string;
  countryCode: string;
}

export interface CustomerShippingAddress extends ShippingAddress {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface TenantShippingAddress extends ShippingAddress {
  name: string;
  company: string;
  email: string;
  phone: string;
}

export interface ProductResource {
  type: string;
  options: ProductLineItem[];
}

export interface OrderResource {
  type: string;
  id: string;
  summary: OrderResourceSummary;
  fulfilments?: OrderFulfilment[];
}

interface OrderResourceSummary {
  createdOn: number;
  items: OrderLineItem[];
  orderRef?: string;
  orderStatus: ResourceOrderStatus;
  orderStatusDisplay: string;
  requestedShippingDate: string;
  shippingAddress: ShippingAddress;
}

interface OrderSummary {
  createdOn: number;
  createdOnDisplay: string;
  items: OrderLineItem[];
  itemNameList?: string;
  orderStatus: ResourceOrderStatus;
  orderStatusDisplay: string;
  requestedShippingDate: string;
  shippingAddress: ShippingAddress;
  displayAddress?: string;
}

export interface OrderFulfilment {
  id: string;
  title?: string;
  itemsDisplay?: string;
  fulfilmentOrderId: string;
  fulfilmentOrderNumber: string;
  fulfilmentOrderStatus: OrderPartStatus;
  fulfilmentOrderStatusDisplay: string;
  externalErrorCode?: string;
  showPendingItems: boolean;
  shipments: FulfilmentShipment[];
  isCancellable: boolean;
}

export interface FulfilmentShipment {
  carrier: string;
  externalShipmentId: string;
  items: FulfilmentShipmentItem[];
  itemsDisplay?: string;
  pendingItemsDisplay?: string;
  shippedDate: string;
  status: string;
  trackingReference: string;
}

export interface FulfilmentShipmentItem {
  orderedQty: number;
  shippedQty: number;
  productId: string;
  productName: string;
}

export interface ProductLineItem {
  code: string;
  description: string;
  id: string;
  name: string;
  default?: boolean;
  listPrice?: number;
  metadata?: {
    [key: string]: string;
  };
}

export interface OrderLineItem {
  category?: string;
  productId: string;
  productDescription?: string;
  productCode: string;
  productName: string;
  productMetadata?: {
    [key: string]: string;
  };
  qty: number;
}

export interface MyOrdersI18n {
  title: i18nObject;
  table: i18nObject;
  message: i18nObject;
  validation: i18nObject;
  label: i18nObject;
  alert: i18nObject;
  toast: i18nObject;
  modal: i18nObject;
  button: i18nObject;
  placeholder: i18nObject;
  orderStatus: i18nObject;
  partStatus: i18nObject;
}

interface i18nObject {
  [key: string]: string;
}

// Constants
export const MY_ORDERS_EMPTY_I18N: MyOrdersI18n = {
  title: {},
  table: {},
  message: {},
  validation: {},
  label: {},
  alert: {},
  toast: {},
  modal: {},
  button: {},
  placeholder: {},
  orderStatus: {},
  partStatus: {}
};

export const TEMP_ORDER_ID = 'tempOrderId';

export enum ProductCategory {
  Installation = 'Installation',
  Station = 'Station',
  Fru = 'FRU'
}

export enum SelectedAddressType {
  custom = 'custom',
  driver = 'driver'
}

export enum ErrorType {
  createOrder = 'hardwareOrdering.error.createOrder',
  cancelOrder = 'hardwareOrdering.error.cancelOrder',
  missingProducts = 'hardwareOrdering.error.missingProducts',
  unknown = 'hardwareOrdering.error.unknown'
}

export enum ResourceOrderStatus {
  CREATED = 'CREATED',
  AWAITING_FULFILMENT = 'AWAITING_FULFILMENT',
  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  SHIPPING = 'SHIPPING',
  COMPLETED = 'COMPLETED',
  AWAITING_UPDATE = 'AWAITING_UPDATE'
}

export enum OrderPartStatus {
  PENDING_APPROVAL = 'Pending Approval',
  PENDING_FULFILMENT = 'Pending Fulfilment',
  PARTIALLY_FULFILLED = 'Partially Fulfilled',
  SHIPPING = 'Shipping',
  SHIPPED = 'Shipped',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed'
}

export const ALLOW_ORDER_STATUS_LIST = [ResourceOrderStatus.CANCELLED, ResourceOrderStatus.FAILED];
