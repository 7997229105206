import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  DateChangeType,
  DateTimeChangeEvent,
  ErrorType,
  RemoveTradespersonSlotEvent,
  ScheduleError,
  ScheduleI18n,
  ScheduleJobsDisplayDm,
  SchedulePayloadPostDto,
  SelectedJobDetailsDm,
  SelectedJobDetailsFormChangeType,
  SelectedJobDetailsToggleDm,
  TradesPerson,
  TradesPersonSlot,
  TradesPersonVm
} from './schedule.model';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JOB_STATUSES_V2, TEMP_EVENT_ID, TIME_SLOTS } from './utils/schedule-constants';
import { HttpGateway } from '../../core/http-gateway.service';
import { TranslocoService } from '@ngneat/transloco';
import { Router } from '@angular/router';
import { ToasterService } from '../../toast/toast-service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AggregatorReadOnlyService } from '../../project-detail/aggregator-read-only.service';
import { UserService } from '../../auth/services/user.service';
import { EventInfo, JobInfo } from './utils/schedule-types';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { Job, JobAssignment, JobAssignmentType } from '../../core/domain/job';
import { environment } from '../../../environments/environment';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { pad, trimEventIdSuffixes } from './utils/schedule.helper';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';

@Injectable({ providedIn: 'root' })
export class ScheduleRepositoryV3 {
  readyToScheduleSubscription: Subscription;
  errorsSubscription: Subscription;
  selectedJobDetailsSubscription: Subscription;
  selectedJobDetailsFormSubscription: Subscription;
  detailsToggleSubscription: Subscription;
  slotFormSubscriptions: Subscription[] = [];

  errors$: Subject<ScheduleError>;
  rsj$: Subject<ScheduleJobsDisplayDm>;
  selectedJobDetails$: Subject<SelectedJobDetailsDm>;
  dt$: Subject<SelectedJobDetailsToggleDm>;

  dm: ScheduleJobsDisplayDm;
  cachedDm: ScheduleJobsDisplayDm;
  allTradespeopleList: any;
  filteredTradespeopleList: any;
  isReadonlyAggregator = false;
  isReadonlyForm = false;
  scheduleableStatuses = ['SCHEDULED', 'PROVISIONALLY_SCHEDULED'];

  selectedJobDetailsDm: SelectedJobDetailsDm = {} as SelectedJobDetailsDm;

  selectedJobDetailsForm: FormGroup;
  addTradespersonForm: FormGroup;
  filtersForm: FormGroup;

  i18n: ScheduleI18n = {};
  timeSlots = TIME_SLOTS;

  formChangeCallback;
  selectedCalendarEvent;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private fb: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private readOnlyProjectService: AggregatorReadOnlyService,
    private userService: UserService
  ) {
    this.init().then();
  }

  async init(): Promise<void> {
    this.rsj$ = new Subject<ScheduleJobsDisplayDm>();
    this.errors$ = new Subject<ScheduleError>();
    this.selectedJobDetails$ = new Subject<SelectedJobDetailsDm>();
    this.dt$ = new Subject<SelectedJobDetailsToggleDm>();
    this.initI18ns();
    this.initForms();
    this.isReadonlyAggregator = this.readOnlyProjectService.isReadOnly();
    await this.initDataLists();
  }

  initForms(jobInformation?: JobInfo, resetFilters = true): void {
    // When dragging or assigning new jobs we want to default the time to 9am
    const assignmentStartTime = JumptechDate.from(new Date(new Date().setHours(9, 0, 0, 0)));
    const assignmentEndTime = assignmentStartTime.plus({ hours: jobInformation?.defaultDuration ?? 2 });
    const fullJobStart = jobInformation?.fullJobStart ?? jobInformation?.startDateTimestamp;
    const fullJobEnd = jobInformation?.fullJobEnd ?? jobInformation?.endDateTimestamp;
    const formStartTime = jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentStartTime.toIso())
      : this.isoStringToTime(fullJobStart);
    const formEndTime = jobInformation?.setDefaultStartTime
      ? this.isoStringToTime(assignmentEndTime.toIso())
      : this.isoStringToTime(fullJobEnd);

    this.selectedJobDetailsForm = this.fb.group({
      id: [jobInformation?.actionId ?? null],
      fullJob: this.fb.group({
        startIso: [fullJobStart, [Validators.required]],
        startDate: [fullJobStart ? this.isoStringToDateStruct(fullJobStart) : null, [Validators.required]],
        endIso: [fullJobEnd, [Validators.required]],
        endDate: [fullJobEnd ? this.isoStringToDateStruct(fullJobEnd) : null, [Validators.required]],
        startTime: [formStartTime ?? null, [Validators.required]],
        endTime: [formEndTime ?? null, [Validators.required]],
        lastChange: [null]
      }),
      rescheduleReason: [null]
    });

    this.buildTradesPeopleForms(jobInformation);

    this.addTradespersonForm = this.fb.group({
      tradesperson: [null, [Validators.required]]
    });
    if (resetFilters) {
      this.filtersForm = this.fb.group({
        selectedJobTypes: [[]],
        freeText: ['']
      });
    }
  }

  buildTradesPeopleForms(jobInfo: JobInfo): void {
    jobInfo?.tradesPeople.forEach(tp => {
      this.selectedJobDetailsForm.addControl(
        tp.assignedTo,
        this.fb.group({
          assignedTo: [tp.assignedTo, Validators.required],
          assignedToDisplayName: [tp.assignedToDisplayName],
          assignmentId: [tp.assignmentId],
          assignmentType: [tp.assignmentType, Validators.required]
        })
      );

      if (tp.slots.length) {
        // build slot array
        const formArraySlots = [];
        tp.slots.forEach(slot => {
          const slotStartTime = this.isoStringToTime(slot.startDate);
          const slotEndTime = this.isoStringToTime(slot.endDate);

          formArraySlots.push(
            this.fb.group({
              assignedTo: [tp.assignedTo, Validators.required],
              assignedToDisplayName: [tp.assignedToDisplayName],
              assignmentType: [tp.assignmentType, Validators.required],
              assignmentId: [slot.assignmentId],
              // todo constraints around dates validation - no overlapping slots for same resource + bounds of full job + start before end
              startDate: [this.isoStringToDateStruct(slot.startDate), Validators.required],
              endDate: [this.isoStringToDateStruct(slot.endDate), Validators.required],
              startTime: [slotStartTime, Validators.required],
              endTime: [slotEndTime, Validators.required],
              startIso: slot.startDate,
              endIso: slot.endDate,
              lastChange: [null]
            })
          );
        });
        (this.selectedJobDetailsForm.controls[tp.assignedTo] as FormGroup).addControl(
          'slots',
          this.fb.array(formArraySlots)
        );
      }
    });
  }

  load(cb): void {
    this.readyToScheduleSubscription?.unsubscribe();
    this.readyToScheduleSubscription = this.rsj$.subscribe(cb);
    this.loadReadyToScheduleJobs().then();
  }

  loadJobDetails(cb): void {
    this.selectedJobDetailsSubscription?.unsubscribe();
    this.selectedJobDetailsSubscription = this.selectedJobDetails$.subscribe(cb);
  }

  closeJobDetails(success = false, payload = null): void {
    this.slotFormSubscriptions?.forEach(sub => sub.unsubscribe());
    this.selectedJobDetailsFormSubscription?.unsubscribe();
    if (success) {
      this.selectedJobDetailsDm = { scheduleSuccess: true, ...payload } as SelectedJobDetailsDm;
      this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    } else {
      this.selectedJobDetails$.next(null);
    }
  }

  loadDetailsToggle(cb): void {
    this.detailsToggleSubscription?.unsubscribe();
    this.detailsToggleSubscription = this.dt$.subscribe(cb);
  }

  showDetailsToggle(show: boolean): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, hidden: show };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    this.dt$.next({ display: show, hint: this.i18n.toggleSelectedJobHint });
  }

  addTradesperson(): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, isAddingTradesperson: true };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  addSpecificSlot(vm: TradesPersonVm): void {
    // add slot
    // add to form array
    const tradesperson = this.selectedJobDetailsDm.tradesPeople.find(tp => tp.assignedTo == vm.assignedTo);
    const startDate = this.selectedJobDetailsDm.form.get('fullJob').get('startIso').value;
    const endDate = this.selectedJobDetailsDm.form.get('fullJob').get('endIso').value;
    const slotStartTime = this.isoStringToTime(startDate);
    const slotEndTime = this.isoStringToTime(endDate);
    const assignmentId = tradesperson.assignmentId
      ? `${tradesperson.assignmentId}-partial-${tradesperson.slots.length + 1}`
      : `${TEMP_EVENT_ID}-${tradesperson.slots.length + 1}`;

    const newSlot = {
      assignmentId,
      endDate,
      startDate
    };

    let assignmentToUpdate = null;
    // if we don't already have a slot then we need to build the form
    if (!tradesperson.slots.length) {
      (this.selectedJobDetailsForm.get(tradesperson.assignedTo) as FormGroup).addControl('slots', this.fb.array([]));
      // find and update
      assignmentToUpdate = this.selectedJobDetailsDm.jobAssignments.find(
        ja => ja.id === trimEventIdSuffixes(assignmentId)
      );
    }

    tradesperson.slots.push(newSlot);

    const newJobAssignment: JobAssignment = {
      ...newSlot,
      assignedTo: tradesperson.assignedTo,
      assignedToDisplayName: tradesperson.assignedToDisplayName,
      assignmentType: tradesperson.assignmentType
    };

    // update job assignments
    if (assignmentToUpdate) {
      assignmentToUpdate.id = assignmentId;
      assignmentToUpdate.assignedTo = tradesperson.assignedTo;
      assignmentToUpdate.assignedToDisplayName = tradesperson.assignedToDisplayName;
      assignmentToUpdate.assignmentType = tradesperson.assignmentType;
      assignmentToUpdate.endDate = endDate;
      assignmentToUpdate.startDate = startDate;
    } else {
      this.selectedJobDetailsDm = {
        ...this.selectedJobDetailsDm,
        jobAssignments: [...this.selectedJobDetailsDm.jobAssignments, newJobAssignment]
      };
    }

    (this.selectedJobDetailsDm.form.get(vm.assignedTo).get('slots') as FormArray).push(
      this.fb.group({
        assignedTo: [vm.assignedTo, Validators.required],
        assignedToDisplayName: [vm.assignedToDisplayName],
        assignmentType: [vm.assignmentType, Validators.required],
        assignmentId: [assignmentId],
        // todo constraints around dates validation - no overlapping slots for same resource + bounds of full job + start before end
        startDate: [this.isoStringToDateStruct(startDate), Validators.required],
        endDate: [this.isoStringToDateStruct(endDate), Validators.required],
        startTime: [slotStartTime, Validators.required],
        endTime: [slotEndTime, Validators.required],
        startIso: startDate,
        endIso: endDate,
        lastChange: [null]
      })
    );

    const linkId = this.selectedJobDetailsDm.id.split('--')[0];
    const eventId = `${linkId}--${vm.assignedTo}--${assignmentId}`;

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);

    this.formChangeCallback(
      this.selectedJobDetailsDm.form.value,
      { eventId, assignedTo: vm.assignedTo },
      newSlot,
      SelectedJobDetailsFormChangeType.addSlotChange
    );

    this.listenForMoreDetailsSlotFormChanges(
      tradesperson.assignedTo,
      tradesperson.slots.length - 1,
      newSlot,
      this.formChangeCallback,
      this.selectedCalendarEvent
    );
  }

  setLeadTradesperson(tradesperson: TradesPersonVm): void {
    if (tradesperson.assignmentType === 'LEAD') {
      return;
    }

    this.selectedJobDetailsDm.tradesPeople = this.selectedJobDetailsDm.tradesPeople.map(x => {
      const assType: JobAssignmentType = tradesperson.assignedTo === x.assignedTo ? 'LEAD' : 'SUPPORT';
      this.selectedJobDetailsForm.get(x.assignedTo).get('assignmentType').patchValue(assType);

      return {
        ...x,
        assignedTo: x.assignedTo,
        assignedToDisplayName: x.assignedToDisplayName,
        assignmentType: tradesperson.assignedTo === x.assignedTo ? 'LEAD' : 'SUPPORT'
      };
    });

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  confirmDeleteTradesperson(tradesperson: TradesPersonVm): void {
    this.selectedJobDetailsDm.jobAssignments = this.selectedJobDetailsDm.jobAssignments.filter(
      x => x.assignedTo !== tradesperson.assignedTo
    );

    this.selectedJobDetailsDm.tradesPeople = this.selectedJobDetailsDm.tradesPeople.filter(
      tp => tp.assignedTo !== tradesperson.assignedTo
    );

    if (tradesperson.assignmentType === 'LEAD' && this.selectedJobDetailsDm.jobAssignments.length) {
      this.selectedJobDetailsDm.tradesPeople[0].assignmentType = 'LEAD';
    }

    this.selectedJobDetailsForm.patchValue({ jobAssignments: this.selectedJobDetailsDm.jobAssignments });
    this.selectedJobDetailsForm.removeControl(tradesperson.assignedTo);
    this.filterTradespeopleOptions(this.selectedJobDetailsDm.jobAssignments);
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    this.formChangeCallback(
      this.selectedJobDetailsDm.form.value,
      tradesperson,
      null,
      SelectedJobDetailsFormChangeType.removeTradespersonChange
    );
  }

  deleteTradesperson(tradesperson: TradesPersonVm): void {
    // Check if tradesperson has slots
    if (tradesperson.slots.length) {
      let hasPreExistingSlots = false;
      for (const slot of tradesperson.slots) {
        if (!slot.assignmentId.includes('temp') && !slot.assignmentId.includes('partial')) {
          // Pre-existing slot - ask for delete confirmation
          this.confirmTradespersonDeleteDialog(tradesperson);
          hasPreExistingSlots = true;
          break;
        }
      }

      if (!hasPreExistingSlots) {
        this.confirmDeleteTradesperson(tradesperson);
      }
    } else {
      this.confirmDeleteTradesperson(tradesperson);
    }
  }

  confirmTradespersonDeleteDialog(tradesperson: TradesPersonVm) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.componentInstance.config = {
      title: this.i18n.confirmDelete,
      messages: [
        this.i18n.confirmDeleteTradespersonNameMessage.replace('%tp%', tradesperson.assignedToDisplayName),
        this.i18n.confirmDeleteTradespersonAllTimeslotsMessage
      ],
      confirm: this.i18n.confirmDelete,
      cancel: this.i18n.cancelBtn
    };
    modalRef.result
      .then(() => {
        this.confirmDeleteTradesperson(tradesperson);
      })
      .catch(() => {
        // Modal closed
      });
  }

  confirmTradespersonSlotDeleteDialog(tradesperson: TradesPerson, slot: TradesPersonSlot, slotIndex: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    const formattedStartDate = JumptechDate.from(slot.startDate).toExportDateTimeFormat();
    const formattedEndDate = JumptechDate.from(slot.endDate).toExportDateTimeFormat();
    modalRef.componentInstance.config = {
      title: this.i18n.confirmTimeslotDelete,
      messages: [
        this.i18n.confirmDeleteTradespersonNameMessage.replace('%tp%', tradesperson.assignedToDisplayName),
        this.i18n.confirmDeleteTradespersonSlotMessage
          .replace('%sd%', formattedStartDate)
          .replace('%ed%', formattedEndDate)
      ],
      confirm: this.i18n.confirmTimeslotDelete,
      cancel: this.i18n.cancelBtn
    };
    modalRef.result
      .then(() => {
        this.confirmDeleteTradespersonSlot(tradesperson, slotIndex, slot);
      })
      .catch(() => {
        // Modal closed
      });
  }

  deleteTradespersonSlot(event: RemoveTradespersonSlotEvent): void {
    // Check if it's a pre-existing slot
    const matchingTradesperson = this.selectedJobDetailsDm.tradesPeople.find(
      tp => tp.assignedTo === event.tradespersonId
    );
    const matchingSlot = matchingTradesperson.slots[event.index];
    if (matchingSlot.assignmentId.includes('temp') || matchingSlot.assignmentId.includes('partial')) {
      // Not pre-existing - proceed with slot delete
      this.confirmDeleteTradespersonSlot(matchingTradesperson, event.index, matchingSlot);
    } else {
      this.confirmTradespersonSlotDeleteDialog(matchingTradesperson, matchingSlot, event.index);
    }
  }

  confirmDeleteTradespersonSlot(tradesperson: TradesPerson, slotIndex: number, slot: TradesPersonSlot) {
    tradesperson.slots.splice(slotIndex, 1);
    (this.selectedJobDetailsForm.get(tradesperson.assignedTo).get('slots') as FormArray).removeAt(slotIndex);

    if (!tradesperson.slots.length) {
      const assignment = this.selectedJobDetailsDm.jobAssignments.find(ja => ja.id == slot.assignmentId);
      if (assignment) {
        delete assignment.start;
        delete assignment.startDate;
        delete assignment.end;
        delete assignment.endDate;
      }
    } else {
      this.selectedJobDetailsDm.jobAssignments = this.selectedJobDetailsDm.jobAssignments.filter(
        ja => ja.id !== slot.assignmentId
      );
    }

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);

    this.formChangeCallback(
      this.selectedJobDetailsDm.form.value,
      tradesperson,
      slot,
      SelectedJobDetailsFormChangeType.removeTradespersonSlotChange
    );
  }

  filterTradespeopleOptions(jobAssignments: JobAssignment[]): void {
    const alreadyAssignedList = jobAssignments.map(a => a.assignedTo);
    this.filteredTradespeopleList = this.allTradespeopleList.filter(x => {
      return !alreadyAssignedList.includes(x.id);
    });
    this.selectedJobDetailsDm.tradespeopleList = this.filteredTradespeopleList;
  }

  confirmAddTradesperson(): void {
    const engineerData = this.selectedJobDetailsDm.addTradespersonForm.get('tradesperson').value;
    const newJobAssignment: JobAssignment = {
      assignedTo: engineerData.id,
      assignedToDisplayName: engineerData.name,
      assignmentType: this.selectedJobDetailsDm.jobAssignments?.length ? 'SUPPORT' : 'LEAD'
    };

    // update job assignments
    this.selectedJobDetailsDm = {
      ...this.selectedJobDetailsDm,
      jobAssignments: [...this.selectedJobDetailsDm.jobAssignments, newJobAssignment]
    };
    this.selectedJobDetailsDm.tradesPeople = this.buildTradesPeopleFromAssignments(
      this.selectedJobDetailsDm.jobAssignments
    );

    this.addTradespersonToForm(newJobAssignment);
    this.resetAddTradespersonForm();
    this.filterTradespeopleOptions(this.selectedJobDetailsDm.jobAssignments); // check
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    this.formChangeCallback(
      this.selectedJobDetailsDm.form.value,
      newJobAssignment,
      null,
      SelectedJobDetailsFormChangeType.addTradespersonChange
    );
  }

  private addTradespersonToForm(person: JobAssignment): void {
    this.selectedJobDetailsForm.addControl(
      person.assignedTo,
      this.fb.group({
        assignedTo: [person.assignedTo, Validators.required],
        assignedToDisplayName: [person.assignedToDisplayName],
        assignmentId: [person.id], // todo temp id
        assignmentType: [person.assignmentType, Validators.required]
      })
    );
  }

  cancelAddTradesperson(): void {
    this.resetAddTradespersonForm();
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  resetAddTradespersonForm(): void {
    this.selectedJobDetailsDm.addTradespersonForm.patchValue({ tradesperson: null });
    this.selectedJobDetailsDm.isAddingTradesperson = false;
  }

  async loadReadyToScheduleJobs(showloader = true, scheduledJobId: string = null): Promise<void> {
    try {
      // notify loading state
      if (showloader) {
        this.dm = {
          loading: true,
          jobs: [],
          jobTypes: [],
          filtersForm: this.filtersForm,
          i18ns: this.i18n
        };
        this.rsj$.next(this.dm);
      }

      const dto: Job[] = [];
      let currentNextPageToken: string | undefined;

      const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch();
      const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

      dto.push(...unscheduledJobs);
      currentNextPageToken = nextPageToken;

      this.parseAndNotify(dto);

      while (currentNextPageToken) {
        const { results, nextPageToken } = await this.getReadyToScheduleJobsBatch(currentNextPageToken);
        const unscheduledJobs = this.removeRecentlyScheduledJob(results, scheduledJobId);

        dto.push(...unscheduledJobs);
        currentNextPageToken = nextPageToken;

        this.parseAndNotify(dto);
      }
    } catch (e) {
      this.handleErrors(ErrorType.fetchReadyToScheduleJobs, e);
    }
  }

  private removeRecentlyScheduledJob(jobs: Job[], scheduledJobId: string = null) {
    if (!scheduledJobId) {
      return jobs;
    }
    // remove recently scheduled job from list, so we don't have to retry or make user wait too long
    return jobs.filter(({ id }) => id !== scheduledJobId);
  }

  private async getReadyToScheduleJobsBatch(
    nextPageToken?: string
  ): Promise<{ results: Job[]; nextPageToken?: string }> {
    const params = { readyToSchedule: true, isScheduled: false };

    if (nextPageToken !== undefined) {
      params['nextPageToken'] = nextPageToken;
    }

    return await this.gateway.get(`${environment.apiJobsUrl}`, params);
  }

  public getErrors(cb): void {
    this.errorsSubscription?.unsubscribe();
    this.errorsSubscription = this.errors$.subscribe(cb);
  }

  public clearErrors(): void {
    this.errors$.next({} as ScheduleError);
  }

  private setError(e: ErrorType): void {
    const message = this.i18nService.translate(e);
    this.errors$.next({
      message,
      qaErrorMessage: 'scheduleErrorMessage',
      qaClearErrorsButton: 'scheduleClearErrorsButton'
    });
  }

  private parseAndNotify(dto: Job[]): void {
    const jobTypes = this.buildJobTypesFilter(dto);

    const updatedDm: ScheduleJobsDisplayDm = {
      ...this.dm,
      loading: false,
      jobs: dto,
      filtersForm: this.filtersForm,
      jobTypes,
      i18ns: this.i18n
    };

    this.cachedDm = { ...updatedDm };
    this.filterReadyToSchedule();
  }

  public setSelectedJob(job: Job): void {
    this.dm = { ...this.dm, selectedJob: job };
    this.cachedDm = { ...this.dm };
    this.rsj$.next(this.dm);
  }

  private buildJobTypesFilter(dto: Job[]): DropDownElement[] {
    return dto
      .map(job => job.type)
      .filter((value, index, currentValue) => currentValue.indexOf(value) === index)
      .map(x => ({ id: x, name: x }));
  }

  filterReadyToSchedule(): void {
    const selectedJobTypes: string[] = this.filtersForm.get('selectedJobTypes').value;
    const freeText: string = this.filtersForm.get('freeText').value;
    let filteredJobs = this.cachedDm?.jobs;

    if (selectedJobTypes?.length) {
      filteredJobs = this.cachedDm.jobs.filter((job: Job) => selectedJobTypes?.includes(job.type));
    }
    if (freeText) {
      filteredJobs = filteredJobs.filter((job: Job) => {
        return (
          `${job.firstName} ${job.lastName}`.toLowerCase().includes(freeText.toLowerCase()) ||
          job.address?.postCode?.toLowerCase()?.replace(/\s/g, '').includes(freeText?.toLowerCase()?.replace(/\s/g, ''))
        );
      });
    }
    this.dm = {
      loading: false,
      jobs: filteredJobs,
      filtersForm: this.filtersForm,
      jobTypes: this.cachedDm ? this.cachedDm.jobTypes : this.dm.jobTypes,
      selectedJob: this.cachedDm ? this.cachedDm.selectedJob : this.dm.selectedJob,
      i18ns: this.i18n
    };
    this.rsj$.next(this.dm);
  }

  public handleErrors(type: ErrorType | unknown, e): void {
    if (!environment.production) {
      console.log(e);
    }
    switch (type) {
      case ErrorType.fetchReadyToScheduleJobs:
        this.setError(ErrorType.fetchReadyToScheduleJobs);
        this.rsj$.next({ ...this.dm, jobs: [], loading: false, i18ns: this.i18n });
        break;
      case ErrorType.fetchEngineers:
        this.setError(ErrorType.fetchEngineers);
        break;
      case ErrorType.selectRescheduleJob:
        this.setError(ErrorType.selectRescheduleJob);
        break;
      case ErrorType.unknown:
        this.setError(ErrorType.unknown);
        break;
      case ErrorType.scheduleJob:
        this.setError(ErrorType.scheduleJob);
        this.selectedJobDetails$.next({ ...this.selectedJobDetailsDm, scheduleInProgress: false });
        break;
      default:
        this.setError(ErrorType.unknown);
    }
  }

  parseEngineers(dto): DropDownElement[] {
    const engineers = dto.map(x => ({ name: x.key, id: x.value.split('|')[1] }));
    if (AuthenticationService.getTier() === 'support') {
      const currentUser = this.userService.currentUser;
      engineers.push({ name: `${currentUser.label} (Support)`, id: currentUser.id });
    }
    return engineers;
  }

  async fetchEngineers(): Promise<void> {
    try {
      const dto = await this.gateway.get(`${environment.apiCustomRoot}/core/users/engineer`, {});
      this.allTradespeopleList = this.parseEngineers(dto);
    } catch (e) {
      this.handleErrors(ErrorType.fetchEngineers, e);
    }
  }

  listenForMoreDetailsSlotFormChanges(assignedTo: string, slotIdx: number, slot, cb, calEvent: EventInfo): void {
    const slotForm = (this.selectedJobDetailsForm.get(assignedTo).get('slots') as FormArray).at(slotIdx);
    this.slotFormSubscriptions.push(
      slotForm.valueChanges.subscribe(change => {
        cb(change, calEvent, slot);
      })
    );
  }

  listenForMoreDetailsFormChanges(cb, calEvent: EventInfo): void {
    this.selectedJobDetailsFormSubscription?.unsubscribe();
    this.selectedJobDetailsFormSubscription = this.selectedJobDetailsForm
      .get('fullJob')
      .valueChanges.subscribe(change => {
        if (change.startDate && change.endDate && !change.startIso && !change.endIso) {
          this.patchFullJobIsos();
        }
        cb(change, calEvent, null, SelectedJobDetailsFormChangeType.fullJobChange);
      });
  }

  private buildTradesPeopleFromAssignments(jobAssignments: JobAssignment[]): TradesPerson[] {
    const tradesPeople: TradesPerson[] = [];

    jobAssignments.forEach((ja: JobAssignment): void => {
      const tradesPerson: TradesPerson = {
        assignedTo: null,
        assignedToDisplayName: null,
        assignmentType: null,
        slots: []
      };
      // we don't have a tradesperson for this job assignment
      if (!tradesPeople.find(tp => tp.assignedTo === ja.assignedTo)) {
        tradesPerson.assignedTo = ja.assignedTo;
        tradesPerson.assignedToDisplayName = ja.assignedToDisplayName;
        tradesPerson.assignmentType = ja.assignmentType;
        // we don't have specific slot so add the assignment id
        if (!ja.startDate && !ja.endDate) {
          tradesPerson.assignmentId = ja.id;
        } else {
          tradesPerson.slots.push({
            assignmentId: ja.id,
            startDate: ja.startDate,
            endDate: ja.endDate
          });
        }
        tradesPeople.push(tradesPerson);
      } else {
        const existing = tradesPeople.find(tp => tp.assignedTo === ja.assignedTo);
        // add slot to existing
        existing.slots.push({
          assignmentId: ja.id,
          startDate: ja.startDate,
          endDate: ja.endDate
        });
      }
    });

    return tradesPeople;
  }

  private isReadonlyJob(jobInformation: JobInfo): boolean {
    // todo revisit this
    return !JOB_STATUSES_V2['SCHEDULED'].legacyStatuses.includes(jobInformation.status);
  }

  public openJobDetails(jobInformation: JobInfo, cb, calEvent: EventInfo): void {
    this.filterTradespeopleOptions(jobInformation.jobAssignments);
    const tradesPeople = this.buildTradesPeopleFromAssignments(jobInformation.jobAssignments);
    this.isReadonlyForm = this.isReadonlyAggregator || this.isReadonlyJob(jobInformation);
    jobInformation.tradesPeople = tradesPeople;

    this.initForms(jobInformation, false);
    this.formChangeCallback = cb;
    this.selectedCalendarEvent = calEvent;

    if (this.formChangeCallback && calEvent) {
      this.listenForMoreDetailsFormChanges(this.formChangeCallback, calEvent);
    }

    this.slotFormSubscriptions?.forEach((sub: Subscription) => sub.unsubscribe());
    tradesPeople.forEach(tp => {
      tp.slots.forEach((slot, idx) => {
        this.listenForMoreDetailsSlotFormChanges(tp.assignedTo, idx, slot, this.formChangeCallback, calEvent);
      });
    });

    if (jobInformation.tenantType) {
      this.readOnlyProjectService.next(jobInformation.tenantType);
    }

    if (this.isReadonlyForm) {
      this.selectedJobDetailsForm.disable();
    }

    this.selectedJobDetailsDm = {
      context: jobInformation.context,
      readonly: this.isReadonlyForm,
      id: jobInformation.id,
      scheduleSuccess: false,
      type: jobInformation.type,
      projectId: jobInformation.projectId,
      firstName: jobInformation.customerFirstName,
      lastName: jobInformation.customerLastName,
      phoneNumber: jobInformation.contactInfo.telephoneNumber,
      email: jobInformation.contactInfo.email,
      address: jobInformation.address,
      startDate: jobInformation.startDateTimestamp,
      endDate: jobInformation.endDateTimestamp,
      tradespeopleList: this.filteredTradespeopleList,
      jobAssignments: jobInformation.jobAssignments,
      tradesPeople,
      collisions: [],
      form: this.selectedJobDetailsForm,
      addTradespersonForm: this.addTradespersonForm,
      isAddingTradesperson: false,
      scheduleInProgress: false,
      isInitialSchedule: jobInformation.isInitialSchedule,
      enableCollisionCheck: false,
      collisionCheckInProgress: false,
      hidden: false,
      i18nLead: this.i18n.lead,
      i18nConfirmBtn: this.i18n.confirmBtn,
      i18nCancelBtn: this.i18n.cancelBtn,
      i18nCloseBtn: this.i18n.closeBtn,
      i18nStartDatePlaceholder: this.i18n.startDatePlaceholder,
      i18nTradesPeopleHeader: this.i18n.tradesPeopleHeader,
      i18nTradesPeopleSubHeader: this.i18n.tradesPeopleSubHeader,
      i18nTradesPeopleSlotHeader: this.i18n.tradesPeopleSlotHeader,
      i18nStartDateLabel: this.i18n.startDateLabel,
      i18nScheduleNowBtn: this.i18n.scheduleNowBtn,
      i18nGoToProjectBtn: this.i18n.goToProjectBtn,
      i18nStartDateRequired: this.i18n.startDateRequired,
      i18nInvalidDateFormat: this.i18n.invalidDateFormat,
      i18nStartDateAfterEnd: this.i18n.startDateAfterEnd,
      i18nStartTimeLabel: this.i18n.startTimeLabel,
      i18nEndDatePlaceholder: this.i18n.endDatePlaceholder,
      i18nEndDateLabel: this.i18n.endDateLabel,
      i18nEndDateRequired: this.i18n.endDateRequired,
      i18nEndDateBeforeStart: this.i18n.endDateBeforeStart,
      i18nEndTimeLabel: this.i18n.endTimeLabel,
      i18nDurationLabel: this.i18n.durationLabel,
      i18nDayLabel: this.i18n.dayLabel,
      i18nDaysLabel: this.i18n.daysLabel,
      i18nHourLabel: this.i18n.hourLabel,
      i18nHoursLabel: this.i18n.hoursLabel,
      i18nMinutesLabel: this.i18n.minutesLabel,
      i18nAddTradesPersonLabel: this.i18n.addTradesPersonLabel,
      i18nSelectEngineerPlaceholder: this.i18n.selectEngineerPlaceholder,
      i18nProvisionallyScheduleBtn: this.i18n.provisionallyScheduleBtn,
      i18nAddTradesPersonBtn: this.i18n.addTradesPersonBtn,
      i18nSetLeadEngineerBtn: this.i18n.setLeadEngineerBtn,
      i18nRemoveTradespersonBtn: this.i18n.removeTradespersonBtn,
      i18nRescheduleReasonInputLabel: this.i18n.rescheduleReasonInputLabel,
      i18nRescheduleReasonInputPlaceholder: this.i18n.rescheduleReasonInputPlaceholder,
      i18nEngineerRequiredError: this.i18n.engineerRequiredError,
      i18nTimeIsInvalid: this.i18n.timeIsInvalid,
      i18nTimeNotWithinJob: this.i18n.i18nTimeNotWithinJob,
      i18nCheckCollisionsBtn: this.i18n.checkCollisionsBtn,
      i18nAddSpecificTimeBtn: this.i18n.addSpecificTimeBtn
    };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  private initI18ns(): void {
    this.i18n.projectType = this.i18nService.translate('common.projectType');
    this.i18n.jobType = this.i18nService.translate('common.jobType');
    this.i18n.lead = this.i18nService.translate('common.lead');
    this.i18n.nameLabel = this.i18nService.translate('common.name');
    this.i18n.contactInfoLabel = this.i18nService.translate('schedule.jobInformation.contactInfo');
    this.i18n.addressLabel = this.i18nService.translate('common.formFields.address');
    this.i18n.assignedTradespersonLabel = this.i18nService.translate('schedule.jobInformation.assignedTradesperson');
    this.i18n.freeTextFilterLabel = this.i18nService.translate('common.filter');
    this.i18n.jobTypesDropdownPlaceholder = this.i18nService.translate('common.showAll');
    this.i18n.titleJob = this.i18nService.translate('schedule.jobInformation.job');
    this.i18n.titlePostcode = this.i18nService.translate('common.postcode');
    this.i18n.buttonAssign = this.i18nService.translate('common.assign');
    this.i18n.buttonProject = this.i18nService.translate('common.project');
    this.i18n.buttonMoreDetails = this.i18nService.translate('schedule.jobInformation.buttons.moreDetails');
    this.i18n.titleJobsReadyToSchedule = this.i18nService.translate('schedule.jobDisplay.jobsReadyToSchedule');
    this.i18n.titleAllOtherJobs = this.i18nService.translate('schedule.jobDisplay.allOtherJobs');
    this.i18n.titleSelectedJobFromProject = this.i18nService.translate('schedule.jobDisplay.selectedJobFromProjects');
    this.i18n.startDatePlaceholder = this.i18nService.translate('schedule.formFields.startDate.placeholder');
    this.i18n.tradesPeopleHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleHeader');
    this.i18n.tradesPeopleSubHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleSubHeader');
    this.i18n.tradesPeopleSlotHeader = this.i18nService.translate('schedule.moreInfo.tradesPeopleSlotHeader');
    this.i18n.startDateLabel = this.i18nService.translate('schedule.formFields.startDate.label');
    this.i18n.scheduleNowBtn = this.i18nService.translate('schedule.moreInfo.scheduleNowBtn');
    this.i18n.goToProjectBtn = this.i18nService.translate('schedule.jobInformation.buttons.goToProject');
    this.i18n.startDateRequired = this.i18nService.translate('schedule.errors.startDateRequired');
    this.i18n.invalidDateFormat = this.i18nService.translate('schedule.errors.invalidDateFormat');
    this.i18n.startDateAfterEnd = this.i18nService.translate('schedule.errors.startDateAfterEnd');
    this.i18n.startTimeLabel = this.i18nService.translate('schedule.formFields.startTime.label');
    this.i18n.endDatePlaceholder = this.i18nService.translate('schedule.formFields.endDate.placeholder');
    this.i18n.endDateLabel = this.i18nService.translate('schedule.formFields.endDate.label');
    this.i18n.endDateRequired = this.i18nService.translate('schedule.errors.endDateRequired');
    this.i18n.endDateBeforeStart = this.i18nService.translate('schedule.errors.endDateBeforeStart');
    this.i18n.endTimeLabel = this.i18nService.translate('schedule.formFields.endTime.label');
    this.i18n.durationLabel = this.i18nService.translate('schedule.jobInformation.duration');
    this.i18n.dayLabel = this.i18nService.translate('schedule.moreInfo.dayLabel');
    this.i18n.daysLabel = this.i18nService.translate('schedule.moreInfo.daysLabel');
    this.i18n.hourLabel = this.i18nService.translate('schedule.moreInfo.hourLabel');
    this.i18n.hoursLabel = this.i18nService.translate('schedule.moreInfo.hoursLabel');
    this.i18n.minutesLabel = this.i18nService.translate('schedule.moreInfo.minutesLabel');
    this.i18n.addTradesPersonBtn = this.i18nService.translate('schedule.moreInfo.addTradesPersonBtn');
    this.i18n.selectEngineerPlaceholder = this.i18nService.translate('schedule.moreInfo.selectEngineerPlaceholder');
    this.i18n.confirmBtn = this.i18nService.translate('common.confirm');
    this.i18n.cancelBtn = this.i18nService.translate('common.cancel');
    this.i18n.closeBtn = this.i18nService.translate('common.close');
    this.i18n.provisionallyScheduleBtn = this.i18nService.translate('schedule.moreInfo.provisionallyScheduleBtn');
    this.i18n.scheduleJobLabel = this.i18nService.translate('schedule.moreInfo.scheduleJobLabel');
    this.i18n.jobHasBeenScheduledLabel = this.i18nService.translate('schedule.moreInfo.jobHasBeenScheduledLabel');
    this.i18n.setLeadEngineerBtn = this.i18nService.translate('schedule.moreInfo.setLeadEngineerBtn');
    this.i18n.removeTradespersonBtn = this.i18nService.translate('schedule.moreInfo.removeTradespersonBtn');
    this.i18n.freeTextFilterPlaceholder = this.i18nService.translate('schedule.jobDisplay.freeTextFilterPlaceholder');
    this.i18n.rescheduleReasonInputPlaceholder = this.i18nService.translate(
      'schedule.moreInfo.rescheduleReasonInputPlaceholder'
    );
    this.i18n.rescheduleReasonInputLabel = this.i18nService.translate('schedule.moreInfo.rescheduleReasonInputLabel');
    this.i18n.engineerRequiredError = this.i18nService.translate('schedule.moreInfo.engineerRequiredError');
    this.i18n.timeIsInvalid = this.i18nService.translate('schedule.moreInfo.timeIsInvalid');
    this.i18n.i18nTimeNotWithinJob = this.i18nService.translate('schedule.moreInfo.timeNotWithinJob');
    this.i18n.toggleSelectedJobHint = this.i18nService.translate('schedule.moreInfo.toggleHint');
    this.i18n.checkCollisionsBtn = this.i18nService.translate('schedule.moreInfo.checkCollisionsBtn');
    this.i18n.addSpecificTimeBtn = this.i18nService.translate('schedule.moreInfo.addSpecificTimeBtn');
    this.i18n.confirmDelete = this.i18nService.translate('schedule.selectedJobDetails.confirmDelete');
    this.i18n.confirmTimeslotDelete = this.i18nService.translate('schedule.selectedJobDetails.confirmTimeslotDelete');
    this.i18n.confirmDeleteTradespersonNameMessage = this.i18nService.translate(
      'schedule.selectedJobDetails.confirmDeleteTradespersonNameMessage'
    );
    this.i18n.confirmDeleteTradespersonAllTimeslotsMessage = this.i18nService.translate(
      'schedule.selectedJobDetails.confirmDeleteTradespersonAllTimeslotsMessage'
    );
    this.i18n.confirmDeleteTradespersonSlotMessage = this.i18nService.translate(
      'schedule.selectedJobDetails.confirmDeleteTradespersonSlotMessage'
    );
  }

  private async initDataLists(): Promise<void> {
    await this.fetchEngineers();
  }

  isoStringToDateStruct(isoString: string): NgbDateStruct {
    if (isoString) {
      const date = new Date(isoString);
      return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      };
    }
  }

  isoStringToTime(isoString: string): string {
    if (isoString) {
      const date = new Date(isoString);
      const hrs = pad(date.getHours());
      const mins = pad(date.getMinutes());
      return this.timeSlots.find(slot => slot.id === hrs + mins).name;
    }
  }

  patchFullJobIsos(): void {
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob') as FormGroup;

    const startDate = fullJobForm.get('startDate').value;
    const startTime = fullJobForm.get('startTime').value;
    const start = JumptechDate.from({
      year: startDate.year,
      month: startDate.month,
      day: startDate.day,
      hour: startTime.split(':')[0],
      minute: startTime.split(':')[1]
    }).toIso();
    fullJobForm.patchValue({ startIso: start });

    const endDate = fullJobForm.get('endDate').value;
    const endTime = fullJobForm.get('endTime').value;
    const end = JumptechDate.from({
      year: endDate.year,
      month: endDate.month,
      day: endDate.day,
      hour: endTime.split(':')[0],
      minute: endTime.split(':')[1]
    }).toIso();

    fullJobForm.patchValue({ startIso: start, endIso: end });
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  handleDateTimeChange(type: DateChangeType): void {
    if (this.selectedJobDetailsForm.get('fullJob').invalid) {
      return;
    }
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob') as FormGroup;
    if (type === 'start') {
      const startDate = fullJobForm.get('startDate').value;
      const startTime = fullJobForm.get('startTime').value;
      const start = JumptechDate.from({
        year: startDate.year,
        month: startDate.month,
        day: startDate.day,
        hour: startTime.split(':')[0],
        minute: startTime.split(':')[1]
      }).toIso();
      fullJobForm.patchValue({ startIso: start, lastChange: type });
    } else {
      const endDate = fullJobForm.get('endDate').value;
      const endTime = fullJobForm.get('endTime').value;
      const end = JumptechDate.from({
        year: endDate.year,
        month: endDate.month,
        day: endDate.day,
        hour: endTime.split(':')[0],
        minute: endTime.split(':')[1]
      }).toIso();
      fullJobForm.patchValue({ endIso: end, lastChange: type });
    }
    // todo fix the below
    // if (!startDate || !endDate || !startTime || !endTime) {
    //   this.selectedJobDetailsForm.patchValue({ startIso: null, endIso: null });
    //   this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    //   return;
    // }

    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  handleSlotDateTimeChange(slotDateChangeEvent: DateTimeChangeEvent): void {
    // todo maybe we can have a single dateTime change handler
    const slotForm = (
      this.selectedJobDetailsDm.form.get(slotDateChangeEvent.tradespersonId).get('slots') as FormArray
    ).at(slotDateChangeEvent.index);

    if (slotForm.invalid) {
      return;
    }

    const startDate = slotForm.get('startDate').value;
    const endDate = slotForm.get('endDate').value;
    const startTime = slotForm.get('startTime').value;
    const endTime = slotForm.get('endTime').value;

    const startIso = JumptechDate.from({
      year: startDate.year,
      month: startDate.month,
      day: startDate.day,
      hour: startTime.split(':')[0],
      minute: startTime.split(':')[1]
    }).toIso();
    const endIso = JumptechDate.from({
      year: endDate.year,
      month: endDate.month,
      day: endDate.day,
      hour: endTime.split(':')[0],
      minute: endTime.split(':')[1]
    }).toIso();

    slotForm.patchValue({ startIso, endIso, lastChange: slotDateChangeEvent.type });
    // update the dm
    const person = this.selectedJobDetailsDm.tradesPeople.find(
      tp => tp.assignedTo === slotDateChangeEvent.tradespersonId
    );
    const thisSlot = person.slots[slotDateChangeEvent.index];
    thisSlot.startDate = startIso;
    thisSlot.endDate = endIso;
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);
  }

  checkCollisions(): void {
    this.selectedJobDetailsDm = { ...this.selectedJobDetailsDm, collisionCheckInProgress: true };
    this.selectedJobDetails$.next(this.selectedJobDetailsDm);

    // mock collision check API call
    setTimeout(() => {
      // no collisions detected
      this.selectedJobDetailsDm = {
        ...this.selectedJobDetailsDm,
        collisionCheckInProgress: false,
        collisions: [],
        enableCollisionCheck: false
      };
      this.selectedJobDetails$.next(this.selectedJobDetailsDm);
    }, 2000); // todo
  }

  private buildPayload(): SchedulePayloadPostDto {
    const assignments: JobAssignment[] = [];
    const fullJobForm = this.selectedJobDetailsForm.get('fullJob');
    this.selectedJobDetailsDm.tradesPeople.forEach(tp => {
      if (tp.slots.length) {
        tp.slots.forEach(slot => {
          const jobAssignment: JobAssignment = {
            start: slot.startDate,
            startDate: slot.startDate,
            end: slot.endDate,
            endDate: slot.endDate,
            assignedTo: tp.assignedTo,
            assignedToDisplayName: tp.assignedToDisplayName,
            assignmentType: tp.assignmentType
          };
          if (slot.assignmentId && !slot.assignmentId.includes(TEMP_EVENT_ID)) {
            jobAssignment.id = trimEventIdSuffixes(slot.assignmentId);
          }
          assignments.push(jobAssignment);
        });
      } else {
        const jobAssignment: JobAssignment = {
          assignedTo: tp.assignedTo,
          assignedToDisplayName: tp.assignedToDisplayName,
          assignmentType: tp.assignmentType
        };
        if (tp.assignmentId && !tp.assignmentId.includes(TEMP_EVENT_ID)) {
          jobAssignment.id = trimEventIdSuffixes(tp.assignmentId);
        }
        assignments.push(jobAssignment);
      }
    });

    return {
      id: this.selectedJobDetailsForm.get('id').value,
      start: fullJobForm.get('startIso').value,
      end: fullJobForm.get('endIso').value,
      jobType: this.selectedJobDetailsDm.type,
      jobAssignments: assignments,
      rescheduleReason: this.selectedJobDetailsForm.get('rescheduleReason')?.value ?? ''
    };
  }

  async scheduleJob(): Promise<void> {
    const payload = this.buildPayload();

    this.selectedJobDetails$.next({ ...this.selectedJobDetailsDm, scheduleInProgress: true });
    try {
      const headers = {
        'x-jt-project-id': this.selectedJobDetailsDm.projectId
      };
      await this.gateway.post(
        `${environment.apiProjectUrl}/${this.selectedJobDetailsDm.projectId}/schedule`,
        payload,
        headers,
        { ignoreState: 'true' }
      );
      this.toasterService.pop('success', this.i18n.scheduleJobLabel, this.i18n.jobHasBeenScheduledLabel);
      await this.optimisticUpdateJobsList(this.selectedJobDetailsDm.id);
      this.closeJobDetails(true, { ...payload, projectId: this.selectedJobDetailsDm.projectId });
      this.modalService.dismissAll();
    } catch (e) {
      this.handleErrors(ErrorType.scheduleJob, e);
    }
  }

  async optimisticUpdateJobsList(jobId): Promise<void> {
    if (this.cachedDm.jobs && this.cachedDm.jobs.length) {
      const updated = { ...this.cachedDm, jobs: this.dm?.jobs?.filter(j => j.id !== jobId) };
      this.rsj$.next(updated);
      await this.loadReadyToScheduleJobs(false, jobId);
    }
  }

  goToProject(id?: string): void {
    const projectId: string = id ?? this.selectedJobDetailsDm.projectId;
    this.modalService.dismissAll();
    this.router.navigate([`/project/${projectId}`]).catch(console.log);
  }
}
