import { AfterContentInit, Component, Input, ViewChild } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MapMarker, GoogleMapsModule } from '@angular/google-maps';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { NgIf, NgFor } from '@angular/common';

export interface MultiMarker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
  threeWords?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'crds-question-google-maps-multi-marker',
  template: `
    <!-- QUESTION HINT -->
    <question-hint *ngIf="question.hint" [question]="question" class="mb-2"></question-hint>
    <div class="outer-wrapper">
      <div class="map-wrapper" id="wrapper" [attr.data-qa]="question.key" #wrapper>
        <google-map
          class="google-map"
          height="100%"
          width="100%"
          [center]="{lat, lng}"
          [zoom]="zoom"
          [mapTypeId]="mapStyle"
          (mapClick)="mapClicked($event)"
          [options]="mapOptions"
        >
          <map-marker
            #marker="mapMarker"
            *ngFor="let m of markers; let i = index"
            (mapClick)="removeMarker(i)"
            [position]="{ lat: m.lat, lng: m.lng }"
            [label]="m.label ?? ''"
            [draggable]="m.draggable"
            (mapDragend)="markerDragEnd(m, $event)"
          >
          </map-marker>
        </google-map>
      </div>
    </div>
  `,
  styles: [
    `
      .map-wrapper {
        height: 300px;
      }

      .google-map {
        margin: 0;
        padding: 0;
      }

      :host ::ng-deep google-map > div {
        margin-top: 0.75rem;
      }

      .outer-wrapper {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
      }

      .action-button > span {
        line-height: 2rem;
        padding-right: 0.5rem;
        vertical-align: middle;
      }
      .action-button > .material-icons {
        vertical-align: middle;
      }
    `
  ],
  standalone: true,
  imports: [NgIf, QuestionHintComponent, GoogleMapsModule, NgFor]
})
export class GoogleMapsMultiMarkerQuestionComponent implements AfterContentInit {
  @Input() form: UntypedFormGroup;
  @Input() question: QuestionBase<any>;

  @ViewChild(MapMarker) mapMarker: MapMarker;

  // google maps zoom level
  zoom = 18;

  // initial center position for the map
  lat: number;
  lng: number;
  markers: MultiMarker[] = [];
  addressFormControl: UntypedFormControl | null;
  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true
  };

  get mapStyle(): google.maps.MapTypeId {
    return (this.question.mapsConfig && this.question.mapsConfig.mapStyle) || google.maps.MapTypeId.HYBRID;
  }

  findAddressFormControl() {
    if (this.form.get('address') && this.form.get('address')?.value) {
      return this.form.get('address');
    }
    // check the parent form for an address in case we don't have the address as part of this formGroup
    const parentForm = this.form.parent;
    if (parentForm) {
      const parentAddressForm = parentForm.get('address.address');
      if (parentAddressForm && parentAddressForm.value) {
        return parentAddressForm;
      }
    }
    return null;
  }

  ngAfterContentInit() {
    this.addressFormControl = this.findAddressFormControl() as UntypedFormControl;

    if (this.addressFormControl && this.addressFormControl.value) {
      this.lat = parseFloat(this.addressFormControl.value.latitude);
      this.lng = parseFloat(this.addressFormControl.value.longitude);
    }

    if (!this.lat) {
      const markers = this.form.get(this.question.key)?.value as MultiMarker[];
      if (markers && markers.length > 0) {
        this.lat = markers[0].lat;
        this.lng = markers[0].lng;
      }
    }
    if (!this.lat) {
      this.setCurrentLocation();
    }
    this.markers = this.form?.get(this.question.key)?.value;
  }

  private addMarker(marker: MultiMarker) {
    if (!this.markers) {
      this.markers = [];
    }
    this.markers.push(marker);
    this.form?.get(this.question.key)?.patchValue(this.markers);
  }

  removeMarker(i: number) {
    this.markers.splice(i, 1);
    this.form?.get(this.question.key)?.patchValue(this.markers);
  }

  async mapClicked($event: google.maps.MapMouseEvent) {
    const marker = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
      draggable: true
    };
    this.addMarker(marker);
  }

  async markerDragEnd(marker: MultiMarker, $event: google.maps.MapMouseEvent) {
    marker.lat = $event.latLng.lat();
    marker.lng = $event.latLng.lng();
    this.form?.get(this.question.key)?.patchValue(this.markers);
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
  }
}
