<div *ngIf="vm$ | async as vm" class="jobs-container">
  <div *ngIf="!displayOpen" class="display-header">
    <jui-icon name="chevron_right" color="current" size="sm" (click)="toggleDisplay()"></jui-icon>
  </div>

  <div *ngIf="displayOpen" class="display-header">
    <p class="display-header__text">{{ vm.headerJobsReadyToSchedule }} {{ vm.numberOfJobs }}</p>
    <div class="display-header__actions">
      <span>
        <jui-icon
          [attr.data-qa]="vm.qaButtonFilters"
          name="filter_alt"
          color="current"
          size="sm"
          [ngbPopover]="rsjFilterPopover"
          #p="ngbPopover"
          placement="bottom-left"
          [autoClose]="'outside'"
        ></jui-icon>
      </span>
      <span>
        <jui-icon name="chevron_left" color="current" size="sm" (click)="toggleDisplay()"></jui-icon>
      </span>
    </div>
  </div>

  <div *ngIf="displayOpen" class="schedule-jobs-wrapper">
    <div class="main-content-container">
      <div class="jobs-container">
        <ng-container *ngIf="vm.showLoader">
          <schedule-jobs-loader-v3 [vm]="vm"></schedule-jobs-loader-v3>
        </ng-container>

        @for (job of vm.jobs; track job.id) {
        <div class="job-entry" [attr.data-qa]="job.qaJobEntry">
          <span class="job-entry__icon-container">
            <i class="material-icons job-entry__icon">drag_handle</i>
          </span>
          <div class="job-entry__details" [attr.data-jobid]="job.id">
            <span class="job-entry__details__title">{{ vm.headerDetails }}</span>
            <div class="job-entry__details__content">
              <span class="job-type">{{ job.jobTypeDetails }}</span>
              <span> - {{ job.jobCustomerNameDetails }}</span>
            </div>
          </div>
          <div class="job-entry__details">
            <span class="job-entry__details__title">{{ vm.headerPostcode }}</span>
            <div class="job-entry__details__content">{{ job.postcode }}</div>
          </div>
          <div class="job-entry__details button-column">
            <jui-button
              size="sm"
              color="secondary"
              display="ghost"
              expand="true"
              [attr.data-qa]="vm.qaButtonProject"
              (click)="openProject(job.projectId)"
              >{{ vm.buttonProject }}</jui-button
            >
            <jui-button size="sm" color="primary" expand="true" [attr.data-qa]="vm.qaButtonAssign" (click)="assignTradesperson(job)">{{ vm.buttonAssign }}</jui-button>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
  <ng-template #rsjFilterPopover>
    <schedule-jobs-display-filter-popover-v3
      [vm]="vm.filtersVm"
      (filterChanged)="filterReadyToScheduleJobs()"
      (popoverClosed)="closeFilters()"
    ></schedule-jobs-display-filter-popover-v3>
  </ng-template>
</div>
