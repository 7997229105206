import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpGateway {
  constructor(private httpClient: HttpClient) {}

  public async get(path: string, params, headers?) {
    return (await firstValueFrom(
      this.httpClient.get(path, {
        headers: { ...headers, 'x-jt-response-format': 'plain' },
        params
      })
    )) as Promise<any>;
  }

  public async getStaticResponse(path: string, headers?) {
    return await firstValueFrom(
      this.httpClient
        .get(path, {
          headers: { ...headers },
          responseType: 'text' as 'json',
          observe: 'response'
        })
        .pipe(
          map((response: HttpResponse<string>) => {
            return response;
          })
        )
    );
  }

  public async getBlobAsPlainText(path: string, headers?) {
    return await firstValueFrom(
      this.httpClient
        .get(path, {
          headers: { ...headers },
          responseType: 'blob' as 'json',
          observe: 'response'
        })
        .pipe(
          map((response: HttpResponse<string>) => {
            return response;
          })
        )
    );
  }

  // todo - sort this out
  public async post(
    path: string,
    body,
    headers:
      | HttpHeaders
      | {
          [header: string]: string | string[];
        },
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
        }
  ) {
    const options: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      context?: HttpContext;
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
      transferCache?:
        | {
            includeHeaders?: string[];
          }
        | boolean;
    } = { headers: { ...headers, 'x-jt-response-format': 'plain' } };
    if (params) {
      options.params = params;
    }
    return await firstValueFrom(this.httpClient.post(path, body, options).pipe(map((response: any) => response)));
  }

  public async put(path: string, params, headers) {
    const options = { headers };
    return await firstValueFrom(this.httpClient.put(path, params, options).pipe(map((response: any) => response)));
  }

  public async delete(path: string, headers) {
    const options = { headers };
    return await firstValueFrom(this.httpClient.delete(path, options).pipe(map((response: any) => response)));
  }
}
