import { Job, JobAssignment, JobAssignmentType } from '../../core/domain/job';
import { FormGroup } from '@angular/forms';
import { EventInfo, JobEvent, JobInfo } from './utils/schedule-types';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface ScheduleJobsDisplayVm {
  showLoader: boolean;
  jobs: JobVm[];
  filtersVm: ScheduleJobsDisplayFilterInfoVm;
  numberOfJobs: string;
  headerDetails: string;
  headerPostcode: string;
  buttonAssign: string;
  buttonProject: string;
  headerJobsReadyToSchedule: string;
  qaButtonProject: string;
  qaButtonAssign: string;
  qaButtonFilters: string;
}

export interface ScheduleJobsDisplayDm {
  loading: boolean;
  jobs: Job[];
  selectedJob?: Job;
  jobTypes: DropDownElement[];
  filtersForm: FormGroup;
  i18ns: ScheduleI18n;
}

export interface JobVm {
  id: string;
  projectId: string;
  jobTypeDetails: string;
  jobCustomerNameDetails: string;
  postcode: string;
  jobInformation?: JobInfo;
  qaJobEntry: string;
}

export interface ScheduleJobsDisplayFilterInfoVm {
  filtersForm: FormGroup;
  jobTypes: DropDownElement[];
  jobTypeLabel: string;
  jobTypesDropdownPlaceholder: string;
  freeTextFilterLabel: string;
  freeTextFilterPlaceholder: string;
  qaCloseFilterButton: string;
  qaFilterJobTypesDropdown: string;
  qaFilterFreeTextInput: string;
}

export interface ScheduleI18n {
  [key: string]: string;
}

export interface ScheduleError {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

export enum ErrorType {
  fetchReadyToScheduleJobs = 'schedule.errors.fetchReadyToScheduleJobs',
  fetchEngineers = 'schedule.errors.fetchEngineers',
  unknown = 'schedule.errors.unknown',
  scheduleJob = 'schedule.errors.scheduleJob',
  selectRescheduleJob = 'schedule.errors.selectRescheduleJob'
}

export type SelectedJobDetailsDm = Required<
  Pick<
    Job,
    | 'id'
    | 'type'
    | 'projectId'
    | 'jobAssignments'
    | 'firstName'
    | 'lastName'
    | 'phoneNumber'
    | 'email'
    | 'address'
    | 'startDate'
    | 'endDate'
  >
> & {
  context?: string;
  tradespeopleList: {
    name: string;
    id: string;
  }[];
  form: FormGroup;
  addTradespersonForm: FormGroup;
  isAddingTradesperson: boolean;
  scheduleInProgress: boolean;
  collisionCheckInProgress: boolean;
  enableCollisionCheck: boolean;
  isInitialSchedule: boolean;
  scheduleSuccess: boolean;
  readonly: boolean;
  hidden: boolean;
  collisions?: { date: string; assignedToDisplayName: string }[];
  tradesPeople: TradesPerson[];

  //i18ns
  i18nLead: string;
  i18nStartDatePlaceholder: string;
  i18nTradesPeopleHeader: string;
  i18nTradesPeopleSubHeader: string;
  i18nTradesPeopleSlotHeader: string;
  i18nStartDateLabel: string;
  i18nScheduleNowBtn: string;
  i18nGoToProjectBtn: string;
  i18nStartDateRequired: string;
  i18nInvalidDateFormat: string;
  i18nStartDateAfterEnd: string;
  i18nStartTimeLabel: string;
  i18nEndDatePlaceholder: string;
  i18nEndDateLabel: string;
  i18nEndDateRequired: string;
  i18nEndDateBeforeStart: string;
  i18nEndTimeLabel: string;
  i18nDurationLabel: string;
  i18nDayLabel: string;
  i18nDaysLabel: string;
  i18nHourLabel: string;
  i18nHoursLabel: string;
  i18nMinutesLabel: string;
  i18nAddTradesPersonLabel: string;
  i18nSelectEngineerPlaceholder: string;
  i18nConfirmBtn: string;
  i18nCancelBtn: string;
  i18nCloseBtn: string;
  i18nSetLeadEngineerBtn: string;
  i18nRemoveTradespersonBtn: string;
  i18nAddTradesPersonBtn: string;
  i18nProvisionallyScheduleBtn: string;
  i18nRescheduleReasonInputLabel: string;
  i18nRescheduleReasonInputPlaceholder: string;
  i18nEngineerRequiredError: string;
  i18nTimeIsInvalid: string;
  i18nTimeNotWithinJob: string;
  i18nCheckCollisionsBtn: string;
  i18nAddSpecificTimeBtn: string;
};

export interface TradesPerson {
  assignedTo: string;
  assignedToDisplayName: string;
  assignmentId?: string;
  assignmentType: JobAssignmentType;
  slots: TradesPersonSlot[];
}

export interface TradesPersonVm extends TradesPerson {
  type?: string;
  color?: string;
  isLeadTradesperson?: boolean;
  titleText?: string;
  i18nLead: string;
  i18nRemoveTradespersonBtn: string;
  qaRemoveEngineerBtn: string;
  qaSetLeadEngineerBtn: string;
  slots: TradesPersonSlotVm[];
  removeDisabled: boolean;
  readonly: boolean;
}

export interface TradesPersonSlot {
  assignmentId?: string;
  endDate: string;
  startDate: string;
}

export interface TradesPersonSlotVm extends TradesPersonSlot {
  form: FormGroup;
  isTimeInvalid: boolean;
  isTimeOutsideJob: boolean;
  hasConstraintError: boolean;
  timeslots: DropDownElement[];
  readonly: boolean;
  i18nStartDatePlaceholder: string;
  i18nEndDatePlaceholder: string;
  i18nRemoveTradespersonBtn: string;
  i18nStartDateRequired: string;
  i18nInvalidDateFormat: string;
  i18nStartDateAfterEnd: string;
  i18nEndDateRequired: string;
  i18nEndDateBeforeStart: string;
  i18nTimeIsInvalid: string;
  i18nTimeNotWithinJob: string;
  qaStartDateInput: string;
  qaStartDateBtn: string;
  qaStartTimeSelect: string;
  qaEndDateInput: string;
  qaEndDateBtn: string;
  qaEndTimeSelect: string;
  qaRemoveEngineerBtn: string;
}

export interface RescheduleReasonVm {
  form: FormGroup;
  show: boolean;
  i18nRescheduleReasonInputLabel: string;
  i18nRescheduleReasonInputPlaceholder: string;
  qaRescheduleReasonInput: string;
}

export interface SelectedJobTimeVm {
  form: FormGroup;
  timeslots: DropDownElement[];
  readonly: boolean;
  isTimeInvalid: boolean;
  i18nTimeIsInvalid: string;
  durationDisplay: string;
  i18nStartDatePlaceholder: string;
  i18nEndDatePlaceholder: string;
  i18nRemoveTradespersonBtn: string;
  i18nStartDateRequired: string;
  i18nInvalidDateFormat: string;
  i18nStartDateAfterEnd: string;
  i18nEndDateRequired: string;
  i18nEndDateBeforeStart: string;
  i18nTradesPeopleHeader: string;
  i18nTradesPeopleSubHeader: string;
  i18nStartDateLabel: string;
  i18nStartTimeLabel: string;
  i18nEndDateLabel: string;
  i18nEndTimeLabel: string;
  qaStartDateInput: string;
  qaStartDateBtn: string;
  qaStartTimeSelect: string;
  qaEndDateInput: string;
  qaEndDateBtn: string;
  qaEndTimeSelect: string;
  qaRemoveEngineerBtn: string;
}

export interface SelectedJobActionsVm {
  scheduleInProgress: boolean;
  isScheduleButtonDisabled: boolean;
  showCheckCollisionsButton: boolean;
  collisionCheckInProgress: boolean;
  i18nScheduleNowBtn: string;
  i18nCheckCollisionsBtn: string;
  i18nGoToProjectBtn: string;
  hideCloseIcon: boolean;
}

export interface SelectedJobInfoVm {
  jobType: string;
  addressDisplay: string;
  phoneNumber: string;
  email: string;
}

export interface SelectedJobDetailsVm {
  form: FormGroup;
  addTradespersonForm: FormGroup;
  tradesPeople: TradesPersonVm[];
  mainJobTime: SelectedJobTimeVm;
  rescheduleReason: RescheduleReasonVm;
  actions: SelectedJobActionsVm;
  info: SelectedJobInfoVm;
  assignedTradesPeople: AssignedTradesPeopleVm;
  projectId: string;
  jobId: string;
  tenantType?: string;
  customerName: string;
  showEngineerRequiredError: boolean;
  isAddingTradesperson: boolean;
  i18nTradesPeopleSlotHeader: string;
  scheduleValid: boolean;
  isInitialSchedule: boolean;
  scrollContentClass: string;
  readonly: boolean;
  hidden: boolean;
}

export interface AssignedTradesPeopleVm {
  tradesPeople: TradesPersonVm[];
  tradesPeopleDisplay: string;
  i18nAddSpecificTimeBtn: string;
  i18nAddTradesPersonBtn: string;
  form: FormGroup;
  readonly: boolean;
  addTradespersonForm: FormGroup;
  isAddingTradesperson: boolean;
  allTradespeople: {
    name: string;
    id: string;
  }[];
  showTradespersonRequiredError: boolean;
  qaTradespersonSelect: string;
  qaAddTradespersonBtn: string;
  qaConfirmAddTradespersonBtn: string;
  qaCancelAddTradespersonBtn: string;
  i18nTradespersonRequiredError: string;
  i18nSelectTradespersonPlaceholder: string;
  i18nConfirmBtn: string;
  i18nCancelBtn: string;
}

export interface SchedulePayloadPostDto {
  id?: string;
  start: string;
  end: string;
  jobType: string;
  jobAssignments: JobAssignment[];
  rescheduleReason?: string;
}

export interface SelectedJobDetailsToggleVm {
  display: boolean;
  hint: string;
  qaHook: string;
}

export interface SelectedJobDetailsToggleDm {
  display: boolean;
  hint: string;
}

export type DateChangeType = 'start' | 'end';

export interface DateTimeChangeEvent {
  tradespersonId: string;
  index: number;
  type: DateChangeType;
}

export interface RemoveTradespersonSlotEvent {
  tradespersonId: string;
  index: number;
}

export interface SelectedJobDetailsChangeData {
  event?: JobEvent;
  assignedTo: string;
  assignmentId: string;
  assignedToDisplayName: string;
  slots?: TradesPersonSlot[];
  eventId: string;
}

export interface SelectedJobDetailsFormChange {
  fullJob: FullJobChange;
  startIso: string;
  startDate: NgbDateStruct;
  startTime: string;
  endDate: NgbDateStruct;
  endTime: string;
  endIso: string;
  lastChange: DateChangeType;
  assignedTo: string;
}

export interface FullJobChange {
  startIso: string;
  endIso: string;
}

export enum SelectedJobDetailsFormChangeType {
  addTradespersonChange = 'addTradespersonChange',
  removeTradespersonChange = 'removeTradespersonChange',
  removeTradespersonSlotChange = 'removeTradespersonSlotChange',
  addSlotChange = 'addSlotChange',
  fullJobChange = 'fullJobChange'
}
